import Papa from "papaparse";

export const handleFileUpload = (
  event,
  employeeList,
  setTransferEmployeeList,
  createTransferFormik
) => {
  const file = event.target.files[0];
  if (file) {
    Papa.parse(file, {
      complete: (result) => {
        updateTableFromCsv(
          result.data,
          employeeList,
          setTransferEmployeeList,
          createTransferFormik
        );
      },
      header: true,
      skipEmptyLines: true,
    });
    event.target.value = null;
  }
};

const updateTableFromCsv = (
  csvData,
  employeeList,
  setTransferEmployeeList,
  createTransferFormik
) => {
  // Borrar la lista de empleados de transferencia y detalles antes de actualizar
  let updatedTransferEmployeeList = [];
  let updatedDetails = [];

  csvData.forEach((row) => {
    const { cuit, transferencia, referencia } = row;

    // Buscar el empleado que coincida con el CUIT
    const employee = employeeList.find((e) => e["CUIT/CUIL"] === cuit);

    if (employee) {
      // Crear un nuevo nodo para la lista de empleados de transferencia
      const newEmployee = {
        id: `${employee.id}-item-${updatedTransferEmployeeList.length + 1}`,
        "CUIT/CUIL": cuit,
        Nombre: employee.Nombre,
        "Monto ARS": transferencia,
        Referencia: referencia,
      };

      // Agregar el nuevo nodo a la lista de empleados de transferencia
      updatedTransferEmployeeList.push(newEmployee);

      // Agregar el detalle correspondiente
      updatedDetails.push({
        id: employee.id,
        monto: transferencia,
        referencia: referencia,
      });
    }
  });

  // Actualizar el estado con la nueva lista de empleados de transferencia y detalles
  setTransferEmployeeList(updatedTransferEmployeeList);
  createTransferFormik.setFieldValue("detalle", updatedDetails);
};

export const extractFirstNumber = (id) => {
  const match = id.match(/^\d+/);

  return match ? Number(match[0]) : null;
};
