import React, { useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { CommonForm, FormButton } from '../../../components'
import { formData, formIsOpen, rowSelected, snackbarData, userCredentials } from '../../../recoilState/GlobalState'
import { merchantCobroCancelar, movilinkCobradoMarcar } from '../../../services/hooli-services'
import { formatNumber, handleResponse } from '../../../services/Local-services'
import { SelectedRowType, UserCredentialsType } from '../../../types/types'

type Props = {
    props: {
        procede: 'cancelar' | 'marcar'
        updateList: () => void
    }
};

export default function ModalConfirmar({ props: { procede, updateList } }: Props) {
    const [inRequest, setInRequest] = useState<boolean>(false);

    const { title } = useRecoilValue(formData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials) as UserCredentialsType;
    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const { row } = selected as SelectedRowType;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setInRequest(true);
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };

        e.preventDefault();
        if (procede === 'cancelar') {
            const response = await merchantCobroCancelar({ ...credentials, id: row.id, origen: row.origen as string, version: row.version as number })
            return handleResponse(response, 'Cobro cancelado exitosamente', functions);
        }
        if (procede === 'marcar') {
            const response = await movilinkCobradoMarcar({ ...credentials, id: row.id, version: row.version as number });
            return handleResponse(response, 'Cobro marcado como cobrado exitosamente', functions);
        }
    };

    return (
        <CommonForm
            onSubmit={handleSubmit}
            title={title}
            procede={procede}
        >
            <p>Esta seguro de que desea {procede === 'cancelar' ? 'cancelar' : 'marcar como cobrada'} la operación de {row.Nombre} por ${formatNumber(row.Monto)}?</p>
            <FormButton inRequest={inRequest} />
        </CommonForm>
    )
};