import { useState, useEffect, useCallback } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Filtros from "..//../components/filtros/Filtros";
// consts
import { liquidaciones as titles } from "../../consts/titulos-de-tablas";
import { Routes } from "../../consts/Routes-App";
//Assets
import CircularIndeterminate from "../../components/utils/spinner/Spinner";
//Services
import {
  MerchantLiquidacionGet,
  MerchantNominaGet,
} from "../../services/hooli-services";
// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  rowSelected,
  userCredentials,
  routeInfo,
} from "../../recoilState/GlobalState";

export default function Liquidaciones() {
  const [transfers, setTransfers] = useState([]);
  const [formattedTransferList, setFormattedTransferList] = useState([]);
  const [payrollsOptions, setPayrollsOptions] = useState([]);
  const [filtros, setFiltros] = useState({
    nominaId: "",
  });
  const [cargando, setCargando] = useState(false);

  const setRow = useSetRecoilState(rowSelected);
  const setRoute = useSetRecoilState(routeInfo);
  const credentials = useRecoilValue(userCredentials);

  const transferMapper = (transferList = []) =>
    transferList.map((transfer) => ({
      "Nomina": transfer.merchantNominaNombre,
      Estado: transfer.merchantLiquidacionEstado,
      Período: transfer.merchantLiquidacionPeriodo,
      id: transfer.merchantLiquidacionId,
      "Fecha de carga": transfer.merchantLiquidacionFechaCarga,
      "Fecha de acreditación": transfer.merchantLiquidacionFechaAcreditacion,
      "Notas": transfer.merchantLiquidacionNotas,
      "Monto ARS": transfer.merchantLiquidacionTotalMonto,
      "Monto HC": transfer.merchantLiquidacionTotalBono,
    }));

  const retrievePayrolls = useCallback(async () => {
    const payload = {
      merchantId: credentials.merchantId,
    };
    const result = await MerchantNominaGet(payload);
    const formattedOptions = [
      { merchantNominaNombre: "Todas", merchantNominaId: "" },
      ...result,
    ];
    setPayrollsOptions(formattedOptions);
  }, [credentials.merchantId]);

  const retrieveTransfers = useCallback(async () => {
    setCargando(true);
    const payload = {
      merchantNominaId: filtros.nominaId,
      merchantId: credentials.merchantId,
    };
    const result = await MerchantLiquidacionGet(payload);
    
    const transferMapped = transferMapper(result);
    setTransfers(result);
    setFormattedTransferList(transferMapped);
    setCargando(false);
  }, [credentials.merchantId, filtros.nominaId]);

  useEffect(() => {
    retrievePayrolls();
  }, [retrievePayrolls]);

  useEffect(() => {
    retrieveTransfers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrieveTransfers]);

  useEffect(() => {
    setRoute(Routes[window.location.pathname.split("/")[1]]);
    setRow({ status: false, row: {} });
  }, [setRoute, setRow]);


  return (
    <main>
      <TransitionModal
        titles={titles}
        updateList={retrieveTransfers}
        moneda={filtros.moneda}
      />
      <Filtros
        filtros={filtros}
        setFiltros={setFiltros}
        cargando={cargando}
        isTransfers
        retrieveTransfers={retrieveTransfers}
        payrollsOptions={payrollsOptions}
      />
      <div className="row-grid" id="grid-container">
        {cargando ? (
          <CircularIndeterminate />
        ) : (
          <XGridDemo rows={formattedTransferList} titles={titles} type="see" />
        )}
      </div>
    </main>
  );
}
