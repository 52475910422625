import React, { useState } from "react";

import { Menu } from "@mui/icons-material";

import { AppBar, Toolbar, Typography, IconButton, Drawer } from "@mui/material";
// assets
import LogoutIcon from "../../assets/icons/icon-logout.svg";

// components
import ListDrawer from "../list/ListDrawer";
// styles
import { HeaderStyles } from "./Header.styles";
// recoil
import { useRecoilValue } from "recoil";
import {
  userInfo,
  routeInfo,
  userCredentials,
} from "../../recoilState/GlobalState";
import { logout } from "../../services/Local-services";

export default function Header() {
  const [openDrawer, setOpenDrawer] = useState(false);

  const credentials = useRecoilValue(userCredentials);
  const info = useRecoilValue(userInfo);
  const route = useRecoilValue(routeInfo);

  const handleDrawerOpen = () => setOpenDrawer(true);
  const handleDrawerClose = () => setOpenDrawer(false);

  const classes = HeaderStyles();
  return (
    <>
      <AppBar position="relative" className={classes.root}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            aria-label="menu"
            color="inherit"
            onClick={handleDrawerOpen}
          >
            <Menu className={classes.menuIcon} />
          </IconButton>
          <Typography color={"inherit"} className={classes.wordKey}>
            {route.wordKey + " - " + credentials?.merchantNombre}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        onClose={handleDrawerClose}
      >
        <div className={classes.userDescription}>
          <img
            className={classes.avatar}
            alt="avatar"
            src={info.imagePathPERFIL}
          />
          <div className={classes.usernameContainer}>
            <p>
              {info.usuarioApellidos} {info.usuarioNombres.split(" ")[0]}
            </p>
            <div className={classes.logout} onClick={() => logout()}>
              <img src={LogoutIcon} alt="logout" />
              <p>Cerrar sesión</p>
            </div>
          </div>
        </div>
        <div className={classes.listContainer}>
          <ListDrawer setOpen={setOpenDrawer} />
        </div>
        <div className={classes.drawerFooter}>
          ® Hooli | Todos los derechos reservados
        </div>
      </Drawer>
      {openDrawer ? (
        <div
          className={classes.drawerHandler}
          onClick={() => setOpenDrawer(false)}
        ></div>
      ) : null}
    </>
  );
}
