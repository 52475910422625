import axiosPost from '../axiosPost';
import { UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

type Args = UserCredentialsType & {
    merchantId: number
};

export const MWPorCobrarGet = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "MWPorCobrarGet",
        "params": {
            "merchantId": arg.merchantId
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result.result
    } catch (err) {
        console.log(err)
    }
}