import { useState, useEffect, useCallback } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Filtros from "..//../components/filtros/Filtros";
// consts
import { nominas as titles } from "../../consts/titulos-de-tablas";
import { Routes } from "../../consts/Routes-App";
//Assets
import CircularIndeterminate from "../../components/utils/spinner/Spinner";
//Services
import { MerchantNominaGet } from "../../services/hooli-services";
// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  rowSelected,
  userCredentials,
  routeInfo,
} from "../../recoilState/GlobalState";

export default function Nominas() {
  const [payrolls, setPayrolls] = useState([]);
  const [formattedPayrolls, setFormattedPayrolls] = useState();
  const [filtros, setFiltros] = useState({
    nomina: "",
  });
  const [cargando, setCargando] = useState(false);

  const setRow = useSetRecoilState(rowSelected);
  const setRoute = useSetRecoilState(routeInfo);
  const credentials = useRecoilValue(userCredentials);

  const payrollsMapper = (payrollsList = []) =>
    payrollsList.map((nomina) => ({
      "Nombre de nómina": nomina.merchantNominaNombre,
      "Cantidad de empleados": nomina.merchantNominaCantidadEmpleados,
      "Fecha de último pago": nomina.merchantNominaUltimoPago,
      id: nomina.merchantNominaId,
      ...nomina,
    }));

  const retrievePayrolls = useCallback(async () => {
    setCargando(true);
    const payload = {
      merchantId: credentials.merchantId,
    };
    const result = await MerchantNominaGet(payload);
    const formatResult = payrollsMapper(result);
    setPayrolls(result);
    setFormattedPayrolls(formatResult);
    setCargando(false);
  }, [credentials.merchantId]);

  useEffect(() => {
    if (filtros.nomina) {
      const filteredPayrolls = payrolls.filter((payroll) => {
        const regex = new RegExp(filtros.nomina, "i");
        return regex.test(payroll.merchantNominaNombre );
      });
      const formattedPayrollsList = payrollsMapper(filteredPayrolls);
      setFormattedPayrolls(formattedPayrollsList);
    } else {
      retrievePayrolls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros.nomina, retrievePayrolls]);

  useEffect(() => {
    setRoute(Routes[window.location.pathname.split("/")[1]]);
    setRow({ status: false, row: {} });
  }, [setRoute, setRow]);

  return (
    <main>
      <TransitionModal
        titles={titles}
        updateList={retrievePayrolls}
        moneda={filtros.moneda}
      />
      <Filtros
        filtros={filtros}
        setFiltros={setFiltros}
        updateList={retrievePayrolls}
        cargando={cargando}
        isPayrolls
        retrievePayrolls={retrievePayrolls}
      />
      <div className="row-grid" id="grid-container">
        {cargando ? (
          <CircularIndeterminate />
        ) : (
          <XGridDemo rows={formattedPayrolls} titles={titles} />
        )}
      </div>
    </main>
  );
}
