import axios from 'axios';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';
import axiosPost from '../axiosPost';
import { serviceURL } from '../../../consts/ServiceURL';

export const DocumentosPut = async (url: string, fileData: File) => {
    const response = await axios.put(url, fileData, {
        headers: {
            'Content-Type': 'application/octet-stream',
        }
    });
};