import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Args = UserCredentialsType & {
  merchantEmpleadoId: number;
  merchantEmpleadoDenominacion: string;
  merchantEmpleadoCuenta: string;
};

export const MerchantNominaEmpleadoUpd = async (args: Args) => {
  const body: AxiosPostBody = {
    service: "MerchantNominaEmpleadoUpd",
    params: {
      merchantEmpleadoId: args.merchantEmpleadoId,
      merchantEmpleadoDenominacion: args.merchantEmpleadoDenominacion,
      merchantEmpleadoCuenta: args.merchantEmpleadoCuenta,
    },
  };

  try {
    const response = await axiosPost("Y", body, args);
    return response.result;
  } catch (e) {
    console.log(e);
  }
};
