import React from 'react'
import { ContainerConstanciaAFIP } from '../constanciaAFIP';
import {ContainerConstanciaIngresosBrutos} from "../constanciaIngresosBrutos";
import {ContainerContratoSocial} from "../contratoSocial";

function ContainerDocumentos({nextStep, setStepDocumentos, stepDocumentos, setIsOpen}) {
   


if(stepDocumentos.step === 1){ 
   return (<ContainerConstanciaAFIP nextStep={nextStep} setStepDocumentos={setStepDocumentos} />)
} else if (stepDocumentos.step === 2) {
    return (
        <ContainerConstanciaIngresosBrutos nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
    )
} else if(stepDocumentos.step === 3) { 
    return (
       <ContainerContratoSocial nextStep={nextStep} setStepDocumentos={setStepDocumentos} />
    )
}
  
}

export {
    ContainerDocumentos
} 
