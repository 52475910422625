import { Typography, Box } from "@mui/material";
import { handleFileUpload } from "../../utils";
import styles from "../../index.module.scss";

const CsvLoadModal = ({
  style,
  closeModal,
  employeeList,
  setTransferEmployeeList,
  createTransferFormik,
}) => {
  return (
    <Box sx={style}>
      <Typography variant="h4">Atención:</Typography>
      <Typography>
        Si continúas, los datos actuales en la tabla serán eliminados y
        reemplazados con la nueva información del archivo CSV que estás
        cargando.
      </Typography>
      <Typography>
        Esta acción no se puede deshacer. ¿Deseas continuar?
      </Typography>
      <div
        style={{
          display: "flex",
          gap: "4rem",
          alignItems: "center",
          marginBottom: "6px",
        }}
      >
        <div onClick={closeModal} style={{ cursor: "pointer" }}>
          Cerrar
        </div>
        <div className={styles.fileUploadWrapper}>
          <label for="file-upload" className={styles.fileUploadBtn}>
            Aceptar
          </label>
          <input
            id="file-upload"
            type="file"
            className={styles.fileUploadInput}
            onChange={(event) => {
              handleFileUpload(
                event,
                employeeList,
                setTransferEmployeeList,
                createTransferFormik
              );
              closeModal();
            }}
            accept=".csv"
          />
        </div>
      </div>
    </Box>
  );
};

export default CsvLoadModal;
