import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

export const MerchantNominaGet = async (args: UserCredentialsType) => {
  const body: AxiosPostBody = {
    service: "MerchantNominaGet",
    params: {
      merchantId: args.merchantId,
    },
  };

  try {
    const result = await axiosPost("Y", body, args);

    return result.result;
  } catch (err) {
    console.log(err);
  }
};
