import React from 'react'
import styles from "./HeaderHooli.module.scss"
import logo from "../../assets/icons/logo_hooli_login.svg"

export default function HeaderHooli() {
  return (
    <section className={styles.container}>
        <img src={logo} alt='Logo hooli' className={styles.logo}/>
    </section>
  )
}
