import React from 'react';
import classes from './Buttons.module.scss';

type Props = {
    handleContinue: () => void
    handleCancel: () => void
}

export default function Buttons({ handleCancel, handleContinue }: Props) {
    return (
        <div className={classes.container}>
            <button
                className={classes.continuar}
                onClick={handleContinue}
            >
                Continuar
            </button>
            <button
                className={classes.cancelar}
                onClick={handleCancel}
            >
                Cancelar
            </button>
        </div>
    )
};