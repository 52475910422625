import { Box } from '@mui/material'
import React from 'react'
import ButtonGradient from '../../../components/utils/button/ButtonGradient'
import { useNavigate } from 'react-router-dom';
import styles from './FinishPage.module.scss';
import img from "../../../assets/img/finish.svg";
import { useRecoilState } from 'recoil';
import { comercioData } from '../../../recoilState/GlobalState';

export default function FinishPage() {
    const [comercio,setComercio] = useRecoilState(comercioData)
    console.log("esto es comercioData", comercio)
const navigate = useNavigate();
const handleBack = () => {
    navigate('/');
};

  return (
    <>
        <div className={styles.container}>
            <img src={img} alt="imgFinish" className={styles.img}/>
            <div>
                <h2 className={styles.title}>¡Listo!</h2>
                <h2 className={styles.title}>Estamos revisando tus datos fiscales</h2>
            </div>
            <div className={styles.txt}>
            <p >Revisaremos tus condiciones impositivas según los datos que completaste.</p>    
            <p>En 2 días como máximo te enviaremos una notificación de confirmación.</p>
            </div>
        </div>
        <Box> 
            <ButtonGradient size={"medium"} text={"Ir al Inicio"} action={handleBack}/>
        </Box>
    </>
  )
}
