import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';

type Args = UserCredentialsType & {
    "merchantId": number
    "fechaDesde": string
    "fechaHasta": string
};

export const MWVentaGet = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "MWVentaGet",
        "params": {
            "merchantId": arg.merchantId,
            "fechaDesde": arg.fechaDesde,
            "fechaHasta": arg.fechaHasta
        }
    };

    try {
        const result = await axiosPost('Y', body, arg);

        return result.result
    } catch (err) {
        console.log(err)
    }
}