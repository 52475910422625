import { Box, Grid, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormikSelectInput, FormikTextInput } from '../../../utils/inputs/FormikInputs'
import { useStyles } from './styles'
import Map from '../../../map'
import { useRecoilValue } from 'recoil'
import { afipData, provincias } from '../../../../recoilState/GlobalState'
import axios from 'axios'

export default function LocalizationPage({formik, domicilio, setDomicilio, coords, setCoords, coordsComercial, setCoordsComercial}) {
    const [localidades, setLocalidades] = useState(JSON.parse(sessionStorage.getItem("localidades")));
    const recoilProvincias = useRecoilValue(provincias);
    const recoilAfipData = useRecoilValue(afipData);
    const styles = useStyles();
    const dataFormik = {
        calleFiscal: {label: "Calle", placeholder:"Viamonte"},
        numeroFiscal: {label: "Número", placeholder:"1760"},
        unidadFiscal: {label: "Unidad", placeholder:"Depto."},
        pisoFiscal: {label: "Piso", placeholder:"7"},
        codFiscal: {label: "Código Postal (CPA)", placeholder:"C1155ABH"},
        provinciaFiscal: {label: "Provincia", placeholder:"Buenos Aires"},
        localidadFiscal: {label: "Localidad", placeholder:"Ciudad Autónoma de Buenos Aires"},
        calleComercial: {label: "Calle", placeholder:"Viamonte"},
        numeroComercial: {label: "Número", placeholder:"1760"},
        unidadComercial: {label: "Unidad", placeholder:"Depto."},
        pisoComercial: {label: "Piso", placeholder:"7"},
        codComercial: {label: "Código Postal (CPA)", placeholder:"C1155ABH"},
        provinciaComercial: {label: "Provincia", placeholder:"Buenos Aires"},
        localidadComercial: {label: "Localidad", placeholder:"Ciudad Autónoma de Buenos Aires"},
    }
    const getLocalidad = ()=> { 
      
        const findLocalidad = localidades.find((loc)=> loc.localidadId === Number(recoilAfipData.domicilioFiscalLocalidadId));
    
        if(findLocalidad) { 
          return [{localidadNombre: findLocalidad.localidadNombre, localidadId: findLocalidad.localidadId, lat: findLocalidad.localidadGeoPINLatitud, lng: findLocalidad.localidadGeoPINLongitud}]
        }else {
          return false
        }
      }

    const getLocalidadesPorProvincia = async(provId) => {
        if(provId){
            const {data} = await axios.get("/localidades.json")
            setLocalidades(data.result.filter((prov) => prov.provinciaId === Number(provId)))
        }
    }

      useEffect(()=> {
             const loc = {
                 lat: getLocalidad()[0]?.lat || localidades.find((localidad)=> localidad.localidadId === Number(formik.values.localidadFiscal))?.localidadGeoPINLatitud,
                 lng:getLocalidad()[0]?.lng || localidades.find((localidad)=> localidad.localidadId === Number(formik.values.localidadFiscal))?.localidadGeoPINLongitud
             }
             setCoords({
                 lat: loc.lat, 
                 lng: loc.lng
             })
             setCoordsComercial({
                lat: loc.lat, 
                lng: loc.lng
            })
      }, [formik.values.localidadFiscal]);

      useEffect(()=> {
        const loc = {
            lat: getLocalidad()[0]?.lat || localidades.find((localidad)=> localidad.localidadId === Number(formik.values.localidadComercial))?.localidadGeoPINLatitud,
            lng:getLocalidad()[0]?.lng || localidades.find((localidad)=> localidad.localidadId === Number(formik.values.localidadComercial))?.localidadGeoPINLongitud
        }
        setCoordsComercial({
            lat: loc.lat, 
            lng: loc.lng
        })
     
 }, [formik.values.localidadComercial]);
 
    useEffect(() => {
        getLocalidadesPorProvincia(formik.values.provinciaComercial)
    }, [formik.values.provinciaComercial])

    useEffect(() => {
        if(formik.values.localidadFiscal === "undefined" || formik.values.localidadFiscal === "" || formik.values.localidadFiscal === null){
            formik.setFieldValue("localidadFiscal", localidades[0].localidadId);
        }
        if(formik.values.localidadComercial === "undefined" || formik.values.localidadComercial === "" || formik.values.localidadComercial === null){
            formik.setFieldValue("localidadComercial", localidades[0].localidadId);
        }
    }, [])
    // console.log("localidades:", localidades);
  
    return (
    <>
    <Grid container width={"100%"} maxWidth={"800px"} justifyContent={"center"} gap={"24px"} padding={"0px 50px 16px 50px"}>
        <div className={`${styles.btn} ${domicilio === "fiscal" && styles.select}`} onClick={() => setDomicilio("fiscal")}>Domicilio Fiscal</div>
        <div className={`${styles.btn} ${domicilio === "comercial" && styles.select}`} onClick={() => setDomicilio("comercial")}>Domicilio Comercial</div>
    </Grid>
    {domicilio === "fiscal" && <Grid container width={"100%"} maxWidth={"800px"} padding={"20px 0 50px 0"}>
        <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"calleFiscal"} formik={formik} variant="standard" disabled={true}/> 
            <FormikTextInput data={dataFormik} name={"numeroFiscal"} formik={formik} variant="standard" disabled={formik.values.numeroFiscal !== "" || formik.values.numeroFiscal !== null}/> 
        </Box>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}>
            <FormikTextInput data={dataFormik} name={"pisoFiscal"} formik={formik} variant="standard"/> 
            <FormikTextInput data={dataFormik} name={"unidadFiscal"} formik={formik} variant="standard"/> 
            <FormikTextInput data={dataFormik} name={"codFiscal"} formik={formik} variant="standard"/> 
            </Box>
            </Grid>
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box display={"flex"} flexDirection={"column"} width={"100%"} className={styles.containerInput}> 
                <FormikTextInput data={dataFormik} name={"provinciaFiscal"} formik={formik}  disabled={true}/>
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"100%"} className={styles.containerInput}> 
                <FormikSelectInput data={dataFormik} name={"localidadFiscal"} formik={formik} options={recoilAfipData.domicilioFiscalLocalidadId ? getLocalidad()  : localidades } optionKey={"localidadId"} optionValue={"localidadId"} optionName={"localidadNombre"} disabled={recoilAfipData.domicilioFiscalLocalidadId !== null} />
            </Box>
        </Grid>
        <Map lat={coords?.lat} lng={coords?.lng} setCoords={setCoords} />
    </Grid>}
    {domicilio === "comercial" && <Grid container width={"100%"} maxWidth={"800px"} padding={"20px 0 50px 0"}>
        <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}> 
            <FormikTextInput data={dataFormik} name={"calleComercial"} formik={formik} variant="standard"/> 
            <FormikTextInput data={dataFormik} name={"numeroComercial"} formik={formik} variant="standard"/> 
        </Box>
        <Box width={"50%"} display={"flex"} gap={4} className={styles.containerInput}>
            <FormikTextInput data={dataFormik} name={"pisoComercial"} formik={formik} variant="standard"/> 
            <FormikTextInput data={dataFormik} name={"unidadComercial"} formik={formik} variant="standard"/> 
            <FormikTextInput data={dataFormik} name={"codComercial"} formik={formik} variant="standard"/> 
            </Box>
            </Grid>
        <Grid item width={"100%"} display={"flex"} gap={4}> 
            <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}> 
                <FormikSelectInput data={dataFormik} name={"provinciaComercial"} formik={formik}  options={recoilProvincias} optionName={"provinciaNombre"} optionValue={"provinciaId"} optionKey={"provinciaId"}/>
            </Box>
            <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}> 
            <FormikSelectInput data={dataFormik} name={"localidadComercial"} formik={formik} options={localidades} optionKey={"localidadId"} optionValue={"localidadId"} optionName={"localidadNombre"} />
            </Box>
        </Grid>
        <Map lat={coordsComercial?.lat} lng={coordsComercial?.lng} setCoords={setCoordsComercial} />
    </Grid>}
    </>
  )
}
