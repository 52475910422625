import React from 'react';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { Identificacion } from '../../../components/alta-comercios/forms/identificacion';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, comercioData } from '../../../recoilState/GlobalState';
import { useNavigate } from 'react-router-dom';

export function ContainerIdentificacion({nextStep}) {
  const navigate = useNavigate()
  const validationSchema = yup.object({
    nombre: yup.string().required("Debes ingresar tu nombre"),
});
  const recoilAfipData = useRecoilValue(afipData);
  const [comercio, setComercioData] = useRecoilState(comercioData);
  const formik = useFormik({
    initialValues: {
        razonSocial: recoilAfipData.personaFJ === "J" ? recoilAfipData.merchantRZ : `${recoilAfipData.usuarioApellidos}, ${recoilAfipData.usuarioNombres}` ,
        tipoDePersona:recoilAfipData.personaFJ === "F" ? "Física" : "Jurídica",
        cuit:recoilAfipData.cuit,
        nombre:"",
        tipoDeSociedad: "",
        rubro: "",
        cargo: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values)=> { 
      setComercioData({ ...comercio,
        merchantNombre: values.nombre,
        rubroDeMerchantId: values.rubro,
        cargoId: values.cargo,
        tipoSocietarioId: values.tipoDeSociedad,
      })
       nextStep(1)
    },
    initialErrors:{
      nombre: false
    }
}); 
const back = () => {
  navigate("/ingreso-cuit")
}
console.log(recoilAfipData)
  return (
    <> 
          <Identificacion formik={formik}/>
          <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={formik.handleSubmit} disabled={!formik.isValid}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={back}/>
        </Box>
    </>
  )
}
