import { makeStyles } from "@mui/styles";

export const CommonFormStyles = makeStyles(() => ({
    root: {
        width: '80%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        '& label': {
            fontWeight: 'bold'
        },
        '& div.MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-disabled.Mui-disabled.MuiInputBase-formControl.MuiInput-formControl::before': {
            borderBottom: 'none'
        }
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        color: '#63d1a2',
        fontWeight: '300',
        fontSize: '1.5rem',
        marginBottom: '0.5rem'
    },
    divider: {
        margin: '0 2rem 0.5rem',
        width: '100%',
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 2rem',
        width: '80%',
        '& .row': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        '& .column': {
            width: '45%'
        }
    }
}));