import { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import { Box, Typography, Modal } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import moment from "moment";
import * as Yup from "yup";

import {
  MerchantNominaEmpleadoGet,
  MerchantLiquidacionIns,
} from "../../../../services/hooli-services";

import {
  FormikSelectInput,
  FormikDateInput,
} from "../../../../components/utils/inputs/FormikInputs";
import HeaderButtonTable from "../../../utils/button/HeaderTableButton";
import XGridDemo from "../../../utils/grid/Xgrid";
import SubmitButton from "../../../../components/utils/button/SubmitButton";
import { transferenciasUpd as transferenciasTitles } from "../../../../consts/titulos-de-tablas";
import { formatNumber } from "../../../../services/Local-services";
import { removeAddedOption } from "../../utils";

import styles from "./index.module.scss";
import AddTransferModal from "./actions/AddTransferModal";
import EditTransferModal from "./actions/EditTransferModal";
import CsvLoadModal from "./actions/CsvLoadModal";
import { extractFirstNumber } from "./utils";

const CreateTransferForm = ({
  payrollsOptions,
  credentials,
  classes,
  style,
  retrieveTransfers,
  handleCloseModal,
  selected,
  setSelected,
}) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [transferEmployeeList, setTransferEmployeeList] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  const [modal, setModal] = useState({ isOpen: false, type: "" });

  const { row, status } = selected;

  const filteredOption = removeAddedOption(payrollsOptions);
  const hourOptions = [
    { value: 8, label: "08:00" },
    { value: 13, label: "13:00" },
    { value: 18, label: "18:00" },
  ];

  const createValidationSchema = (employeeList) => {
    const schemaShape = {
      fechaDePago: Yup.date()
        .min(new Date(), "La fecha debe ser posterior a hoy")
        .required("Este campo es obligatorio"),
      periodo: Yup.date()
        .required("Este campo es obligatorio")
        .test(
          "within-one-month",
          "El periodo debe ser mayor o igual a este mes",
          (periodo) => {
            if (!periodo) return true;
            const maxDate = new Date();
            maxDate.setMonth(maxDate.getMonth() - 1);
            return periodo >= maxDate;
          }
        ),
    };

    employeeList.forEach((employee) => {
      // Validación para el monto
      schemaShape[`transferencia_${employee.id}`] = Yup.array().of(
        Yup.number()
          .required("Este campo es obligatorio")
          .test("max-decimals", "Ingresá hasta dos decimales", (value) => {
            if (value !== undefined && value !== null) {
              const numValue = parseFloat(value);
              if (isNaN(numValue)) return false;

              const decimalPart = numValue.toString().split(".")[1];
              return !decimalPart || decimalPart.length <= 2;
            }
            return true;
          })
      );

      // Validación para la referencia
      schemaShape[`referencia_${employee.id}`] = Yup.array()
        .of(
          Yup.string().max(
            100,
            "La referencia no puede exceder los 100 caracteres"
          )
        )
        .min(1, "Debe ingresar al menos una referencia");
    });

    return Yup.object().shape(schemaShape);
  };

  const validationSchema = createValidationSchema(employeeList);

  const createTransferFormik = useFormik({
    initialValues: {
      nominaId: filteredOption[0]?.merchantNominaId,
      periodo: "",
      fechaDePago: "",
      notas: "",
      detalle: [],
      horaDePago: hourOptions[0].value,
      monto: 0,
      referencia: "",
      employeId: "",
    },
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const isValid = await createTransferFormik.validateForm();
      if (!isValid || Object.keys(createTransferFormik.errors).length > 0) {
        setSubmitting(false);
        return;
      }
      if (!firstLoad) {
        setFirstLoad(true);
        setDisabledForm(true);
        return;
      }
      const merchantLiquidacionDetalle = values.detalle.map((item) => {
        const { id, monto, referencia } = item;
        return {
          merchantNominaEmpleadoId: extractFirstNumber(id),
          merchantLiquidacionMonto: Number(monto),
          merchantLiquidacionReferencia: referencia,
        };
      });
      await MerchantLiquidacionIns({
        merchantNominaId: values.nominaId,
        merchantLiquidacionDetalle: JSON.stringify(merchantLiquidacionDetalle),
        merchantLiquidacionPeriodo: moment(values.periodo).format("MM/YYYY"),
        merchantLiquidacionFechaAcreditacion: moment(values.fechaDePago).format(
          "YYYY/MM/DD"
        ),
        merchantLiquidacionNotas: values.notas,
        merchantLiquidacionHoraAcreditacion: Number(values.horaDePago),
      });
      retrieveTransfers();
      handleCloseModal(resetForm);
    },
    validationSchema,
    validateOnChange: true,
  });
  const totalTransferAmount = createTransferFormik.values.detalle.reduce(
    (acc, current) => acc + (Number(current.monto) || 0),
    0
  );

  const retrieveEmployees = useCallback(async () => {
    const payload = {
      merchantNominaId: createTransferFormik.values.nominaId,
      merchantId: credentials.merchantId,
    };
    const result = await MerchantNominaEmpleadoGet(payload);
    const employeesMapped = employeesMapper(result);
    setEmployeeList(employeesMapped);
  }, [createTransferFormik.values.nominaId, credentials.merchantId]);

  useEffect(() => {
    if (firstLoad) {
      retrieveEmployees();
    }
  }, [createTransferFormik.values.nominaId, firstLoad, retrieveEmployees]);
  const employeesMapper = (employeesList = []) => {
    let detail = [];
    let data = [];

    employeesList.forEach((employee) => {
      // Nodo principal: Empleado/Entidad
      data.push({
        id: employee.merchantEmpleadoId,
        "CUIT/CUIL": employee.merchantEmpleadoCUIT,
        Nombre: employee.merchantEmpleadoDenominacion,
      });
    });

    // Actualizar Formik con detalle inicial
    createTransferFormik.setFieldValue("detalle", detail);
    return data;
  };

  const createTransferData = {
    nominaId: { label: "Nómina" },
    periodo: { label: "Ingresá el período" },
    fechaDePago: { label: "Ingresa la fecha de pago" },
    horaDePago: { label: "Ingresa la hora de pago" },
    employeId: { label: "Componente" },
    notas: { label: "Notas" },
    monto: { label: "Monto ARS" },
    referencia: { label: "Referencia" },
  };

  const addMontoToEmployee = () => {
    setModal((prev) => ({ ...prev, isOpen: true, type: "addEmployee" }));
  };

  const deleteMontoFromEmployee = (id) => {
    const filteredList = transferEmployeeList.filter(
      (employee) => employee.id !== id
    );
    console.log(filteredList, "filtered list");
    const updatedDetails = filteredList.map((employee) => ({
      id: extractFirstNumber(employee.id),
      monto: employee["Monto ARS"],
      referencia: employee.Referencia,
    }));
    setTransferEmployeeList(filteredList);
    createTransferFormik.setFieldValue("detalle", updatedDetails);
  };
  console.log(createTransferFormik.values, "values de formik");
  const editEmployee = () => {
    setModal((prev) => ({ ...prev, isOpen: true, type: "editEmployee" }));
  };

  const handleTransferChange = (value, formik, name) => {
    const montoRegex = /^\d+([.,]\d{0,2})?$/;

    const isMonto = name.startsWith("monto");

    if (
      (isMonto && (montoRegex.test(value) || value === "")) ||
      (!isMonto && value.length <= 100)
    ) {
      let parseValue = value.replace(",", ".");
      parseValue = value === "0" ? "" : parseValue;

      formik.setFieldValue(name, parseValue);
    }
  };

  const closeModal = () => {
    setSelected({ status: false, row: {} });
    setModal((prev) => ({ ...prev, isOpen: false, type: "" }));
  };
  return (
    <form>
      <Modal
        open={modal.isOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {modal.type === "addEmployee" && (
            <AddTransferModal
              createTransferFormik={createTransferFormik}
              setTransferEmployeeList={setTransferEmployeeList}
              idKey={transferEmployeeList.length}
              createTransferData={createTransferData}
              employeeList={employeeList}
              style={style}
              classes={classes}
              handleCloseModal={handleCloseModal}
              setModal={setModal}
              handleTransferChange={handleTransferChange}
            />
          )}
          {modal.type === "editEmployee" && (
            <EditTransferModal
              createTransferFormik={createTransferFormik}
              createTransferData={createTransferData}
              classes={classes}
              style={style}
              setModal={setModal}
              handleTransferChange={handleTransferChange}
              row={row}
              setTransferEmployeeList={setTransferEmployeeList}
              setSelected={setSelected}
            />
          )}
          {modal.type === "csvLoadModal" && (
            <CsvLoadModal
              style={style}
              closeModal={closeModal}
              employeeList={employeeList}
              setTransferEmployeeList={setTransferEmployeeList}
              createTransferFormik={createTransferFormik}
            />
          )}
        </>
      </Modal>
      <Box
        sx={{
          ...style,
          height: !!employeeList.length ? "100%" : "fit-content",
          width: !!employeeList.length ? "60%" : "fit-content",
        }}
      >
        <Typography variant="h6" component="h2">
          Nueva liquidación
        </Typography>
        <div className={styles.inputsContainer}>
          <FormikSelectInput
            data={createTransferData}
            name="nominaId"
            formik={createTransferFormik}
            options={filteredOption}
            optionKey="merchantNominaId"
            optionName="merchantNominaNombre"
            optionValue="merchantNominaId"
            className={classes.input}
            disabled={disabledForm}
          />
          <FormikDateInput
            data={createTransferData}
            name="periodo"
            formik={createTransferFormik}
            className={classes.input}
            type="month"
            disabled={disabledForm}
            required
          />
          <FormikDateInput
            required
            data={createTransferData}
            name="fechaDePago"
            formik={createTransferFormik}
            className={classes.input}
            disabled={disabledForm}
          />
          <FormikSelectInput
            required
            data={createTransferData}
            name="horaDePago"
            formik={createTransferFormik}
            className={classes.input}
            disabled={disabledForm}
            options={hourOptions}
            optionKey="value"
            optionName="label"
            optionValue="value"
          />
        </div>
        {firstLoad && (
          <>
            <textarea
              id="nota"
              name="notas"
              rows="4"
              cols="50"
              placeholder="Notas"
              value={createTransferFormik.values.notas}
              onChange={createTransferFormik.handleChange}
              className={styles.textArea}
            ></textarea>
            <div className={`row-grid ${styles.grid}`} id="grid-container">
              <XGridDemo
                rows={transferEmployeeList}
                titles={transferenciasTitles}
                formik={createTransferFormik}
                data={createTransferData}
              />
            </div>
          </>
        )}
        {firstLoad && (
          <div className={styles.formFooter}>
            <div className={styles.actionButtons}>
              <HeaderButtonTable
                text="Agregar liquidación"
                handleClick={(e) => {
                  e.preventDefault();
                  addMontoToEmployee(row?.id);
                }}
              >
                <AddRoundedIcon />
              </HeaderButtonTable>
              <HeaderButtonTable
                text="Editar liquidación"
                disabled={!status}
                handleClick={(e) => {
                  e.preventDefault();
                  editEmployee();
                }}
              >
                <EditRoundedIcon />
              </HeaderButtonTable>
              <HeaderButtonTable
                disabled={!status && !row.id}
                text="Eliminar liquidación"
                handleClick={(e) => {
                  e.preventDefault();
                  deleteMontoFromEmployee(row?.id);
                }}
              >
                <DeleteRoundedIcon />
              </HeaderButtonTable>
            </div>
            <div className={styles.fileUploadWrapper}>
              <label
                onClick={() =>
                  setModal((prev) => ({
                    ...prev,
                    isOpen: true,
                    type: "csvLoadModal",
                  }))
                }
                className={styles.fileUploadBtn}
              >
                Completar desde CSV
              </label>
            </div>

            <div className={styles.totalLabelsContainer}>
              <h4>Totales</h4>
              <div className={styles.amountLabels}>
                <h4>Monto ARS: {"$" + formatNumber(totalTransferAmount)}</h4>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            gap: "4rem",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          <div
            onClick={() => {
              setSelected({ status: false, row: {} });
              handleCloseModal(createTransferFormik.resetForm);
            }}
            style={{ cursor: "pointer" }}
          >
            Cerrar
          </div>
          <SubmitButton
            prevent={true}
            text="Aceptar"
            click={createTransferFormik.handleSubmit}
            type="button"
          />
        </div>
      </Box>
    </form>
  );
};

export default CreateTransferForm;
