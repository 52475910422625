import React, { useEffect } from "react";
// Style
import Alert from "@mui/lab/Alert";
import { Snackbar } from "@mui/material";
import { SnackbarStyles } from "./Snackbar.styles";
// Recoil
import { useRecoilState, useRecoilValue } from 'recoil';
import { snackbarData } from '../../../recoilState/GlobalState'

export default function SnackBar(){
    const [{ message, severity, open }, setSnackbarInfo] = useRecoilState(snackbarData);
    const classes = SnackbarStyles();

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setSnackbarInfo({ open: false, message });
            }, 3500);
        }
    }, [message, open, setSnackbarInfo]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            key="top-center"
            className={classes.root}
        >
            <Alert
                className={classes.alert}
                elevation={6}
                severity={severity}
                color={severity}
            >
                <span className={classes.message}>{message}</span>
            </Alert>
        </Snackbar>
    );
};
