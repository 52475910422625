import React from "react";
import { Layaout } from "../../components/layaout";
import { Card } from "../../components/alta-comercios/card";
import IconDefaultComercios from "../../assets/icons/icono-default-comercios.png";
import { useRecoilState, useRecoilValue } from "recoil";
import { userCredentials } from "../../recoilState/GlobalState";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import ButtonGradient from "../../components/utils/button/ButtonGradient";

function ListadoComercios() {
  const merchants = JSON.parse(sessionStorage.getItem("merchants"));
  const [credentials, setCredentials] = useRecoilState(userCredentials);
  const navigate = useNavigate();

  const handleSelection = (comercioId) => {
    const findMerchant = merchants.find(
      (merchant) => String(merchant.merchantId) === String(comercioId)
    );
    setCredentials({ ...credentials, ...findMerchant });
    sessionStorage.setItem("hooliToken", credentials.hooliToken);
    sessionStorage.setItem("sessionId", credentials.sessionId);
    sessionStorage.setItem("merchantId", findMerchant.merchantId);
    sessionStorage.setItem("merchantRZ", findMerchant.merchantRZ);
    sessionStorage.setItem("merchantCUIT", findMerchant.merchantCUIT);
    sessionStorage.setItem("rolCod", merchants[0].rolCod);
    navigate("/dashboard");
  };
  return (
    <Layaout title={"Listado de tus comercios"}>
      <Box
        gap={2}
        display={"flex"}
        flexDirection={"column"}
        height={"500px"}
        marginTop={"16px"}
      >
        {merchants.map((merchant) => (
          <Card
            title={merchant.merchantNombre}
            subtitle={merchant.merchantRZ}
            key={merchant.merchantId}
            image={IconDefaultComercios}
            handleClick={handleSelection}
            merchantId={merchant.merchantId}
            opacity={false}
          />
        ))}
      </Box>

      <Box>
        <ButtonGradient
          text={"Crear nuevo comercio"}
          action={() => navigate("/ingreso-cuit")}
        />
      </Box>
    </Layaout>
  );
}

export { ListadoComercios };
