import { useState, useEffect, useCallback } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Filtros from "..//../components/filtros/Filtros";
// consts
import { empleados as titles } from "../../consts/titulos-de-tablas";
import { Routes } from "../../consts/Routes-App";
//Assets
import CircularIndeterminate from "../../components/utils/spinner/Spinner";
//Services
import {
  MerchantNominaEmpleadoGet,
  MerchantNominaGet,
} from "../../services/hooli-services";
// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  rowSelected,
  userCredentials,
  routeInfo,
} from "../../recoilState/GlobalState";

export default function Empleados() {
  const [employees, setEmployees] = useState([]);
  const [formattedEmployeesList, setFormattedEmployeesList] = useState([]);
  const [payrollsOptions, setPayrollsOptions] = useState([]);
  const [filtros, setFiltros] = useState({
    nominaId: payrollsOptions[0]?.merchantNominaId,
    datoEmpleado: "",
  });
  const [cargando, setCargando] = useState(false);

  const setRow = useSetRecoilState(rowSelected);
  const setRoute = useSetRecoilState(routeInfo);
  const credentials = useRecoilValue(userCredentials);

  const employeesMapper = (employeesList = []) =>
    employeesList.map((employee) => ({
      Nombre: employee.merchantEmpleadoDenominacion,
      CUIT: employee.merchantEmpleadoCUIT,
      "CBU/CVU": employee.merchantEmpleadoCuenta,
      Tipo: employee.merchantEmpleadoTipo,
      id: employee.merchantEmpleadoId,
      Nomina: employee.merchantNominaNombre,
    }));

  const retrievePayrolls = useCallback(async () => {
    const payload = {
      merchantId: credentials.merchantId,
    };
    const result = await MerchantNominaGet(payload);
    const formattedOptions = [
      { merchantNominaNombre: "Todas", merchantNominaId: "" },
      ...result,
    ];
    setPayrollsOptions(formattedOptions);
  }, [credentials.merchantId]);

  const retrieveEmployees = useCallback(async () => {
    setCargando(true);
    const payload = {
      merchantNominaId: filtros.nominaId,
      merchantId: credentials.merchantId,
    };
    const result = await MerchantNominaEmpleadoGet(payload);

    const employeesMapped = employeesMapper(result);
    setEmployees(result);
    setFormattedEmployeesList(employeesMapped);
    setCargando(false);
  }, [credentials.merchantId, filtros.nominaId]);

  useEffect(() => {
    retrievePayrolls();
  }, [retrievePayrolls]);

  useEffect(() => {
    if (filtros.datoEmpleado) {
      const filteredEmployees = employees.filter((employee) => {
        const regex = new RegExp(filtros.datoEmpleado, "i");
        return (
          regex.test(employee.merchantEmpleadoDenominacion) ||
          regex.test(employee.merchantEmpleadoCUIT) ||
          regex.test(employee.merchantEmpleadoCuenta)
        );
      });
      const filteredEmployeesMapped = employeesMapper(filteredEmployees);
      setFormattedEmployeesList(filteredEmployeesMapped);
      return;
    }
    retrieveEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros.datoEmpleado, retrieveEmployees]);

  useEffect(() => {
    setRoute(Routes[window.location.pathname.split("/")[1]]);
    setRow({ status: false, row: {} });
  }, [setRoute, setRow]);
  return (
    <main>
      <TransitionModal
        titles={titles}
        updateList={retrieveEmployees}
        moneda={filtros.moneda}
      />
      <Filtros
        filtros={filtros}
        setFiltros={setFiltros}
        cargando={cargando}
        isEmployees
        retrieveEmployees={retrieveEmployees}
        payrollsOptions={payrollsOptions}
      />
      <div className="row-grid" id="grid-container">
        {cargando ? (
          <CircularIndeterminate />
        ) : (
          <XGridDemo rows={formattedEmployeesList} titles={titles} />
        )}
      </div>
    </main>
  );
}
