import axiosPost from '../axiosPost';
import { UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

type Args = UserCredentialsType & {
    id: number
    nombre: string
    email: string
    telefono: string
};

export const usuarioLinkUpd = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "WAUsuarioLinkUpd",
        "params": {
            "usuarioLinkId": arg.id,
            "usuarioLinkNombre": arg.nombre,
            "usuarioLinkEmail": arg.email,
            "usuarioLinkCelular": arg.telefono
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result
    } catch (err) {
        console.log(err)
    }
}