import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';

type Args = UserCredentialsType & {
    id: number
    origen: string
    version: number
};

export const merchantCobroCancelar = async (arg: Args) => {
    const body: AxiosPostBody = {
        service: "MerchantCobroCancelar",
        params: {
            "merchantCobroId": arg.id,
            "merchantCobroOrigen": arg.origen,
            "merchantCobroVersion": arg.version
        }
    }

    const result = await axiosPost('Y', body, arg);
    return result
}