import { atom } from "recoil";
import AvatarUser from "../assets/img/avatar2.png";

export const userInfo = atom({
  key: "userInfo",
  default: {
    usuarioNombres: "Backuser",
    usuarioApellidos: "",
    imagePathPERFIL: AvatarUser,
  },
});

export const userCredentials = atom({
  key: "userCredentials",
  default: {},
});

export const routeInfo = atom({
  key: "routeInfo",
  default: {
    route: "/",
    wordKey: "Dashboard",
  },
});

export const formIsOpen = atom({
  key: "formIsOpen",
  default: false,
});

export const rowSelected = atom({
  key: "rowSelected",
  default: {
    status: false,
    row: {},
  },
});

export const snackbarData = atom({
  key: "snackbarData",
  default: {
    message: "",
    severity: "success",
    open: false,
  },
});

export const formData = atom({
  key: "formData",
  default: {
    title: "",
  },
});

export const afipData = atom({
  key: "afipData",
  default: {},
});

export const comercioData = atom({
  key: "comercioData",
  default: {},
});

export const provincias = atom({
  key: "provinciasData",
  default: {},
});
export const loading = atom({
  key: "loading",
  default: false,
});
