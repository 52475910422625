/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Route, Routes as Switch, useNavigate } from "react-router-dom";
// Components
import { PagosHeader } from "./components/pagos/index";
import { Header, SnackBar, ProtectedRoute } from "./components/index";
// pages
import {
  Login,
  Dashboard,
  Saldos,
  Ventas,
  ACobrar,
  Movimientos,
  PagosPaso1,
  PagosHome,
  PagosPaso2,
  PagosCobros,
  Nominas,
  Liquidaciones,
  Empleados,
} from "./pages";
// consts
import { Routes } from "./consts/Routes-App";
//Credentials services
import { usuarioMinimosGet, checkAuth } from "./services/hooli-services";
// recoil
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  userInfo,
  userCredentials,
  routeInfo,
  snackbarData,
} from "./recoilState/GlobalState";
import AltaComercios from "./pages/altaComercios/AltaComercios.js";
import LandingPage from "./pages/landingPage/LandingPage.js";
import HeaderHooli from "./components/headerHooli/HeaderHooli.js";
import { IngresoCUIT } from "./pages/altaComercios/ingresoCUIT/index.jsx";
import { ContainerNoDisponibles } from "./pages/altaComercios/noDisponibles/index.jsx";
import { ListadoComercios } from "./pages/listado-comercios/index.jsx";
import { NecesitamosDatos } from "./pages/altaComercios/necesitamosDatos/index.jsx";
import { useLocation } from "react-router-dom";
import ModalAltaComercio from "./components/alta-comercios/modal/index.jsx";
import { subscribe, unsubscribe } from "./utils/eventManager.js";

export default function App() {
  const setInfo = useSetRecoilState(userInfo);
  const [credentials, setCredentials] = useRecoilState(userCredentials);
  const [route, setRoute] = useRecoilState(routeInfo);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [routeError, setRouteError] = useState(false);
  const [setSnackbarInfo] = useRecoilState(snackbarData);
  // const [allowedRoutes, setAllowedRoutes] = useState(false);
  const location = useLocation();
  const allowedRoutesHeaderHooli = [
    Routes.landing.route,
    Routes.necesitamosDatos.route,
    Routes.altaComercios.route,
    "/ingreso-cuit",
    Routes.comercioNoDisponible.route,
    Routes.listadoComercios.route,
  ];

  const handleClickModal = () => {
    setIsOpen(false);
    if (routeError) {
      navigate(routeError);
    }
  };

  useEffect(() => {
    const handleErrorServer = (data) => {
      setSnackbarInfo({
        message: data.message,
        severity: "warning",
        open: true,
      });
    };
    const handleSessionExpired = (data) => {
      setErrorMsg(data.message);
      setRouteError(data.route);
      setIsOpen(true);
      setCredentials({});
    };
    subscribe("serverError", handleErrorServer);
    subscribe("sessionExpired", handleSessionExpired);
    if (location.pathname !== Routes.login.route) {
      setCredentials(checkAuth());
    }
    if (Routes[location.pathname.split("/")[1]])
      setRoute(Routes[location.pathname.split("/")[1]]);
    return () => {
      unsubscribe("serverError");
      unsubscribe("sessionExpired");
    };
  }, []);

  useEffect(() => {
    async function retrieveUsuarioMinimo() {
      const data = await usuarioMinimosGet(credentials);
      if (data) setInfo(data);
    }
    if (Object.keys(credentials).length > 0) retrieveUsuarioMinimo();
  }, [credentials]);

  //  useEffect(() => {
  //      if (Object.keys(credentials).length === 0) return;
  //      // Visita una ruta que no es login ni cobros y no es admin
  //      const noAutorizado = route.route !== Routes.login.route && credentials.rolCod !== 'MD' && credentials.rolCod !== 'MA' && !route.route.includes('/cobros');
  //      if (noAutorizado) return window.location.assign('/cobros');
  //  }, [route, credentials])
  return (
    <>
      {allowedRoutesHeaderHooli.includes(location.pathname) && <HeaderHooli />}
      {route.route.includes("/cobros") ? (
        <PagosHeader />
      ) : (
        route.route !== "/" &&
        route.route !== "/login" &&
        credentials.hooliToken &&
        !allowedRoutesHeaderHooli.includes(location.pathname) && <Header />
      )}

      <SnackBar />
      <Switch>
        {/* Login */}
        <Route path={Routes.login.route} exact element={<Login />} />

        {/* Landing page */}
        <Route path={Routes.landing.route} exact element={<LandingPage />} />

        {/* Necesitamos datos */}
        <Route
          path={Routes.necesitamosDatos.route}
          exact
          element={<NecesitamosDatos />}
        />
        {/* Alta comercios */}
        <Route
          path={Routes.altaComercios.route}
          exact
          element={<AltaComercios />}
        />
        {/* Ingreso CUIT */}
        {
          <Route
            path={Routes.ingresoCuit.route}
            exact
            element={<IngresoCUIT />}
          />
        }
        {/* Comercio No Disponible */}
        <Route
          path={Routes.comercioNoDisponible.route}
          exact
          element={<ContainerNoDisponibles />}
        />
        <Route
          path={Routes.listadoComercios.route}
          exact
          element={<ListadoComercios />}
        />

        {/* Dashboard */}
        <Route
          path={Routes.dashboard.route}
          exact
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        {/* Tablas */}
        <Route
          path={Routes.saldos.route}
          exact
          element={
            <ProtectedRoute>
              <Saldos />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.nominas.route}
          exact
          element={
            <ProtectedRoute>
              <Nominas />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.componentes.route}
          exact
          element={
            <ProtectedRoute>
              <Empleados />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.liquidaciones.route}
          exact
          element={
            <ProtectedRoute>
              <Liquidaciones />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.ventas.route}
          exact
          element={
            <ProtectedRoute>
              <Ventas />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.aCobrar.route}
          exact
          element={
            <ProtectedRoute>
              <ACobrar />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.resumen.route}
          exact
          element={
            <ProtectedRoute>
              <Movimientos />
            </ProtectedRoute>
          }
        />
        {/* Pagos */}
        <Route path={Routes.pagos.route} exact element={<PagosHome />} />
        <Route path={Routes.pagos.paso1.route} exact element={<PagosPaso1 />} />
        <Route path={Routes.pagos.paso2.route} exact element={<PagosPaso2 />} />
        <Route
          path={Routes.pagos.cobros.route}
          exact
          element={<PagosCobros />}
        />
      </Switch>
      <ModalAltaComercio
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClick={handleClickModal}
        title={"Ups..."}
        text={errorMsg}
      />
    </>
  );
}
