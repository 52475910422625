import { makeStyles } from "@mui/styles";

export const SubmitButtonStyles = makeStyles(() => ({
    root: {
        width: "80px",
        letterSpacing: "0rem",
        height: "2rem",
        opacity: 1,
        background: 'transparent linear-gradient(90deg, #9373B1 0%, #63C3D1 100%) 0% 0% no-repeat padding-box' ,
        borderRadius: "10px",
        // marginRight: "2rem",
        fontWeight: 300,
        fontFamily: '"Roboto", sans-serif',
        textTransform: 'capitalize',
        color:"#fffff",
        "&:hover": {
            background: "linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%)",
        },
        "&:disabled": {
            background: "#A7A7A7",
        }
    
    }
}));