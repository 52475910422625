import { makeStyles } from "@mui/styles";

export const SpinnerStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    root: {
        fontSize: 60
    }
}));