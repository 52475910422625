import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { formatNumber, transformDate } from '../../../services/Local-services';
// Assets
import Hooli from '../../../assets/icons/hooli.svg';
import Credito from '../../../assets/icons/credito.svg';
import Debito from '../../../assets/icons/debito.svg';
import ETransfer from '../../../assets/icons/e-transfer.svg';
import ArrowDown from '../../../assets/icons/arrow-down-red.svg';
import ArrowDownWhite from '../../../assets/icons/arrow-down-white.svg';
// Styles
import classes from './Card.module.scss';
// Skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// Charts
import { Chart, LineElement, Legend, Title, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';

Chart.register(LineElement, Legend, Title, CategoryScale, LinearScale, PointElement);

export default function Card({ dataHoy, dataSerie, totales, link, title, image, position, cargando, ultimoMovimiento }) {
    const [open, setOpen] = useState(false);
    const [chart, setChart] = useState('ventas');

    const handleClick = (newChart) => () => {
        setChart(newChart);
        if (open && chart === newChart) return setOpen(false);
        setOpen(true);
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.2,
        plugins: {
            legend: {
                position: 'bottom',
            }
        },
        scales: {
            yAxis: {
                ticks: {
                    stepSize: 1
                }
            }
        }
    };

    return (
        <div className={`${classes.card} ${classes[title.toLowerCase()]}`} style={position}>
            <div className={classes.titleContainer}>
                <img className={classes.icon} src={image} alt={title} />
                <h4 className={classes.title}>{title}</h4>
            </div>
            <div className={classes.bodyContainer}>
                <div className={classes.total}>
                    <p>Total</p>
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + formatNumber(totales.monto)}</h4>
                    <div className={classes.divider}></div>
                    <div className={classes.horizontalDivider}>-</div>
                    <p>Operaciones</p>
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : totales.operaciones}</h4>
                </div>
                <div className={`${classes.parcial} ${classes.debito}`}>
                    <img src={Debito} alt="Debito" />
                    <div className={classes.ventas}>
                        <p>Tarjeta Débito</p>
                        <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + formatNumber(dataHoy.D.monto)}</h4>
                        <div className={`${classes.operaciones} ${classes.green}`}>
                            <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : dataHoy.D.operaciones}</h4>
                            <p>Operaciones</p>
                        </div>
                    </div>
                </div>
                <div className={`${classes.parcial} ${classes.credito}`}>
                    <img src={Credito} alt="Credito" />
                    <div className={classes.ventas}>
                        <p>Tarjeta Crédito</p>
                        <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + formatNumber(dataHoy.C.monto)}</h4>
                        <div className={`${classes.operaciones} ${classes.purple}`}>
                            <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : dataHoy.C.operaciones}</h4>
                            <p>Operaciones</p>
                        </div>
                    </div>
                </div>
                <div className={`${classes.parcial} ${classes.hooli}`}>
                    <img src={Hooli} alt="Hooli" />
                    <div className={classes.ventas}>
                        <p>Ventas Hooli</p>
                        <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + formatNumber(dataHoy.H.monto)}</h4>
                        <div className={`${classes.operaciones} ${classes.blue}`}>
                            <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : dataHoy.H.operaciones}</h4>
                            <p>Operaciones</p>
                        </div>
                    </div>
                </div>
                {title === "Por cobrar" ? ultimoMovimiento ?
                    <div className={classes.parcial}>
                        <img src={ETransfer} alt="ETransfer" className={classes.ETransfer} />
                        <div className={`${classes.ventas} ${classes.lightBlue}`}>
                            <p>Ultima acreditación</p>
                            <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + formatNumber(ultimoMovimiento.movimientoMonto)}</h4>
                            <div className={`${classes.operaciones} ${classes.lightBlue}`}>
                                <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : transformDate(ultimoMovimiento.movimientoFH.substring(0, 10)).substring(0, 5)}</h4>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fecha&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    : null
                    :
                    <div className={`${classes.parcial} ${classes.transfer}`}>
                        <img src={ETransfer} alt="ETransfer" className={classes.ETransfer} />
                        <div className={`${classes.ventas} ${classes.lightBlue}`}>
                            <p>Ventas E-Transfer</p>
                            <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + formatNumber(dataHoy.T.monto)}</h4>
                            <div className={`${classes.operaciones} ${classes.lightBlue}`}>
                                <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : dataHoy.T.operaciones}</h4>
                                <p>Operaciones</p>
                            </div>
                        </div>
                    </div>
                }

                <div className={classes.buttonsContainer}>
                    <button disabled={cargando}>
                        <Link to={link}>Ver detalle</Link>
                    </button>
                    <button disabled={cargando} onClick={handleClick('ventas')} className={open && chart === 'ventas' ? classes.selected : ''}>
                        <span>Ver gráfico de montos</span>
                        <img src={open && chart === 'ventas' ? ArrowDownWhite : ArrowDown} />
                    </button>
                    <button disabled={cargando} onClick={handleClick('operaciones')} className={open && chart === 'operaciones' ? classes.selected : ''}>
                        <span>Ver gráfico de operaciones</span>
                        <img src={open && chart === 'operaciones' ? ArrowDownWhite : ArrowDown} />
                    </button>
                </div>
                {open && chart === 'ventas' &&
                    <div className={classes.charts}>
                        <Line options={chartOptions} data={getChartData(dataSerie, 'monto')} />
                    </div>
                }
                {open && chart === 'operaciones' &&
                    <div className={classes.charts}>
                        <Line options={chartOptions} data={getChartData(dataSerie, 'operaciones')} />
                    </div>
                }
            </div>
        </div>
    )
};

const getChartData = (data, key) => {
    return {
        labels: Object.keys(data).map(item => moment(item).locale('es').format('dd DD/MM')),
        datasets: [
            {
                label: 'Total',
                data: Object.values(data).map(item => Object.values(item).reduce((acc, metodo) => acc + metodo[key], 0)),
                borderColor: 'rgb(229, 109, 161)',
                backgroundColor: 'rgba(229, 109, 161, 0.5)',
            },
            {
                label: 'Hooli',
                data: Object.values(data).map(item => item.H[key]),
                borderColor: 'rgb(85, 103, 174)',
                backgroundColor: 'rgba(85, 103, 174, 0.5)',
            },
            {
                label: 'Tarjeta Débito',
                data: Object.values(data).map(item => item.D[key]),
                borderColor: 'rgb(99, 209, 162)',
                backgroundColor: 'rgba(99, 209, 162, 0.5)',
            },
            {
                label: 'E-Transfer',
                data: Object.values(data).map(item => item.T[key]),
                borderColor: 'rgb(99, 195, 209)',
                backgroundColor: 'rgba(99, 195, 209, 0.5)',
            },
            {
                label: 'Tarjeta Crédito',
                data: Object.values(data).map(item => item.C[key]),
                borderColor: 'rgb(147, 115, 177)',
                backgroundColor: 'rgba(147, 115, 177, 0.5)',
            }
        ],
    };
}