import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";

import XGridDemo from "../../../utils/grid/Xgrid";
import { transferencias as transferenciasTitles } from "../../../../consts/titulos-de-tablas";

import { removeAddedOption } from "../../utils";

import styles from "./index.module.scss";
import { MerchantLiquidacionDetalleGet } from "../../../../services/hooli-services/Liquidaciones/MerchantLiquidacionDetalleGet";

const SeeTransferForm = ({ payrollsOptions, style, handleCloseModal, row }) => {
  const [employeeList, setEmployeeList] = useState([]);

  const filteredOption = removeAddedOption(payrollsOptions);

  const createTransferFormik = useFormik({
    initialValues: {
      nominaId: filteredOption[0]?.merchantNominaId,
      periodo: "",
      fechaDePago: "",
      notas: "",
      detalle: [],
    },
  });

  useEffect(() => {
    const getInfo = async () => {
      const result = await MerchantLiquidacionDetalleGet({
        merchantLiquidacionId: row.id,
      });
      console.log(result);
      setEmployeeList(employeesMapper(result));
    };
    getInfo();
  }, [row.id]);
  console.log(employeeList);
  const employeesMapper = (employeesList = []) =>
    employeesList.map((employee) => ({
      "CUIT/CUIL": employee.merchantEmpleadoCUIT,
      Nombre: employee.merchantEmpleadoDenominacion,
      id: employee.merchantEmpleadoId,
      "Monto ARS": employee.merchantLiquidacionDetalleMonto,
      Resultado: employee.merchantLiquidacionDetalleResultado, // merchantEmpleadoTipo: employee.merchantEmpleadoTipo,
    }));

  const createTransferData = {
    nominaId: { label: "Nómina" },
  };
  return (
    <form onSubmit={createTransferFormik.handleSubmit}>
      <Box
        sx={{
          ...style,
          height: !!employeeList.length ? "100%" : "fit-content",
          width: !!employeeList.length ? "60%" : "fit-content",
        }}
      >
        <Typography variant="h6" component="h2">
          Ver liquidación
        </Typography>
        <>
          <div className={`row-grid ${styles.grid}`} id="grid-container">
            <XGridDemo
              rows={employeeList}
              titles={transferenciasTitles}
              withoutRowSelection
              formik={createTransferFormik}
              data={createTransferData}
              type="see"
            />
          </div>
        </>
        <div
          style={{
            display: "flex",
            gap: "4rem",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          <div
            onClick={() => handleCloseModal(createTransferFormik.resetForm)}
            style={{ cursor: "pointer" }}
          >
            Cerrar
          </div>
        </div>
      </Box>
    </form>
  );
};

export default SeeTransferForm;
