/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Box } from "@mui/material";
import { FormikNumberInput } from "../../../../../../components/utils/inputs/FormikInputs";
import SubmitButton from "../../../../../../components/utils/button/SubmitButton";

const EditTransferModal = ({
  createTransferFormik,
  createTransferData,
  classes,
  handleTransferChange,
  style,
  row,
  setTransferEmployeeList,
  setModal,
  setSelected,
}) => {
  const editEmployee = () => {
    setTransferEmployeeList((prev) => {
      const updatedList = prev.map((employee) =>
        employee.id === row.id
          ? {
              ...employee,
              "Monto ARS": createTransferFormik.values.monto,
              Referencia: createTransferFormik.values.referencia,
            }
          : employee
      );
      const updatedDetail = updatedList.map((detail) =>
        detail.id === row.id
          ? {
              id: detail.id,
              monto: createTransferFormik.values.monto,
              referencia: createTransferFormik.values.referencia,
            }
          : {
              id: detail.id,
              monto: detail["Monto ARS"],
              referencia: detail.Referencia,
            }
      );
      console.log(updatedDetail, "detalle actualizado");
      createTransferFormik.setFieldValue("detalle", updatedDetail);
      return updatedList;
    });
    closeModal();
  };
  const closeModal = () => {
    createTransferFormik.setFieldValue("employeId", undefined);
    createTransferFormik.setFieldValue("monto", 0);
    createTransferFormik.setFieldValue("referencia", "");
    setSelected({ status: false, row: {} });
    setModal((prev) => ({ ...prev, isOpen: false, type: "" }));
  };

  useEffect(() => {
    createTransferFormik.setFieldValue("monto", Number(row["Monto ARS"]));
    createTransferFormik.setFieldValue("referencia", row.Referencia);
  }, []);

  return (
    <Box sx={style}>
      <FormikNumberInput
        formik={createTransferFormik}
        press={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        data={createTransferData}
        name={"monto"}
        onChange={(e) =>
          handleTransferChange(e.target.value, createTransferFormik, "monto")
        }
        onInput={(e) => {
          const regex = /^\d*(\.\d{0,2})?$/;
          if (!regex.test(e.target.value)) {
            e.target.value = e.target.value.slice(0, -1);
          }
        }}
        className={classes.input}
      />
      <FormikNumberInput
        formik={createTransferFormik}
        press={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        data={createTransferData}
        name={"referencia"}
        onChange={(e) =>
          handleTransferChange(
            e.target.value,
            createTransferFormik,
            "referencia"
          )
        }
        className={classes.input}
      />
      <div
        style={{
          display: "flex",
          gap: "4rem",
          alignItems: "center",
          marginBottom: "6px",
        }}
      >
        <div onClick={closeModal} style={{ cursor: "pointer" }}>
          Cerrar
        </div>
        <SubmitButton
          prevent={true}
          text="Aceptar"
          click={editEmployee}
          type="button"
        />
      </div>
    </Box>
  );
};

export default EditTransferModal;
