import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';

type Args = UserCredentialsType & {
    id: number
    version: number
};

export const movilinkCobradoMarcar = async (arg: Args) => {
    const body: AxiosPostBody = {
        service: "MovilinkCobradoMarcar",
        params: {
            "movilinkId": arg.id,
            "movilinkVersion": arg.version,
        }
    }
    const result = await axiosPost('Y', body, arg);
    return result
}