import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType, UsuarioLinkType } from '../../../types/types';

type Args = UserCredentialsType & {
    dni: string
};

export const usuarioLinkGet = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "UsuarioLinkGet",
        "params": {
            "usuarioLinkDNI": arg.dni
        }
    }

    const result = await axiosPost<UsuarioLinkType>('Y', body, arg);
    return result.result
}