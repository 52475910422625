import styles from "./HeaderTableButton.module.scss";

export default function HeaderButtonTable({
  text,
  icon,
  data,
  placeholder,
  children,
  handleClick,
  disabled = false,
}) {
  return (
    <div className={`${styles.tooltip} ${styles.bottom}`}>
      <span className={styles.tiptext}>{text}</span>
      <span className={styles.placeholder}>{placeholder}</span>
      <button
        disabled={disabled}
        className={`${styles.buttons} ${disabled ? styles.disabled : ""}`}
        onClick={handleClick}
      >
        {icon ? <img src={icon} alt={text} /> : children}
      </button>
    </div>
  );
}
