import React, { useEffect, useState } from 'react';
import {Card} from "../../../components/alta-comercios/card"; 
import { Layaout } from '../../../components/layaout';
import { Box, Typography } from '@mui/material';
import IconDefaultComercion from "../../../assets/icons/icono-default-comercios.png"
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { useNavigate } from 'react-router-dom';
import contact from "../../../assets/icons/stepper_contacto.svg"
import document from "../../../assets/icons/stepper_documentacion_fiscal.svg"
import identification from "../../../assets/icons/stepper_identificacion.svg"
import info from "../../../assets/icons/stepper_informacion_fiscal.svg"
import localization from "../../../assets/icons/stepper_localizacion.svg"
import ModalAltaComercio from '../../../components/alta-comercios/modal';
import { userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilValue } from 'recoil';

 const renderCards = [
 {title: "Identificación", subtitle:"Completá los datos de tu comercio", img:identification},
 {title: "Contacto", subtitle:"Completá los datos de contacto de tu comercio", img:contact},
 {title: "Información Fiscal", subtitle:"Completá los datos de información Fiscal", img:info},
 {title: "Documentación Fiscal", subtitle:"Subí la documentación fiscal de tu comercio", img:document},
 {title: "Localización", subtitle:"Ingresá una dirección para tu comercio", img:localization}
]

function NecesitamosDatos() {
    const credentials = useRecoilValue(userCredentials);
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/alta-comercios');
    }

    const handleBack = () => {
        navigate('/');
    };
    
  return (
    <>
    <Layaout title={"Necesitamos algunos datos para dar de alta tu Comercio "} >
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={2}> 
            {
                renderCards.map((e, i)=> (
                    <Card title={e.title} subtitle={e.subtitle} image={e.img} opacity={i === 0 ? false : true}/>
                ))
            }
        </Box>
        <Box marginTop={"30px"}> 
            <ButtonGradient text={"Continuar"} size={"medium"} action={handleContinue}/>
        </Box>
        <Box marginTop={"15px"} marginBottom={"15px"}> 
            <ButtonGradient text={"Ir al Inicio"} size={"medium"} color="transparent" action={handleBack}/>
        </Box>
       
    </Layaout>
    </>
  )
}

export{ 
    NecesitamosDatos
}
