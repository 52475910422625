import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';
import axiosPost from '../axiosPost';

export const tipoDeSociedadGet = async (arg: UserCredentialsType) => {
    const body: AxiosPostBody = {
        "service": "TipoDeSociedadGet",
        "params": {}
    };

    try {
        const result = await axiosPost('Y', body, arg)
        console.log(result)
        return result
    } catch (err) {
        console.log(err)
    }
};