import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Args = UserCredentialsType & {
    merchantLiquidacionId: number;
};

export const MerchantLiquidacionDetalleGet = async (args: Args) => {
  const body: AxiosPostBody = {
    service: "MerchantLiquidacionDetalleGet",
    params: {
    merchantLiquidacionId: args.merchantLiquidacionId,
    },
  };

  try {
    const result = await axiosPost("Y", body, args);

    return result.result;
  } catch (err) {
    console.log(err);
  }
};
