import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { MerchantCobroType, UserCredentialsType } from '../../../types/types';

type Args = UserCredentialsType & {
    desde: string
    hasta: string
    estado: string | null
    medioDePago: string | null
}

export const merchantCobroGet = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "MerchantCobroGet",
        "params": {
            "merchantCobroMerchantId": arg.merchantId,
            "merchantCobroEstado": arg.estado,
            "merchantCobroMedioPago": arg.medioDePago,
            "fechaDesde": arg.desde,
            "fechaHasta": arg.hasta
        }
    };

    const result = await axiosPost<MerchantCobroType>('Y', body, arg);
    return result.result
}