import React from 'react';
import classes from './Importe.module.scss';

type Props = {
    importe: string;
    setImporte: React.Dispatch<React.SetStateAction<string>>
}

export default function Importe({ importe, setImporte }: Props) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;
        if(value.length === 0) return setImporte(value);
        value = value.
            replace('.', ',')
            .replace(/[^0-9$.,]/g, '')
        if (/,/.test(value) && value.split(',').length > 1) value = value.split(',').slice(0, 2).join(',')
        if (/,/.test(value) && value.split(',')[1] && value.split(',')[1].length > 2) value = value.slice(0, value.length - 1);
        setImporte(value)
    };

    return (
        <div className={classes.container}>
            <label htmlFor="importe">Importe</label>
            <span>$</span>
            <input
                type="text"
                name="importe"
                placeholder='540,60'
                id="importe"
                value={importe}
                onChange={handleChange}
            />
        </div>
    )
};