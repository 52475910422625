import { makeStyles } from "@mui/styles";

export const style = {
    alignItems: "center",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: "50%",
    p: 4,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "600px",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "1rem",
    gap: "1rem",
    padding: "32px 54px",
  };

export const FiltrosStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.5em',
        width: "100%"
    },
    filtros: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        marginRight: '2rem',
        gap: "30px",
        width: "fit-content",
    },
    inputContainer: {
        position: 'relative'
    },
    input: {
        flexDirection: 'column',
        marginRight: '2rem',
        marginTop: "8px",
        '& .MuiInputLabel-formControl': {
            transform: 'translateY(22px)',
            marginRight: '1rem',
            position: 'static!important',
            fontWeight: 'bold',
        },
        '& .MuiInputBase-root': {
            width: 'auto'
        },
    },
    inputLarge: {
        flexDirection: 'column',
        // marginRight: '2rem',
        marginTop: "0px !important",
        '& .MuiInputLabel-formControl': {
            transform: 'translateY(22px)',
            // marginRight: '1rem',
            position: 'static!important',
            fontWeight: 'bold',
        },
        '& .MuiInputBase-root': {
            width: '100%'
        },
    },
    iconoBorrar: {
        height: '16px',
        width: '16px',
        position: 'absolute',
        right: '2rem',
        bottom: '16px',
        cursor: 'pointer'
    },
    total: {
        margin: 0,
        height: '4rem', 
        display: 'flex', 
        alignItems: 'center',
        flexGrow: 1,
        paddingBottom: '6px',
        boxSizing: 'border-box',
    },
    marginNormal: {
        height: '48px',
        marginBottom: '8px',
        marginTop: '16px',
        display: 'flex',
        alignItems: 'flex-end'
    }
}));