import { Box, Grid } from "@mui/material";
import React from "react";
import {
  FormikRadioGroup,
  FormikTextInput,
} from "../../../utils/inputs/FormikInputs";
import { useStyles } from "./styles";
import { useRecoilValue } from "recoil";
import { afipData } from "../../../../recoilState/GlobalState";

function FormInformacionFiscal2({ formik }) {
  const styles = useStyles();
  const recoilAfipData = useRecoilValue(afipData);
  const dataFormik = {
    iva: { label: "¿Retiene IVA?*" },
    ganancias: { label: "¿Retiene Impuestos a las ganancias?*" },
    ingresosBrutos: { label: "¿Retiene Ingresos Brutos?*" },
    alicuota: { label: "Alícuota de retención de Ingresos Brutos" },
    combustible: { label: "Régimen de combustible*" },
    microempresas: { label: "Régimen de Microempresas*" },
  };
  return (
    <Grid container width={"100%"} maxWidth={700} padding={"20px 0 50px 0"}>
      <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"50%"}
          className={styles.containerInput}
        >
          <FormikRadioGroup
            name={"iva"}
            formik={formik}
            label={dataFormik.iva.label}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"50%"}
          className={styles.containerInput}
        >
          <FormikRadioGroup
            name={"ganancias"}
            formik={formik}
            label={dataFormik.ganancias.label}
          />
        </Box>
      </Grid>
      <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"50%"}
          className={styles.containerInput}
        >
          <FormikRadioGroup
            name={"ingresosBrutos"}
            formik={formik}
            label={dataFormik.ingresosBrutos.label}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"50%"}
          className={styles.containerInput}
        >
          <FormikTextInput
            data={dataFormik}
            name={"alicuota"}
            formik={formik}
            variant="standard"
            disabled={
              formik.values.ingresosBrutos === "" ||
              formik.values.ingresosBrutos === "false"
            }
          />
        </Box>
      </Grid>
      <Grid item width={"100%"} display={"flex"} gap={4}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"50%"}
          className={styles.containerInput}
        >
          <FormikRadioGroup
            name={"combustible"}
            formik={formik}
            label={dataFormik.combustible.label}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"50%"}
          className={styles.containerInput}
        >
          <FormikRadioGroup
            name={"microempresas"}
            formik={formik}
            label={dataFormik.microempresas.label}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export { FormInformacionFiscal2 };
