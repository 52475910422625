import React, { useState } from 'react';
import {Layaout} from "../../../components/layaout/index";
import { useFormik } from 'formik';
import {FormikTextInput} from "../../../components/utils/inputs/FormikInputs"
import { useStyles } from './styles';
import * as yup from "yup";
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import {AFIPDatosGet} from "../../../services/hooli-services/AFIP/AFIPDatosGet"
import {MerchantCheck} from "../../../services/hooli-services/Merchants/MerchantCheck"
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { afipData, comercioData, loading, userCredentials } from '../../../recoilState/GlobalState';
import { useNavigate } from 'react-router-dom';

import { getLocalidades } from '../../../utils/getLocalidades';
import Loading from '../loading/Loading';

function IngresoCUIT() {
    const inputCUITData = {cuit: {label: "Número", placeholder:"30-12345678-9"}}
    const schemValidation = yup.object({
        cuit: yup.string().required("Debes ingresar el número de CUIL o CUIT").max(13,"Debes ingresar menos de 13 digitos incluidos guiones").min(13,"Debes ingresar más de 13 digitos incluidos guiones").matches(/^\d{2}-\d{8}-\d$/,"Debes ingresar un formato válido"),
    });
    const credentials = useRecoilValue(userCredentials);
    const setAfipData = useSetRecoilState(afipData);
    const [load, setLoad] = useRecoilState(loading);
    const [comercio, setComercio] = useRecoilState(comercioData);
    const [titleError, setTitleError] = useState("");
    const [textError, setTextError] = useState("");
    const styles = useStyles();
    const navigate = useNavigate();
    
    const handleSubmit = async (values)=> { 
        try { 
            setLoad(true);
            const check = await MerchantCheck(credentials, values.cuit);
            console.log(check)
            if(check.status.errcode === 30067){
                const response = await AFIPDatosGet(credentials, values.cuit); 
                let localidades = null; 
                if(response.status.code === 1 && response.result.length > 0){ 
                    setAfipData({...response.result[0], cuit:values.cuit});
                    localidades = await getLocalidades(response.result[0]);
                    sessionStorage.setItem("localidades", JSON.stringify(localidades));
                    setComercio({
                        merchantPFPJ: response.result[0].personaFJ,
                        merchantCUIT: values.cuit,
                        merchantRZ: response.result[0].personaFJ === "J" ? response.result[0].merchantRZ : `${response.result[0].usuarioApellidos}, ${response.result[0].usuarioNombres}`,
                        actividadAFIPCod: response.result[0].actividadAFIPCod,
                        condicionAFIPCod: response.result[0].condicionAFIPCod,
                    });
                    navigate("/necesitamos-datos");
                }
            } else if (check.status.errcode === 30095){
                setLoad(false);
                setTitleError("Este es un CUIT ya registrado");
                setTextError("Ya existe un comercio con este CUIT");
                // setIsOpen(true);
            } else {
                setLoad(false);
                setTitleError("Ups...");
                setTextError("Algo salio mal");
                // setIsOpen(true);
            }
        }catch(e){ 
            console.log("error", e)
        }
    }

    const formik = useFormik({
        initialValues: {
            cuit: "",
        },
        validationSchema: schemValidation,
        onSubmit: handleSubmit,
       initialErrors:{
        cuit: true
       }
    });

  return (
    <>
    {load ? <Loading/> :<Layaout title={"Ingresá el número de CUIT que identifica a tu comercio"} subtitle={"Vamos a hacer una consulta a la AFIP con la CUIT de tu negocio."}>
        <div className={styles.containerInput}> 
             <FormikTextInput
                formik={formik}
                data={inputCUITData}
                name="cuit"
            /> 
        </div>
    <ButtonGradient action={formik.handleSubmit} text={"Continuar"} size={"medium"} disabled={!formik.isValid}/>
    </Layaout>}
    </>
  )
}

export {
    IngresoCUIT
}
