import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(()=>({
    transparent:{
        opacity: "40%",
        '& img':{
            filter: "brightness(0) saturate(100%) invert(36%) sepia(20%) saturate(1456%) hue-rotate(190deg) brightness(104%) contrast(84%)"
        }
    },
    imgComerciosDefault:{
        width: "40px",
        height: "40px",
        
    }, 
    imgAngleBrackets: {
        width: "16px",
        height: "16px",
        
    },
    title: { 
        color: "#5567AE",
        fontSize: "15px !important",
    },
    subtitle: { 
        fontSize:"12px !important",
        color:"#999999"
    }
}));