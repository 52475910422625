import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import lupa from "../../../../assets/icons/lupa.png";
import download from "../../../../assets/icons/exportar.png";
import reload from "../../../../assets/icons/actualizar.svg";

import HeaderButton from "../../../../components/utils/button/HeaderButton";
import HeaderTableButton from "../../../../components/utils/button/HeaderTableButton";
import Buttons from "../../../../components/utils/buttonsContainer/Buttons";

const Actions = ({
  isBalance,
  isEmployees,
  isSales,
  isPayrolls,
  status,
  updateList,
  handleExport,
  handleCreateModal,
  row,
  handleUpdateModal,
  isTransfers,
  handleSeeModal,
}) => {
  return (
    <Buttons>
      {isBalance && status && (
        <HeaderButton text="Movimientos" icon={lupa} data={{ action: "" }} />
      )}
      {isBalance && !status && (
        <HeaderButton
          text={"Actualizar pagina"}
          icon={reload}
          updateList={updateList}
        />
      )}
      {isSales && (
        <HeaderButton
          text={"Descargar tabla"}
          icon={download}
          handleClick={handleExport}
        />
      )}
      {isPayrolls && (
        <>
          <HeaderTableButton
            text={"Agregar nómina"}
            link={`autenticaciones`}
            handleClick={handleCreateModal}
          >
            <AddRoundedIcon />
          </HeaderTableButton>
          <HeaderTableButton
            text={"Actualizar nomina"}
            disabled={!status}
            handleClick={handleUpdateModal}
          >
            <EditRoundedIcon />
          </HeaderTableButton>
        </>
      )}
      {isEmployees && (
        <>
          <HeaderTableButton
            text={"Agregar empleado"}
            handleClick={handleCreateModal}
          >
            <AddRoundedIcon />
          </HeaderTableButton>
          <HeaderTableButton
            text={"Actualizar empleado"}
            disabled={!status || (status && row.Tipo !== "Entidad Externa")}
            handleClick={handleUpdateModal}
          >
            <EditRoundedIcon />
          </HeaderTableButton>
        </>
      )}
      {isTransfers && (
        <>
        <HeaderTableButton
          text={"Nueva Liquidación"}
          handleClick={handleCreateModal}
        >
          <AddRoundedIcon />
        </HeaderTableButton>
        <HeaderTableButton
            text={"Actualizar Liquidación"}
            disabled={!status || row.Estado !== "Planificado"}
            handleClick={handleUpdateModal}
          >
            <EditRoundedIcon />
        </HeaderTableButton>
        <HeaderTableButton
            text={"Ver Liquidación"}
            disabled={!status}
            handleClick={handleSeeModal}
          >
            <VisibilityRoundedIcon />
        </HeaderTableButton>
        </>
        
      )}
    </Buttons>
  );
};

export default Actions;
