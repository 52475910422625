import React from "react";
// styles
import classes from "./HeaderButton.module.css";
// recoil
import { useSetRecoilState } from 'recoil';
import { formIsOpen } from '../../../recoilState/GlobalState';

export default function CircularButton(props){
    const { text, icon, handleClick, data, deleteImage, setOpen, exportar, updateList } = props;
    const setIsOpen = useSetRecoilState(formIsOpen);
    
    return (
        <div className={`${classes.tooltip} ${classes.bottom}`}>
            <div
                className={classes.buttons}
                onClick={() => {
                    if(text === "Actualizar pagina") return updateList();
                    if(exportar) return exportar();
                    if(handleClick) handleClick(data);
                    if(deleteImage) deleteImage()
                    if(setOpen) setOpen(true);
                    if(data?.action !== 'noOpen') setIsOpen(true);
                }}
            >
                <img src={icon} alt={text} />
                <span className={classes.tiptext}>{text}</span>
            </div>
        </div>
    );
};
