import { useFormik } from "formik";

import { Box, Typography } from "@mui/material";

import { MerchantNominaEmpleadoIns } from "../../../../services/hooli-services";

import {
  FormikTextInput,
  FormikSelectInput,
} from "../../../../components/utils/inputs/FormikInputs";
import SubmitButton from "../../../../components/utils/button/SubmitButton";
import * as yup from "yup";
import { removeAddedOption } from "../../utils";

const ExternalEntityForm = ({
  classes,
  credentials,
  employeeType,
  handleCloseModal,
  payrollsOptions,
  reset,
  retrieveEmployees,
  style,
}) => {
  const filteredOption = removeAddedOption(payrollsOptions);
  const externalEntityFormik = useFormik({
    initialValues: {
      cuit: "",
      denominacion: "",
      cuentaBancaria: "",
      nominaId: filteredOption[0]?.merchantNominaId,
    },
    validationSchema: yup.object({
      cuit: yup
        .string()
        .required("Campo requerido")
        .matches(/^\d{2}-\d{8}-\d$/, "El cuit debe tener 11 digitos y guiones"),
      cuentaBancaria: yup
        .string()
        .required("Campo requerido")
        .matches(/^\d{22}$/, "Debe tener 22 digitos"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await MerchantNominaEmpleadoIns({
        merchantNominaEmpleadoTipo: employeeType,
        merchantEmpleadoCUIT: values.cuit,
        merchantEmpleadoDenominacion: values.denominacion,
        merchantEmpleadoCuenta: values.cuentaBancaria,
        merchantId: credentials.merchantId,
        merchantNominaId: values.nominaId,
      });
      handleCloseModal(resetForm);
      retrieveEmployees();
    },
  });

  const externalEntityData = {
    cuit: {
      label: "Ingresá el CUIT",
    },
    denominacion: {
      label: "Denominación",
    },
    cuentaBancaria: {
      label: "Ingresá cuenta bancaria (CBU/CVU)",
    },
    nominaId: {
      label: "Seleccioná una nómina",
    },
  };

  return (
    <>
      <form onSubmit={externalEntityFormik.handleSubmit}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Agregar empleado de entidad externa
          </Typography>
          <FormikTextInput
            name={"cuit"}
            data={externalEntityData}
            formik={externalEntityFormik}
            className={classes.inputLarge}
            fullWidth={true}
          />
          <FormikTextInput
            name={"denominacion"}
            data={externalEntityData}
            formik={externalEntityFormik}
            className={classes.inputLarge}
            fullWidth={true}
          />
          <FormikTextInput
            name={"cuentaBancaria"}
            data={externalEntityData}
            formik={externalEntityFormik}
            className={classes.inputLarge}
            fullWidth={true}
          />
          <FormikSelectInput
            data={externalEntityData}
            name="nominaId"
            formik={externalEntityFormik}
            options={filteredOption}
            optionKey="merchantNominaId"
            optionName="merchantNominaNombre"
            optionValue="merchantNominaId"
            className={classes.inputLarge}
            fullWidth={true}
          />
          <div style={{ display: "flex", gap: "4rem", alignItems: "center" }}>
            <div
              onClick={() => handleCloseModal(reset)}
              style={{ cursor: "pointer" }}
            >
              Cerrar
            </div>
            <SubmitButton text="Aceptar" />
          </div>
        </Box>
      </form>
    </>
  );
};

export default ExternalEntityForm;
