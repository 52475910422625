import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
// styles
import "../pages.css";
import classes from './movimientos.module.scss';
// utils
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDF from './PDF';
// consts
import { resumen as titles } from "../../consts/titulos-de-tablas";
import { Routes } from "../../consts/Routes-App";
// Form
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormikDateInput, FormikSelectInput } from '../../components/utils/inputs/FormikInputs';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { MWResumenGet } from '../../services/hooli-services';
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { routeInfo, userCredentials } from '../../recoilState/GlobalState';

const today = new Date().toISOString().substring(0, 10);
const validationSchema = yup.object({
    hasta: yup.date().max(new Date().toISOString().substring(0, 10), 'Fecha maxima hoy'),
    desde: yup.date().max(yup.ref('hasta'), 'No puede ser superior a la segunda fecha')
});

export default function Movimientos() {
    const firstRender = useRef(true);
    const [movimientos, setMovimientos] = useState([]);
    const [filtros, setFiltros] = useState({ moneda: "ARS", desde: moment(today).subtract(6, 'days').format('YYYY-MM-DD'), hasta: today });
    const [cargando, setCargando] = useState(false);

    const setRoute = useSetRecoilState(routeInfo);
    const credentials = useRecoilValue(userCredentials);

    const retrieveMovimientos = async (filtros) => {
        if (firstRender.current) firstRender.current = false;
        setCargando(true);
        const payload = {
            ...credentials,
            "merchantId": credentials.merchantId,
            "fechaDesde": filtros.desde,
            "fechaHasta": filtros.hasta,
            "monedaCod": filtros.moneda
        };
        const result = await MWResumenGet(payload);
        const movimientos = result.reduce((acc, item) => {
            const {
                movimientoOrigenDestino,
                movimientoDebito,
                movimientoFecha,
                movimientoId,
                movimientoCredito,
                saldoParcial,
                tipoMovimientoNombre
            } = item

            const movimiento = {
                id: movimientoId,
                [titles[0]]: movimientoFecha,
                [titles[1]]: tipoMovimientoNombre,
                [titles[2]]: movimientoDebito,
                [titles[3]]: movimientoCredito,
                [titles[4]]: movimientoOrigenDestino,
                [titles[5]]: saldoParcial
            };
            return [...acc, movimiento];
        }, []);
        setMovimientos(movimientos);
        setCargando(false);
    }

    useEffect(() => {
        setRoute(Routes[window.location.pathname.split('/')[1]])
    }, []);

    const formik = useFormik({
        initialValues: filtros,
        validationSchema,
        onSubmit: (values) => {
            retrieveMovimientos(values)
            setFiltros(filtros);
        }
    });

    const data = { moneda: { label: "Moneda" }, desde: { label: 'Desde' }, hasta: { label: 'Hasta' } };
    const monedas = [{ monedaCod: 'ARS', monedaNombre: 'Peso Argentino' }, { monedaCod: 'HC', monedaNombre: 'Hooli Coins' }];

    return (
        <main>
            <div className={classes.resumen}>
                <form onSubmit={formik.handleSubmit}>
                    <FormikSelectInput
                        data={data}
                        name="moneda"
                        formik={formik}
                        options={monedas}
                        optionKey="monedaCod"
                        optionName="monedaNombre"
                        optionValue="monedaCod"
                        disabled={cargando}
                    />
                    <div className={classes.row}>
                        <FormikDateInput
                            data={data}
                            name="desde"
                            formik={formik}
                            disabled={cargando}
                            className={classes.input}
                        />
                        <FormikDateInput
                            data={data}
                            name="hasta"
                            formik={formik}
                            disabled={cargando}
                            className={classes.input}
                        />
                    </div>
                    <button className={classes.button} type="submit">Generar PDF</button>
                </form>
                {cargando ?
                    <CircularIndeterminate /> :
                    movimientos.length > 0 ?
                        <PDFDownloadLink document={<PDF rows={movimientos} titles={titles} filtros={formik.values} credentials={credentials} />} fileName="resumen.pdf">
                            <button className={classes.button}>Descargar PDF</button>
                        </PDFDownloadLink>
                        : firstRender.current ? null : <p>Sin movimientos en este período</p>
                }
            </div>
        </main>
    )
};
