import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Paso1.module.scss";
// Components
import {
  PagosButtons,
  PagosImporte,
  PagosInput,
} from "../../../components/pagos";
import { CircularIndeterminate } from "../../../components";
// Services
import { usuarioBasicosGet } from "../../../services/hooli-services";
// Recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  routeInfo,
  snackbarData,
  userCredentials,
} from "../../../recoilState/GlobalState";
// Types
import { UserCredentialsType } from "../../../types/types";

export default function Paso1() {
  const navigate = useNavigate();
  const credentials = useRecoilValue(userCredentials) as UserCredentialsType;

  const [importe, setImporte] = useState<string>("");
  const [dni, setDNI] = useState<string>("");
  const [factura, setFactura] = useState<string>("");
  const [concepto, setConcepto] = useState<string>("");
  const [cargando, setCargando] = useState<boolean>(false);

  const setRoute = useSetRecoilState(routeInfo);
  const setSnackbarInfo = useSetRecoilState(snackbarData);

  const url = new URLSearchParams(window.location.search);
  const tipo = url.get("tipo") as "L" | "S";

  const cleanSnackbar = () =>
    setTimeout(
      () => setSnackbarInfo({ severity: "success", message: "", open: false }),
      3000
    );

  const handleContinue = async () => {
    if (parseInt(importe) <= 10) {
      setSnackbarInfo({
        severity: "warning",
        message: "Importe invalido. Debe ser mayor a $10",
        open: true,
      });
      return cleanSnackbar();
    }
    if (dni.length < 7 || dni.length > 8) {
      setSnackbarInfo({
        severity: "warning",
        message: "DNI invalido.",
        open: true,
      });
      return cleanSnackbar();
    }
    if (tipo === "S") {
      setCargando(true);
      const result = await usuarioBasicosGet({ ...credentials, dni });
      setCargando(false);
      if (result.length === 0) {
        setSnackbarInfo({
          severity: "warning",
          message: "No es usuario Hooli",
          open: true,
        });
        return cleanSnackbar();
      }
    }
    navigate(
      `/cobros/paso2?importe=${importe}&dni=${dni}&factura=${factura}&concepto=${concepto}&tipo=${tipo}`
    );
  };
  const handleCancel = () =>
    credentials.rolCod === ("MD" || "MA")
      ? navigate("/dashboard")
      : navigate("/cobros");

  useEffect(() => {
    setRoute({
      route: "/cobros/paso1",
      wordKey: `Cobros > ${
        tipo === "L" ? "Link de pago" : "Solicitud de pago"
      } > Paso 1`,
    });
  }, []);

  if (cargando)
    return (
      <main className={classes.main}>
        <CircularIndeterminate />
      </main>
    );

  return (
    <main className={classes.main}>
      <h4 className={classes.title}>Ingresar datos de la operación.</h4>
      <PagosImporte importe={importe} setImporte={setImporte} />
      <PagosInput
        label="DNI del cliente"
        placeholder="99.999.999"
        name="DNI"
        value={dni}
        setValue={setDNI}
      />
      <PagosInput
        label="Número de factura (opcional)"
        placeholder="Factura"
        name="Factura"
        value={factura}
        setValue={setFactura}
      />
      <PagosInput
        label="Concepto (opcional)"
        placeholder="Varios"
        name="concepto"
        value={concepto}
        setValue={setConcepto}
      />
      <PagosButtons
        handleContinue={handleContinue}
        handleCancel={handleCancel}
      />
    </main>
  );
}
