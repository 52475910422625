import axios from "axios";

async function getLocalidades (afipData) {


        try {
            const {data} = await axios.get('/localidades.json')
           
            return data.result.filter((localidad)=> afipData.domicilioFiscalProvinciaId === localidad.provinciaId)
                
         }catch(e){
            console.log("archivo zip e", e)
         }

        
    
}
export {
    getLocalidades
}