import { useState } from "react";
import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";

import {
  MerchantNominaEmpleadoIns,
  usuarioBasicosGet,
} from "../../../../services/hooli-services";

import {
  FormikTextInput,
  FormikSelectInput,
} from "../../../../components/utils/inputs/FormikInputs";
import SubmitButton from "../../../../components/utils/button/SubmitButton";

import { removeAddedOption } from "../../utils";

const UserHooliForm = ({
  cargando,
  style,
  classes,
  employeeType,
  credentials,
  handleCloseModal,
  reset,
  retrieveEmployees,
  payrollsOptions,
}) => {
  const [users, setUsers] = useState([]);

  const filteredOption = removeAddedOption(payrollsOptions);
  const userHooliFormik = useFormik({
    initialValues: {
      dniUsuario: "",
      usuarioId: "",
      nominaId: filteredOption[0]?.merchantNominaId,
    },
    onSubmit: async (values, { resetForm }) => {
      if (!!users.length) {
        await MerchantNominaEmpleadoIns({
          merchantNominaEmpleadoTipo: employeeType,
          merchantId: credentials.merchantId,
          merchantNominaId: values.nominaId,
          usuarioId: values.usuarioId,
        });
        handleCloseModal(resetForm);
        retrieveEmployees();
      } else {
        retrieveUsers();
      }
    },
  });

  const merchantHooliData = {
    dniUsuario: { label: "Ingresa el DNI" },
    usuarioId: { label: "Elegí un usuario" },
    nominaId: { label: "Seleccioná una nómina" },
  };

  const retrieveUsers = async () => {
    const response = await usuarioBasicosGet({
      dni: userHooliFormik.values.dniUsuario,
    });
    const responseFormat = response.map((user) => {
      return {
        nombreCompleto: `${user.usuarioApellidos} ${user.usuarioNombres}`,
        ...user,
      };
    });
    setUsers(responseFormat);
    userHooliFormik.setFieldValue("usuarioId", response[0]?.usuarioId);
  };
  return (
    <form onSubmit={userHooliFormik.handleSubmit}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Agregar empleado de usuario Hooli
        </Typography>
        {!users.length && (
          <>
            <FormikTextInput
              name={"dniUsuario"}
              data={merchantHooliData}
              formik={userHooliFormik}
              disabled={cargando}
              className={classes.inputLarge}
            />
            <div style={{ display: "flex", gap: "4rem", alignItems: "center" }}>
              <div
                onClick={() => handleCloseModal(reset)}
                style={{ cursor: "pointer" }}
              >
                Cerrar
              </div>
              <SubmitButton text="Aceptar" />
            </div>
          </>
        )}
        {!!users.length && (
          <>
            <FormikSelectInput
              data={merchantHooliData}
              name="usuarioId"
              formik={userHooliFormik}
              disabled={cargando}
              options={users}
              optionKey="usuarioId"
              optionName="nombreCompleto"
              optionValue="usuarioId"
              className={classes.inputLarge}
              fullWidth={true}
            />
            <FormikSelectInput
              data={merchantHooliData}
              name="nominaId"
              formik={userHooliFormik}
              disabled={cargando}
              options={filteredOption}
              optionKey="merchantNominaId"
              optionName="merchantNominaNombre"
              optionValue="merchantNominaId"
              className={classes.inputLarge}
              fullWidth={true}
            />
            <div style={{ display: "flex", gap: "4rem", alignItems: "center" }}>
              <div
                onClick={() => handleCloseModal(reset)}
                style={{ cursor: "pointer" }}
              >
                Cerrar
              </div>
              <SubmitButton text="Aceptar" />
            </div>
          </>
        )}
      </Box>
    </form>
  );
};

export default UserHooliForm;
