import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Args = UserCredentialsType & {
    merchantLiquidacionId: number;
    merchantLiquidacionDetalle: {
        merchantNominaEmpleadoId: number;
        merchantLiquidacionMonto: number;
        merchantLiquidacionBono?: number;
      }[];
};

export const MerchantLiquidacionDetalleUpd = async (args: Args) => {
  const body: AxiosPostBody = {
    service: "MerchantLiquidacionDetalleUpd",
    params: {
        merchantLiquidacionId: args.merchantLiquidacionId,
        merchantLiquidacionDetalle: args.merchantLiquidacionDetalle,
    },
  };

  try {
    const result = await axiosPost("Y", body, args);

    return result.result;
  } catch (err) {
    console.log(err);
  }
};
