import { useState, useEffect, useCallback } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import Filtros from "../../components/filtros/Filtros";
// consts
import { ventas as titles } from "../../consts/titulos-de-tablas";
import { Routes } from "../../consts/Routes-App";
//Assets
import CircularIndeterminate from "../../components/utils/spinner/Spinner";
//Services
import { MWVentaGet } from "../../services/hooli-services";
// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  routeInfo,
  rowSelected,
  userCredentials,
} from "../../recoilState/GlobalState";
import { transformDate } from "../../services/Local-services";

export default function Ventas() {
  const [ventas, setVentas] = useState([]);
  const [filtros, setFiltros] = useState({
    desde: new Date().toISOString().substring(0, 10),
    hasta: new Date().toISOString().substring(0, 10),
  });
  const [cargando, setCargando] = useState(false);
  const [page, setPage] = useState(0);

  const setRow = useSetRecoilState(rowSelected);
  const setRoute = useSetRecoilState(routeInfo);
  const credentials = useRecoilValue(userCredentials);

  const loadPage = useCallback(
    (page, pageSize) => {
      retrieveVentas(page, pageSize);
    },
    [page]
  );

  async function retrieveVentas(page, pageSize) {
    setCargando(true);
    const payload = {
      ...credentials,
      merchantId: credentials.merchantId,
      fechaDesde: filtros.desde,
      fechaHasta: filtros.hasta,
      page: page,
      pageSize: pageSize || 14,
    };
    const result = await MWVentaGet(payload);
    const ventas = result.reduce((acc, item, index) => {
      const {
        merchantVentaMonto,
        merchantVentaNombreCompleto,
        merchantVentaNroDeDocumento,
        merchantVentaMedioPago,
        merchantVentaFH,
        merchantVentaConcepto,
        merchantVentaEstado,
        merchantVentaDetalle,
        merchantVentaAutorizacion,
        merchantVentaTransaccionInterna,
        merchantVentaCuotas,
        merchantVentaCupon,
        merchantVentaLote,
        merchantVentaOperacion,
      } = item;

      const venta = {
        id: index,
        [titles[0]]: `${transformDate(
          merchantVentaFH?.substring(0, 10)
        )} ${merchantVentaFH?.substring(11, 16)}`,
        [titles[1]]: merchantVentaAutorizacion,
        [titles[2]]:
          merchantVentaEstado === "A"
            ? "Autorizado"
            : merchantVentaEstado === "V"
            ? "Por acreditar"
            : "Acreditado",
        [titles[3]]: merchantVentaNombreCompleto,
        [titles[4]]: merchantVentaNroDeDocumento,
        [titles[5]]:
          merchantVentaMedioPago === "H"
            ? "Hooli Money"
            : merchantVentaMedioPago === "C"
            ? "TC - " + merchantVentaDetalle
            : merchantVentaMedioPago === "D"
            ? "TD - " + merchantVentaDetalle
            : merchantVentaMedioPago === "B"
            ? "BNLP"
            : "Transferencia",
        [titles[6]]: merchantVentaCuotas,
        [titles[7]]: merchantVentaConcepto,
        [titles[8]]: merchantVentaTransaccionInterna,
        [titles[9]]: merchantVentaLote,
        [titles[10]]: merchantVentaCupon,
        [titles[11]]: merchantVentaOperacion,
        [titles[12]]: merchantVentaMonto,
      };
      return [...acc, venta];
    }, []);
    setVentas(ventas);
    setCargando(false);
  }

  useEffect(() => {
    if (Object.keys(credentials).length > 0) retrieveVentas();
  }, [credentials, filtros]);

  useEffect(() => {
    setRoute(Routes[window.location.pathname.split("/")[1]]);
    setRow({ status: false, row: {} });
  }, []);

  return (
    <main>
      <Filtros
        titles={titles}
        rows={ventas}
        filtros={filtros}
        setFiltros={setFiltros}
        updateList={retrieveVentas}
        isSales
      />
      <div className="row-grid" id="grid-container">
        {cargando ? (
          <CircularIndeterminate />
        ) : (
          <XGridDemo rows={ventas} titles={titles} loadPage={loadPage} />
        )}
      </div>
    </main>
  );
}
