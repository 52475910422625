import { useState } from "react";
import { Box } from "@mui/material";
import {
  FormikSelectInput,
  FormikNumberInput,
} from "../../../../../../components/utils/inputs/FormikInputs";
import SubmitButton from "../../../../../../components/utils/button/SubmitButton";

const AddTransferModal = ({
  createTransferFormik,
  setTransferEmployeeList,
  createTransferData,
  employeeList,
  style,
  classes,
  setModal,
  handleTransferChange,
  idKey,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState(employeeList[0]);
  const addEmployee = () => {
    const newEmployee = {
      ...selectedEmployee,
      id: `${selectedEmployee.id}-item-${idKey + 1}`,
      "Monto ARS": createTransferFormik.values.monto,
      Referencia: createTransferFormik.values.referencia,
    };
    setTransferEmployeeList((prev) => [...prev, newEmployee]);
    createTransferFormik.setFieldValue("detalle", [
      ...createTransferFormik.values.detalle,
      {
        id: selectedEmployee.id,
        monto: Number(createTransferFormik.values.monto),
        referencia: createTransferFormik.values.referencia,
      },
    ]);
    closeModal();
  };

  const closeModal = () => {
    createTransferFormik.setFieldValue("employeId", undefined);
    createTransferFormik.setFieldValue("monto", 0);
    createTransferFormik.setFieldValue("referencia", "");
    setModal((prev) => ({ ...prev, isOpen: false, type: "" }));
  };
  return (
    <Box sx={style}>
      <FormikSelectInput
        data={createTransferData}
        name="employeId"
        formik={createTransferFormik}
        options={employeeList}
        optionKey="id"
        optionName="Nombre"
        optionValue="id"
        className={classes.input}
        onChange={(e) => {
          const selected = employeeList.find(
            (employee) => employee.id === Number(e.target.value)
          );
          setSelectedEmployee(selected); // Actualiza el estado local
          createTransferFormik.setFieldValue("employeId", e.target.value);
        }}
      />
      <>
        <FormikNumberInput
          formik={createTransferFormik}
          press={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          data={createTransferData}
          name={"monto"}
          onChange={(e) =>
            handleTransferChange(e.target.value, createTransferFormik, "monto")
          }
          onInput={(e) => {
            const regex = /^\d*(\.\d{0,2})?$/;
            if (!regex.test(e.target.value)) {
              e.target.value = e.target.value.slice(0, -1); // Eliminar el último carácter inválido
            }
          }}
          className={classes.input}
        />
        <FormikNumberInput
          formik={createTransferFormik}
          press={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          data={createTransferData}
          name={"referencia"}
          onChange={(e) =>
            handleTransferChange(
              e.target.value,
              createTransferFormik,
              "referencia"
            )
          }
          className={classes.input}
        />
      </>

      <div
        style={{
          display: "flex",
          gap: "4rem",
          alignItems: "center",
          marginBottom: "6px",
        }}
      >
        <div onClick={closeModal} style={{ cursor: "pointer" }}>
          Cerrar
        </div>
        <SubmitButton
          prevent={true}
          text="Aceptar"
          click={addEmployee}
          type="button"
        />
      </div>
    </Box>
  );
};

export default AddTransferModal;
