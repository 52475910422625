import axiosPost from '../axiosPost';
import { UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

type Args = UserCredentialsType & {
    qr: string
};

export const LoginQREvent = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "LoginQREvent",
        "params": {
            "codigoQR": arg.qr
        }
    }
    
    try {
        const result = await axiosPost('N', body, arg);
        
        return result
    } catch (err) {
        console.log(err)
    }
}