import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { CajaType, UserCredentialsType } from '../../../types/types';

type Args = UserCredentialsType & {
    merchantCajaId: number
    usuarioId: number
    merchantPOSMonto: string
    gwcardFactura: string
    gwcardConcepto: string
}

export const merchantPosIns = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "MerchantPOSIns",
        "params": {
            "usuarioId": arg.usuarioId,
            "merchantId": arg.merchantId,
            "merchantCajaId": arg.merchantCajaId,
            "merchantPOSPresente": "N",
            "merchantPOSMonto": arg.merchantPOSMonto,
            "merchantPOSOrigenDatos": "M",
            "merchantPOSFactura": arg.gwcardFactura,
            "merchantPOSConcepto": arg.gwcardConcepto
        }
    }

    const result = await axiosPost<CajaType>('Y', body, arg);
    return result
}