/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";
import * as Yup from "yup";

import XGridDemo from "../../../utils/grid/Xgrid";
import SubmitButton from "../../../../components/utils/button/SubmitButton";
import { transferenciasUpdate as transferenciasTitles } from "../../../../consts/titulos-de-tablas";

import styles from "./index.module.scss";
import { MerchantLiquidacionDetalleGet } from "../../../../services/hooli-services/Liquidaciones/MerchantLiquidacionDetalleGet";
import { MerchantLiquidacionDetalleUpd } from "../../../../services/hooli-services/Liquidaciones/MerchantLiquidacionDetalleUpd";

const UpdateTransferForm = ({
  style,
  retrieveTransfers,
  handleCloseModal,
  row,
}) => {
  const [employeeList, setEmployeeList] = useState([]);

  const createValidationSchema = (employeeList) => {
    const schemaShape = {};

    employeeList.forEach((employee) => {
      // Validación para el campo de transferencia
      schemaShape[`transferencia_${employee.id}`] = Yup.number().test(
        "max-decimals",
        "Ingresá hasta dos decimales",
        (value) => {
          console.log(value);
          if (value !== undefined && value !== null) {
            const numValue = parseFloat(value);
            if (isNaN(numValue)) return false;

            const decimalPart = numValue.toString().split(".")[1];
            console.log(!decimalPart || decimalPart.length <= 2);
            return !decimalPart || decimalPart.length <= 2;
          }
          return true;
        }
      );

      // Validación para el campo de bono si es un Usuario Hooli
      if (employee.merchantEmpleadoTipo === "Usuario Hooli") {
        schemaShape[`bono_${employee.id}`] = Yup.number().test(
          "max-decimals",
          "Ingresá hasta dos decimales",
          (value) => {
            if (value !== undefined && value !== null) {
              const numValue = parseFloat(value);
              if (isNaN(numValue)) return false;

              const decimalPart = numValue.toString().split(".")[1];
              return !decimalPart || decimalPart.length <= 2;
            }
            return true;
          }
        );
      }
    });

    return Yup.object().shape(schemaShape);
  };

  const validationSchema = createValidationSchema(employeeList);

  const updateTransferFormik = useFormik({
    initialValues: {
      periodo: "",
      fechaDePago: "",
      notas: "",
      detalle: [],
      "Monto ARS": "",
      "Monto HC": "",
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      await MerchantLiquidacionDetalleUpd({
        merchantLiquidacionId: row.id,
        merchantLiquidacionDetalle: JSON.stringify(
          values.detalle.map((item) => ({
            merchantNominaEmpleadoId: item.merchantNominaEmpleadoId,
            merchantLiquidacionMonto: Number(item.merchantLiquidacionMonto),
            merchantLiquidacionBono:
              Number(item.merchantLiquidacionBono) ?? undefined,
          }))
        ),
      });
      retrieveTransfers();
      handleCloseModal(resetForm);
    },
    validationSchema,
    validateOnChange: true,
  });

  const employeesMapper = (employeesList = []) => {
    let detail = [];
    let data = employeesList.map((employee) => {
      detail.push({
        merchantNominaEmpleadoId: employee.merchantEmpleadoId,
        merchantLiquidacionMonto:
          employee.merchantLiquidacionDetalleMonto === null
            ? 0
            : employee.merchantLiquidacionDetalleMonto,
        merchantLiquidacionBono:
          employee.merchantLiquidacionDetalleBono === null
            ? 0
            : employee.merchantLiquidacionDetalleBono,
        cuit: employee.merchantEmpleadoCUIT,
      });
      return {
        "CUIT/CUIL": employee.merchantEmpleadoCUIT,
        Nombre: employee.merchantEmpleadoDenominacion,
        id: employee.merchantEmpleadoId,
        "Monto ARS": employee.merchantLiquidacionDetalleMonto,
        "Monto HC":
          employee.merchantLiquidacionDetalleBono === 0
            ? null
            : employee.merchantLiquidacionDetalleBono,
        merchantEmpleadoTipo: employee.merchantEmpleadoTipo,
      };
    });
    updateTransferFormik.setFieldValue("detalle", detail);
    return data;
  };

  useEffect(() => {
    if (!!employeeList.length) {
      employeeList.forEach((employee) => {
        console.log(employee);
        {
          employee["Monto ARS"] === null
            ? updateTransferFormik.setFieldValue(
                `transferencia_${employee.id}`,
                0
              )
            : updateTransferFormik.setFieldValue(
                `transferencia_${employee.id}`,
                employee["Monto ARS"]
              );
        }
        if (employee.merchantEmpleadoTipo === "Usuario Hooli") {
          employee["Monto HC"] === null
            ? updateTransferFormik.setFieldValue(`bono_${employee.id}`, 0)
            : updateTransferFormik.setFieldValue(
                `bono_${employee.id}`,
                employee["Monto HC"]
              );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList.length]);

  useEffect(() => {
    const getInfo = async () => {
      const result = await MerchantLiquidacionDetalleGet({
        merchantLiquidacionId: row.id,
      });
      setEmployeeList(employeesMapper(result));
    };
    getInfo();
  }, [row.id]);

  const updateTransferData = {
    ...employeeList.reduce((acc, employee) => {
      acc[`transferencia_${employee.id}`] = {};
      acc[`bono_${employee.id}`] = {};
      return acc;
    }, {}),
  };
  return (
    <form>
      <Box
        sx={{
          ...style,
          height: !!employeeList.length ? "100%" : "fit-content",
          width: !!employeeList.length ? "60%" : "fit-content",
        }}
      >
        <Typography variant="h6" component="h2">
          Modificar liquidación
        </Typography>
        <div className={`row-grid ${styles.grid}`} id="grid-container">
          <XGridDemo
            rows={employeeList}
            titles={transferenciasTitles}
            withoutRowSelection
            formik={updateTransferFormik}
            data={updateTransferData}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "4rem",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          <div
            onClick={() => handleCloseModal(updateTransferFormik.resetForm)}
            style={{ cursor: "pointer" }}
          >
            Cerrar
          </div>
          <SubmitButton
            text="Aceptar"
            prevent={true}
            click={updateTransferFormik.handleSubmit}
            type="button"
          />
        </div>
      </Box>
    </form>
  );
};

export default UpdateTransferForm;
