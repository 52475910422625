import React, { useEffect } from 'react';
import classes from './Home.module.scss';
import { Link } from 'react-router-dom'
// Assets
import LinkDePago from '../../../assets/icons/link-de-pago.png';
import SolicitudDePago from '../../../assets/icons/solicitud-de-pago.png';
import Cobros from '../../../assets/icons/cobros.png';
// Recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { routeInfo, userCredentials } from '../../../recoilState/GlobalState';
// Const
import { Routes } from '../../../consts/Routes-App';
// Types
import { UserCredentialsType } from '../../../types/types';

export default function Home() {
    const credentials = useRecoilValue(userCredentials) as UserCredentialsType;
    const setRoute = useSetRecoilState(routeInfo);

    useEffect(() => {
        if (credentials.rolCod === 'MD' || credentials.rolCod === 'MA') return window.location.assign('/dashboard');
        setRoute(Routes.pagos);
    }, []);

    return (
        <main>
            <div className={classes.container}>
                <Link to="/cobros/paso1?tipo=L">
                    <img src={LinkDePago} alt="Link de pago" />
                    <h4>Enviar Link de Pago</h4>
                    <p>Enviale a tus clientes que aún no tienen Hooli un link de pago por el monto que desees.</p>
                </Link>
                <Link to="/cobros/paso1?tipo=S">
                    <img src={SolicitudDePago} alt="Solicitud de pago" />
                    <h4>Enviar Solicitud de Pago</h4>
                    <p>Si tu cliente es usurio de Hooli cobra de forma simple, segura y rápida mediante una solicitud de pago.</p>
                </Link>
                <Link to="/cobros/cobros">
                    <img src={Cobros} alt="Cobros" />
                    <h4>Ver cobros</h4>
                    <p>Aquí podrás conocer el estado de tus links y solicitudes de pago enviadas.</p>
                </Link>
            </div>
        </main>
    )
};