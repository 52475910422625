import React, { useEffect, useState } from 'react';
import { FormInformacionFiscal2 } from '../../../components/alta-comercios/forms/informacion-fiscal2';
import { FormInformacionFiscal } from '../../../components/alta-comercios/forms/informacion-fiscal';
import { FormInformacionFiscal3 } from '../../../components/alta-comercios/forms/informacion-fiscal3';
import { useFormik } from 'formik';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import * as yup from "yup";
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, comercioData, userCredentials } from '../../../recoilState/GlobalState';
import {UsuarioAdicionalGet} from "../../../services/hooli-services/UsuarioAdicional/UsuarioAdicionalGet";
import {PaisGet} from "../../../services/hooli-services/Pais/PaisGet";


function ContainerInformacionFiscal({nextStep, stepInfoFiscal, setStepInfoFiscal}) {

    const [usuarioAdicional, setUsuarioAdicional] = useState(null)
    const recoilAfipData = useRecoilValue(afipData);
    const [comercio, setComercio] = useRecoilState(comercioData);
    const [paises,setPaises] = useState([]);
    const credentials = useRecoilValue(userCredentials);

    const getUsuarioAdicional = async ()=> { 
        const response = await UsuarioAdicionalGet(credentials); 
        setUsuarioAdicional(response.result[0]);
    }

    const getPais = async ()=> {
        const response = await PaisGet(credentials);
        if(response.status.code === 1){ 
            setPaises(response.result.filter(p => p.paisEsArgentina !== "Y"));
        }
    }

    useEffect(()=> {
        getPais();
    },[]);
    console.log(paises)

    const validationSchema = yup.object({
        regimenIngresosBrutos: yup.string().required("Se debe ingresar regimen de ingresos brutos"),
        provinciaIngresosBrutos: yup.object().shape({
            provinciaId: yup.string().required(),
            provinciaNombre: yup.string().required()
        }).nullable(),
        numeroIngresosBrutos: yup.string().required("Se debe ingresar ingresos brutos")
    })
    const validationSchema2 = yup.object({
        iva: yup.boolean().required("Debes seleccionar una opción"),
        ganancias: yup.boolean().required("Debes seleccionar una opción"),
        ingresosBrutos: yup.boolean().required("Debes seleccionar una opción"),
        alicuota:  yup.number("Debe ser un numero").nullable(true).when("ingresosBrutos", {
            is: true,
            then: yup.number("Debe ser un numero").max(100, "Debe ser un valor menor a 100").nullable(true).required("Debes ingresar una alicuota")
          }),
        combustible: yup.boolean().required("Debes seleccionar una opción"),
        microempresas: yup.boolean().required("Debes seleccionar una opción"),
    })
    const validationSchema3 = yup.object({
        sujetoObligado: yup.boolean().required("Debes seleccionar una opción"),
        politicamenteExpuesto: yup.boolean().required("Debes seleccionar una opción"),
        inversorNoResidenteFiscal: yup.boolean().required("Debes seleccionar una opción"),
        paisResidencia:  yup.number().when("inversorNoResidenteFiscal", {
            is: true,
            then: yup.number().required("Debes ingresar un pais")
          }),
          tinNif:yup.string().nullable(true).when("inversorNoResidenteFiscal", {
            is: true,
            then: yup.string().required("Debes ingresar un TIN/NIF")
          }),
          numeroTinNif: yup.string().nullable(true).when("inversorNoResidenteFiscal", {
            is: true,
            then: yup.string().required("Debes ingresar un número")
          }),
    
    })


    const formik = useFormik({
        initialValues:{ 
            regimenIngresosBrutos: recoilAfipData.IBCondicion === "L" ? "Local" : "Multilateral",
            actividadPrincipal: recoilAfipData.actividadAFIPCod === null ? "Sin actividad AFIP principal" : `${recoilAfipData.actividadAFIPCod} - ${recoilAfipData.actividadAFIPNombre}`,
            provinciaIngresosBrutos: recoilAfipData.IBCondicion === "M" ? null : 1,
            numeroIngresosBrutos: recoilAfipData.IBCondicion === "M" ? recoilAfipData.cuit : ""
        },
        onSubmit: (values)=>{ 
             setComercio({
                 ...comercio,
                 merchantIBProvinciaId: values.provinciaIngresosBrutos,
                 merchantIBNumero: values.numeroIngresosBrutos,
                 merchantIBCode: recoilAfipData.IBCondicion

             })
             getUsuarioAdicional()
            setStepInfoFiscal(2)
        },
        validationSchema:validationSchema,

    });
    const formik2 = useFormik({
        initialValues:{
            iva: "",
            ganancias: "",
            ingresosBrutos: "",
            alicuota: null,
            combustible: "",
            microempresas: ""
        },
        onSubmit: (values)=>{ 
            setComercio({
                ...comercio,
                merchantRetieneIVA: values.iva === "true" ? "Y" : "N",
                merchantRetieneIG: values.ganancias === "true" ? "Y" : "N",
                merchantRetieneIB: values.ingresosBrutos === "true" ? "Y" : "N",
                merchantRetieneIBAlicuota: values.alicuota,
                merchantCombustible: values.combustible === "true" ? "Y" : "N",
                merchantMicroempresa: values.microempresas === "true" ? "Y" : "N"
            })
            setStepInfoFiscal(3)
        },
        validationSchema:validationSchema2,
    });


    const formik3 = useFormik({
        initialValues: { 
            sujetoObligado: recoilAfipData.personaFJ === "F" ? usuarioAdicional?.usuarioSujetoObligado === "Y" ? true : usuarioAdicional?.usuarioSujetoObligado === "N" ? false : "" : "",
            politicamenteExpuesto: recoilAfipData.personaFJ === "F" ? usuarioAdicional?.usuarioPoliticamenteExpuesto === "Y" ? true : usuarioAdicional?.usuarioPoliticamenteExpuesto === "N" ? false : "" : "",
            inversorNoResidenteFiscal: "",
            paisResidencia: "",
            tinNif: null,
            numeroTinNif: ""
        },
        onSubmit: (values)=> {
            setComercio({
                ...comercio,
                merchantPEP: values.politicamenteExpuesto === "true" ? "Y": "N",
                merchantSO: values.sujetoObligado === "true" ? "Y" : "N",
                merchantNoResidente: values.inversorNoResidenteFiscal === "true" ? "Y" : "N",
                paisId: values.paisResidencia,
                merchantNoResidenteTC: values.tinNif,
                merchantNoResidenteCuenta: values.numeroTinNif
            })
            nextStep(3)
        },
        validationSchema:validationSchema3
    })


    switch(stepInfoFiscal){ 
        case 1: { 
            return (
                <>
                <FormInformacionFiscal formik={formik}/>
                <Box> 
                    <ButtonGradient size={"medium"} text={"Continuar"} action={formik.handleSubmit} disabled={!formik2.isValid}/>
                </Box>
                <Box marginTop={"15px"} marginBottom={"15px"}> 
                    <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>nextStep(1)}/>
              </Box>
            </>
            )
        }
        case 2: { 
                return (
                    <> 
         
                    <FormInformacionFiscal2 formik={formik2}/>
                        <Box> 
                            <ButtonGradient size={"medium"} text={"Continuar"} action={formik2.handleSubmit} disabled={!formik2.isValid}/>
                        </Box>
                      <Box marginTop={"15px"} marginBottom={"15px"}> 
                            <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>setStepInfoFiscal(1)}/>
                      </Box>
                    </>
                )
        }
        case 3: {
            return (
                <> 
                    <FormInformacionFiscal3 formik={formik3} paises={paises} usuarioAdicional={usuarioAdicional} />
                    <Box> 
                        <ButtonGradient size={"medium"} text={"Continuar"} action={formik3.handleSubmit} disabled={!formik3.isValid}/>
                    </Box>
                    <Box marginTop={"15px"} marginBottom={"15px"}> 
                        <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>setStepInfoFiscal(2)}/>
                    </Box>
                </>
            )
        }
        default:{ 

        }
    }


}

export {
    ContainerInformacionFiscal
}
