import axiosPost from '../axiosPost';
import { UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

type Args = UserCredentialsType & {
    lat: string
    lng: string
};

export const LoginQRGet = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "LoginQRGet",
        "params": {
            "sesionLatitud": arg.lat,
            "sesionLongitud": arg.lng,
        }
    }

    try {
        const result = await axiosPost('N', body, arg);
          
        return result.result[0]
    } catch (err) {
        console.log(err)
    }
}