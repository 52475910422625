import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(()=> (
    {
        root: { 
            '& .MuiButtonBase-root': {
                borderRadius: '50px',
                color: '#fff',
            },
            '& .MuiButtonBase-root:disabled':{
                background: '#D6D6D6',
                color: '#fff'
            }
        },
        btn: { 
            background: 'linear-gradient(90deg, #E56DA1 0%, #FCC020 100%)',
            width: '374px',
            display: 'flex',
            justifyContent: 'center',
            
            padding: '12px',
            fontSize: '22px',
            fontWeight: 700,
            lineHeight: '26px',
            
        },
        medium: {
            width: '250px'
        },
        big:{
            width: '100%'
        },
        transparent: {
            background: '#00000000',
            color: '#8A8A8A !important',
        }
    }
))