import { useState, useEffect, useCallback } from "react";
import moment from "moment";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
import Filtros from "../../components/filtros/Filtros";
// consts
import { saldos as titles } from "../../consts/titulos-de-tablas";
import { Routes } from "../../consts/Routes-App";
//Assets
import CircularIndeterminate from "../../components/utils/spinner/Spinner";
//Services
import { MWSaldoGet } from "../../services/hooli-services";
// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  rowSelected,
  userCredentials,
  routeInfo,
} from "../../recoilState/GlobalState";

const today = new Date().toISOString().substring(0, 10);

export default function Saldos() {
  const [saldos, setSaldos] = useState([]);
  const [filtros, setFiltros] = useState({
    moneda: "ARS",
    desde: moment(today).subtract(6, "days").format("YYYY-MM-DD"),
    hasta: today,
  });
  const [cargando, setCargando] = useState(false);

  const setRow = useSetRecoilState(rowSelected);
  const setRoute = useSetRecoilState(routeInfo);
  const credentials = useRecoilValue(userCredentials);

  const retrieveSaldos = useCallback(async () => {
    setCargando(true);
    const payload = {
      ...credentials,
      merchantId: credentials.merchantId,
      fechaDesde: filtros.desde,
      fechaHasta: filtros.hasta,
      monedaCod: filtros.moneda,
    };
    const result = await MWSaldoGet(payload);
    const saldos = result.reduce((acc, item) => {
      const {
        saldoFecha,
        saldoIngresos,
        saldoCantidadIngresos,
        saldoEgresos,
        saldoCantidadEgresos,
        saldoInicial,
        saldoFinal,
      } = item;

      const saldo = {
        id: saldoFecha,
        [titles[0]]: saldoFecha,
        [titles[1]]: saldoInicial,
        [titles[2]]: saldoIngresos,
        [titles[3]]: "(" + saldoCantidadIngresos + ")",
        [titles[4]]: saldoEgresos,
        [titles[5]]: "(" + saldoCantidadEgresos + ")",
        [titles[6]]: saldoFinal,
      };
      return [...acc, saldo];
    }, []);
    setSaldos(saldos);
    setCargando(false);
  },[credentials, filtros.desde, filtros.hasta, filtros.moneda]);

  useEffect(() => {
    if (Object.keys(credentials).length > 0) retrieveSaldos();
  }, [credentials, filtros, retrieveSaldos]);

  useEffect(() => {
    setRoute(Routes[window.location.pathname.split("/")[1]]);
    setRow({ status: false, row: {} });
  }, []);

  return (
    <main>
      <TransitionModal
        titles={titles}
        updateList={retrieveSaldos}
        moneda={filtros.moneda}
      />
      <Filtros
        filtros={filtros}
        setFiltros={setFiltros}
        updateList={retrieveSaldos}
        cargando={cargando}
        isBalance
      />
      <div className="row-grid" id="grid-container">
        {cargando ? (
          <CircularIndeterminate />
        ) : (
          <XGridDemo rows={saldos} titles={titles} />
        )}
      </div>
    </main>
  );
}
