import React from 'react';
import classes from './Input.module.scss';

type Props = {
    placeholder: string
    name: string
    label: string
    value: string
    setValue: React.Dispatch<React.SetStateAction<string>>
}

export default function Input({ placeholder, name, label, value, setValue }: Props) {
    return (
        <div className={classes.container}>
            <label htmlFor={name}>{label}</label>
            <input
                name={name}
                placeholder={placeholder}
                type="text"
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </div>
    )
};