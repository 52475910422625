export const saldos = [
  "Fecha",
  "Saldo inicial",
  "Ingresos",
  "ing",
  "Egresos",
  "egr",
  "Saldo final",
];
export const movimientos = [
  "Hora",
  "Tipo de movimiento",
  "Ingresos",
  "Egresos",
  "Saldo parcial",
  "Origen / Destino",
  "Referencia",
  "Concepto",
  "Transacción",
];
export const ventas = [
  "Fecha",
  "Autorización",
  "Estado",
  "Cliente",
  "DNI",
  "Método de pago",
  "Cuotas",
  "Concepto",
  "Referencia",
  "Lote",
  "Cupón",
  "Orden",
  "Monto",
];
export const aCobrar = [
  "Fecha",
  "Autorización",
  "Presentación",
  "Acreditación",
  "DNI",
  "Método de pago",
  "Cuotas",
  "Monto Bruto",
  "Deducciones",
  "Impuestos",
  "Monto Neto",
];
export const resumen = [
  "Fecha",
  "Tipo de movimiento",
  "Débito",
  "Crédito",
  "Origen / Destino",
  "Saldo parcial",
];
export const cobros = [
  "Fecha",
  "Cliente",
  "DNI",
  "Concepto",
  "N° de comprobante",
  "Medio de pago",
  "Estado",
  "Monto",
];
export const nominas = [
  "Nombre de nómina",
  "Cantidad de empleados",
  "Fecha de último pago",
];
export const empleados = ["Nombre", "CUIT", "CBU/CVU", "Tipo", "Nomina"];

export const liquidaciones = [
  "Nomina",
  "Estado",
  "Período",
  "Fecha de carga",
  "Fecha de acreditación",
  "Notas",
  "Monto ARS",
];

export const transferencias = ["CUIT/CUIL", "Nombre", "Monto ARS", "Resultado"];

export const transferenciasUpd = [
  "Nombre",
  "CUIT/CUIL",
  "Monto ARS",
  "Referencia",
];
export const transferenciasUpdate = ["CUIT/CUIL", "Nombre", "Monto ARS"];
