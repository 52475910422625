import axiosPost from '../axiosPost';
import { UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

type Args = UserCredentialsType & {
    dni: string
};

export const usuarioLinkIns = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "WAUsuarioLinkIns",
        "params": {
            "usuarioLinkDNI": arg.dni,
            "usuarioNombres": "",
            "usuarioMail": "",
            "usuarioTelefono": ""
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result
    } catch (err) {
        console.log(err)
    }
}