import { useEffect, useState, useCallback } from "react";
import { FiltrosStyles } from "./Filtros.styles";
import { formatNumber } from "../../services/Local-services";
import moment from "moment";
import { Modal, Typography, Box } from "@mui/material";
// Skeleton
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Form
import { useFormik } from "formik";
import * as yup from "yup";
import * as XLSX from "xlsx";
// Components
import Actions from "./components/Actions";
import UserHooliForm from "./components/UserHooliForm";
import SubmitButton from "../../components/utils/button/SubmitButton";
import MerchantHooliForm from "./components/MerchantHooliForm";
import ExternalEntityForm from "./components/ExternalEntityForm";
import CreateTransferForm from "./components/CreateTransferForm";
import UpdateTransferForm from "./components/UpdateTransferForm";
import SeeTransferForm from "./components/SeeTransferForm";
import {
  FormikDateInput,
  FormikSelectInput,
  FormikTextInput,
} from "../../components/utils/inputs/FormikInputs";
// recoil
import { useRecoilValue, useRecoilState } from "recoil";
import { rowSelected, userCredentials } from "../../recoilState/GlobalState";
import {
  MWSaldoActualGet,
  MerchantNominaIns,
  MerchantNominaUpd,
  MerchantNominaEmpleadoUpd,
} from "../../services/hooli-services";

const validationSchema = yup.object({
  hasta: yup
    .date()
    .max(new Date().toISOString().substring(0, 10), "Fecha maxima hoy"),
  desde: yup
    .date()
    .max(yup.ref("hasta"), "No puede ser superior a la segunda fecha"),
});

export default function Filtros(props) {
  const [saldoActual, setSaldoActual] = useState({});
  const [saldoCargando, setSaldoCargando] = useState(true);
  const [modal, setModal] = useState({ isOpen: false, modalType: "" });
  const [employeeType, setEmployeeType] = useState("");

  const [selected, setSelected] = useRecoilState(rowSelected);
  const { row, status } = selected;
  const credentials = useRecoilValue(userCredentials);
  const {
    filtros,
    setFiltros,
    cargando,
    updateList,
    isBalance,
    isSales,
    isPayrolls,
    titles,
    rows,
    retrievePayrolls,
    isEmployees,
    payrollsOptions,
    retrieveEmployees,
    isTransfers,
    retrieveTransfers,
  } = props;

  const formik = useFormik({
    initialValues: filtros,
    validationSchema,
    onSubmit: (values) => setFiltros(values),
  });

  const handleCloseModal = (resetForm) => {
    setModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));
    setEmployeeType("");
    resetForm();
  };

  const retrieveSaldoActual = useCallback(async () => {
    setSaldoCargando(true);
    const payload = {
      ...credentials,
      merchantId: credentials.merchantId,
      monedaCod: formik.values.moneda,
    };
    const result = await MWSaldoActualGet(payload);
    setSaldoActual(result);
    setSaldoCargando(false);
  }, [credentials, formik.values.moneda]);

  useEffect(() => {
    if (isBalance) retrieveSaldoActual();
  }, [formik.values.moneda, cargando, isBalance, retrieveSaldoActual]);

  const classes = FiltrosStyles();
  const data = {
    moneda: { label: "Moneda" },
    desde: { label: "Desde" },
    hasta: { label: "Hasta" },
    nomina: { label: "Nombre de nómina" },
    nominaId: { label: "Nómina" },
    datoEmpleado: { label: "Nombre, CUIT o CBU/CVU" },
  };

  const payrollsFormData = {
    nombreNomina: { label: "Nombre de nómina" },
  };

  const employeesFormData = {
    employeeType: { label: "Tipo de empleado" },
  };

  const employeeTypeOptions = [
    { optionValue: "EE", optionName: "Entidad Externa" },
    { optionValue: "UH", optionName: "Usuario Hooli" },
    { optionValue: "MH", optionName: "Merchant Hooli" },
  ];

  const payrollsConfig = {
    create: {
      label: "Crear una nueva nómina",
      service: MerchantNominaIns,
    },
    update: {
      label: "Actualizar nombre de nómina",
      service: MerchantNominaUpd,
    },
  };

  const handleSeeModal = () => {
    if (isPayrolls) handleSetNomina();
    setModal((prev) => ({
      modalType: "see",
      isOpen: !prev.isOpen,
    }));
  };

  const handleUpdateModal = () => {
    if (isPayrolls) handleSetNomina();
    setModal((prev) => ({
      modalType: "update",
      isOpen: !prev.isOpen,
    }));
  };

  const handleCreateModal = () =>
    setModal((prev) => ({
      modalType: "create",
      isOpen: !prev.isOpen,
    }));

  const payrollsFormik = useFormik({
    initialValues: { nombreNomina: "" },
    onSubmit: async (values, { resetForm }) => {
      await payrollsConfig[modal.modalType].service({
        merchantId: credentials.merchantId,
        merchantNominaNombre: values.nombreNomina,
        merchantNominaId: row.merchantNominaId,
        merchantNominaVersion: row.merchantNominaVersion,
      });
      await retrievePayrolls();
      handleCloseModal(resetForm);
    },
  });

  const employeesFormik = useFormik({
    initialValues: { employeeType: "EE" },
    onSubmit: (values) => {
      setEmployeeType(values.employeeType);
    },
  });
  const handleExport = () => {
    const rowsFilter = rows.map((e) => ({
      [titles[0]]: e[titles[0]],
      [titles[1]]: e[titles[1]],
      [titles[2]]: e[titles[2]],
      [titles[3]]: e[titles[3]],
      [titles[4]]: e[titles[4]],
      [titles[5]]: e[titles[5]],
      [titles[6]]: e[titles[6]],
      [titles[7]]: e[titles[7]],
      [titles[8]]: e[titles[8]],
      [titles[9]]: e[titles[9]],
      [titles[10]]: e[titles[10]],
      [titles[11]]: e[titles[11]],
      [titles[12]]: e[titles[12]],
    }));
    const worksheet = XLSX.utils.json_to_sheet(rowsFilter);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `Tabla-movimientos.xlsx`, { compression: true });
  };
  const monedas = [
    { monedaCod: "ARS", monedaNombre: "Peso Argentino" },
    { monedaCod: "HC", monedaNombre: "Hooli Coins" },
  ];

  const style = {
    alignItems: "center",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: "50%",
    p: 4,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "600px",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "1rem",
    gap: "1rem",
    padding: "32px 54px",
  };
  const handleSetNomina = () => {
    payrollsFormik.setFieldValue("nombreNomina", row["Nombre de nómina"]);
  };

  const updateEmployeesFormik = useFormik({
    initialValues: {
      newCuit: "",
      newDenomination: "",
    },
    onSubmit: async (values, { resetForm }) => {
      await MerchantNominaEmpleadoUpd({
        merchantEmpleadoId: row.id,
        merchantEmpleadoDenominacion: values.newDenomination,
        merchantEmpleadoCuenta: row["CBU/CVU"],
      });
      retrieveEmployees();
      handleCloseModal(resetForm);
    },
  });

  const updateEmployeesData = {
    newCuit: { label: "Agrega un nuevo CUIT" },
    newDenomination: { label: "Agregá nueva denominación" },
  };

  // Funciones auxiliares para cada contenido del modal
  const renderContent = () => {
    if (isPayrolls) return renderPayrollsForm();
    if (isEmployees) return renderEmployeesForm();
    if (isTransfers) return renderTransfersForm();
    return null;
  };

  const renderPayrollsForm = () => {
    return (
      <form onSubmit={payrollsFormik.handleSubmit}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            {payrollsConfig[modal.modalType]?.label}
          </Typography>
          <FormikTextInput
            name="nombreNomina"
            data={payrollsFormData}
            formik={payrollsFormik}
            disabled={cargando}
            className={classes.inputLarge}
          />
          {renderModalFooter(payrollsFormik.resetForm)}
        </Box>
      </form>
    );
  };

  const renderEmployeesForm = () => {
    if (modal.modalType === "create") {
      return !employeeType ? (
        <form onSubmit={employeesFormik.handleSubmit}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              Agregar nuevo empleado
            </Typography>
            <FormikSelectInput
              data={employeesFormData}
              name="employeeType"
              formik={employeesFormik}
              disabled={cargando}
              options={employeeTypeOptions}
              optionKey="optionValue"
              optionName="optionName"
              optionValue="optionValue"
              className={classes.inputLarge}
              fullWidth
            />
            {renderModalFooter(employeesFormik.resetForm)}
          </Box>
        </form>
      ) : (
        renderEmployeeSpecificForm()
      );
    }
    return renderEmployeeUpdateForm();
  };

  const renderEmployeeSpecificForm = () => {
    switch (employeeType) {
      case "EE":
        return <ExternalEntityForm {...commonProps()} />;
      case "MH":
        return <MerchantHooliForm {...commonProps()} />;
      case "UH":
        return <UserHooliForm {...commonProps()} cargando={cargando} />;
      default:
        return null;
    }
  };

  const renderEmployeeUpdateForm = () => {
    return (
      <form onSubmit={updateEmployeesFormik.handleSubmit}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Modificar empleado
          </Typography>
          <FormikTextInput
            name="newDenomination"
            data={updateEmployeesData}
            formik={updateEmployeesFormik}
            disabled={cargando}
            className={classes.inputLarge}
          />
          {renderModalFooter(updateEmployeesFormik.resetForm)}
        </Box>
      </form>
    );
  };

  const renderTransfersForm = () => {
    if (modal.modalType === "create") {
      return (
        <CreateTransferForm
          {...commonProps()}
          retrieveTransfers={retrieveTransfers}
          selected={selected}
          setSelected={setSelected}
        />
      );
    }
    if (modal.modalType === "update") {
      return <UpdateTransferForm {...commonProps()} row={row} />;
    }
    return <SeeTransferForm {...commonProps()} row={row} />;
  };

  // Footer genérico para los formularios
  const renderModalFooter = (resetForm) => {
    return (
      <div style={{ display: "flex", gap: "4rem", alignItems: "center" }}>
        <div
          onClick={() => handleCloseModal(resetForm)}
          style={{ cursor: "pointer" }}
        >
          Cerrar
        </div>
        <SubmitButton text="Aceptar" />
      </div>
    );
  };

  // Props comunes para los formularios
  const commonProps = () => {
    return {
      payrollsOptions,
      employeeType,
      credentials,
      reset: employeesFormik.resetForm,
      handleCloseModal,
      retrieveEmployees,
      classes,
      style,
    };
  };

  return (
    <>
      <Modal
        open={modal.isOpen}
        onClose={() => {
          setSelected({ status: false, row: {} });
          handleCloseModal(payrollsFormik.resetForm);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {renderContent()}
      </Modal>
      <form className={classes.root} onSubmit={formik.handleSubmit}>
        {isBalance && (
          <div className="saldo-actual">
            <p>
              <b>Saldo actual:&nbsp;</b>{" "}
              <span>
                {saldoCargando || cargando ? (
                  <Skeleton count={1} enableAnimation={true} direction="ltr" />
                ) : (
                  "$" + formatNumber(saldoActual?.saldoTotal)
                )}
              </span>
            </p>
            <p>
              <b>Último movimiento:&nbsp;</b>{" "}
              <span>
                {saldoCargando || cargando ? (
                  <Skeleton count={1} enableAnimation={true} direction="ltr" />
                ) : (
                  moment(saldoActual?.saldoUltimaFecha).format("DD/MM/YYYY")
                )}
              </span>
            </p>
          </div>
        )}
        <div className={classes.filtros}>
          {isBalance && (
            <FormikSelectInput
              data={data}
              name="moneda"
              formik={formik}
              disabled={cargando}
              options={monedas}
              optionKey="monedaCod"
              optionName="monedaNombre"
              optionValue="monedaCod"
              className={classes.input}
            />
          )}
          {(isEmployees || isTransfers) && (
            <>
              <FormikSelectInput
                data={data}
                name="nominaId"
                formik={formik}
                disabled={cargando}
                options={payrollsOptions}
                optionKey="merchantNominaId"
                optionName="merchantNominaNombre"
                optionValue="merchantNominaId"
                className={classes.input}
              />
              {isEmployees && (
                <FormikTextInput
                  name={"datoEmpleado"}
                  data={data}
                  formik={formik}
                  disabled={cargando}
                  className={classes.input}
                  type="filtro"
                />
              )}
            </>
          )}
          {(isBalance || isSales) && (
            <>
              <FormikDateInput
                data={data}
                name="desde"
                formik={formik}
                disabled={cargando}
                className={classes.input}
              />
              <FormikDateInput
                data={data}
                name="hasta"
                formik={formik}
                disabled={cargando}
                className={classes.input}
              />
            </>
          )}
          {isPayrolls && (
            <FormikTextInput
              name={"nomina"}
              data={data}
              formik={formik}
              disabled={cargando}
              className={classes.input}
              type="filtro"
            />
          )}
          <div className={classes.marginNormal}>
            <SubmitButton text="Buscar" />
          </div>
        </div>
        <Actions
          isBalance={isBalance}
          isEmployees={isEmployees}
          isSales={isSales}
          isPayrolls={isPayrolls}
          status={status}
          updateList={updateList}
          isTransfers={isTransfers}
          handleExport={handleExport}
          handleCreateModal={handleCreateModal}
          row={row}
          handleUpdateModal={handleUpdateModal}
          handleSeeModal={handleSeeModal}
        />
      </form>
    </>
  );
}
