import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Args = UserCredentialsType & {
  merchantNominaId: number;
  merchantNominaNombre: string;
  merchantNominaVersion: number;
};

export const MerchantNominaUpd = async (args: Args) => {
  console.log(args);
  const body: AxiosPostBody = {
    service: "MerchantNominaUpd",
    params: {
      merchantNominaId: args.merchantNominaId,
      merchantNominaNombre: args.merchantNominaNombre,
      merchantNominaVersion: args.merchantNominaVersion,
    },
  };

  try {
    const response = await axiosPost("Y", body, args);
    console.log(response);
  } catch (err) {
    console.error(err);
  }
};
