import React from 'react'
import styles from "./Loading.module.scss"
import loading from "../../../assets/icons/Ellipse 415.svg"

export default function Loading() {
  return (
    <div className={styles.container}>
        <img src={loading} alt="Loading" className={styles.loadingIcon} />
        <p className={styles.txt}>Esperá unos segundos...</p>
    </div>
  )
}
