import React, { useCallback, useEffect, useState } from 'react'
import classes from './Paso2.module.scss';
// Components
import { PagosInput, PagosModal } from '../../../components/pagos';
import { CircularIndeterminate } from '../../../components';
// Recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { routeInfo, snackbarData, userCredentials } from '../../../recoilState/GlobalState';
// Services
import { merchantCajaGet, usuarioLinkGet, usuarioLinkIns, GWCardTransaccionIns, usuarioBasicosGet, merchantPosIns, usuarioLinkUpd } from '../../../services/hooli-services';
// Types
import { CajaType, UserCredentialsType, UsuarioLinkType, UsuarioType } from '../../../types/types';
import { AxiosResponse } from '../../../types/AxiosPost';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function Paso2() {
    const credentials = useRecoilValue(userCredentials) as UserCredentialsType;
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [open, setOpen] = useState<boolean>(false);
    const [cargando, setCargando] = useState<boolean>(false);
    const [usuario, setUsuario] = useState<UsuarioLinkType | UsuarioType>();
    const [nombre, setNombre] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [telefono, setTelefono] = useState<string>('');

    const setRoute = useSetRecoilState(routeInfo);

    const url = new URLSearchParams(window.location.search);
    const dni = url.get('dni') as string;
    const importe = url.get('importe') as string;
    const factura = url.get('factura') as string;
    const concepto = url.get('concepto') as string;
    const tipo = url.get('tipo') as 'L' | 'S';

    const usuarioLinkDePagosGet = useCallback(async () => {
        setCargando(true);
        const result = await usuarioLinkGet({ ...credentials, dni });
        if (result.length === 0) {
            await usuarioLinkIns({ ...credentials, dni });
            const usuario = await usuarioLinkGet({ ...credentials, dni });
            setUsuario(usuario[0]);
            setNombre(usuario[0].usuarioLinkNombre || '');
            setEmail(usuario[0].usuarioLinkEmail || '');
            setTelefono(usuario[0].usuarioLinkCelular || '');
            setCargando(false);
            return;
        };
        setUsuario(result[0]);
        setNombre(result[0].usuarioLinkNombre || '');
        setEmail(result[0].usuarioLinkEmail || '');
        setTelefono(result[0].usuarioLinkCelular || '');
        setCargando(false);
    }, [credentials]);

    const usuarioSolicitudGet = useCallback(async () => {
        setCargando(true);
        const result = await usuarioBasicosGet({ ...credentials, dni });
        setUsuario(result[0]);
        setNombre(result[0].usuarioNombres + " " + result[0].usuarioApellidos || '');
        setEmail(result[0].usuarioMail || '');
        setTelefono(result[0].usuarioTelefonoCelular || '');
        setCargando(false);
    }, [credentials]);

    useEffect(() => {
        if (!dni || !importe || !tipo || Object.keys(credentials).length === 0) return;
        if (tipo === 'L') usuarioLinkDePagosGet();
        if (tipo === 'S') usuarioSolicitudGet();
    }, [credentials]);

    const handleError = (response: AxiosResponse<CajaType>) => {
        setSnackbarInfo({
            message: response.status.errmsg,
            severity: response.status.action === 'E' ? 'error' : 'warning',
            open: true
        });
        setTimeout(() => {
            setSnackbarInfo({
                message: '',
                severity: 'success',
                open: false
            });
        }, 3000)
    };

    const handleClick = (tipoDeEnvio?: 'M' | 'W') => async () => {
        if (!usuario) return;
        if (!tipoDeEnvio && tipo !== 'S') return;
        const emailValido = email && emailRegex.test(email);
        const telefonoValido = telefono && telefono.length === 10;
        
        if (tipoDeEnvio === 'M' && !emailValido) {
            setSnackbarInfo({
                message: 'Email invalido',
                severity: 'error',
                open: true
            });
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
            }, 3000);
            return;
        };
        if (tipoDeEnvio === 'W' && !telefonoValido) {
            setSnackbarInfo({
                message: 'Telefono invalido',
                severity: 'error',
                open: true
            });
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
            }, 3000);
            return;
        };
        
        setCargando(true);
        const cajas = await merchantCajaGet(credentials);
        const { merchantCajaId } = cajas.result.find(c => c.merchantCajaEsStore === 'Y' && c.merchantSucursalEsCasaCentral === 'Y') as CajaType;

        if (tipo === 'L' && 'usuarioLinkId' in usuario) {
            // Actualizar datos del usuario
            if (email !== usuario.usuarioLinkEmail || telefono !== usuario.usuarioLinkCelular) {
                await usuarioLinkUpd({ ...credentials, id: usuario.usuarioLinkId, email, telefono, nombre: usuario.usuarioLinkNombre })
            };
            const response = await GWCardTransaccionIns({
                ...credentials,
                merchantCajaId,
                tipoDeEnvio: tipoDeEnvio === 'M' ? 'M' : 'W',
                usuarioLinkId: usuario.usuarioLinkId,
                gwcardMonto: importe,
                gwcardFactura: factura,
                gwcardConcepto: concepto
            });
            setCargando(false);
            if (response.status.code === 1) return setOpen(true);
            handleError(response);
        };
        if (tipo === 'S' && 'usuarioId' in usuario) {
            const response = await merchantPosIns({
                ...credentials,
                merchantCajaId,
                usuarioId: usuario.usuarioId,
                merchantPOSMonto: importe,
                gwcardFactura: factura,
                gwcardConcepto: concepto
            });
            setCargando(false);
            if (response.status.code === 1) return setOpen(true);
            handleError(response);
        }
    };

    useEffect(() => {
        setRoute({
            route: "/cobros/paso2",
            wordKey: `Cobros > ${tipo === 'L' ? 'Link de pago' : 'Solicitud de pago'} > Paso 2`
        });
    }, []);

    if (cargando) return (
        <main>
            <div className={classes.container}>
                <CircularIndeterminate />
            </div>
        </main>
    );

    if (tipo === 'L') return (
        <main className={classes.main}>
            <PagosModal
                open={open}
                setOpen={setOpen}
                text='Link de pago enviado exitosamente'
            />
            <h4 className={classes.title}>Enviar link de pago.</h4>
            <PagosInput
                placeholder='Juan Perez'
                label='Nombre'
                name='nombre'
                value={nombre}
                setValue={setNombre}
            />
            <PagosInput
                placeholder='email@gmail.com'
                label='Email'
                name='email'
                value={email}
                setValue={setEmail}
            />
            <PagosInput
                placeholder='1122334455'
                label='Telefono'
                name='telefono'
                value={telefono}
                setValue={setTelefono}
            />
            <div className={classes.buttons}>
                <button type="button" className={classes.submit} onClick={handleClick('M')}>Enviar por mail</button>
                <button type="button" className={classes.submit} onClick={handleClick('W')}>Enviar por whatsapp</button>
                <button type="button" className={classes.volver} onClick={() => window.location.assign(`/cobros/paso1?tipo=${tipo}`)}>Volver</button>
            </div>
        </main>
    );

    return (
        <main className={classes.main}>
            <PagosModal
                open={open}
                setOpen={setOpen}
                text='Solicitud de pago enviada exitosamente'
            />
            <h4 className={classes.title}>Enviar solicitud de pago.</h4>
            <div className={classes.container}>
                {usuario && 'usuarioId' in usuario && <img src={usuario.imagePathPERFIL} alt="Usuario" />}
                <PagosInput
                    placeholder='Juan Perez'
                    label='Nombre'
                    name='nombre'
                    value={nombre}
                    setValue={() => { }}
                />
                <PagosInput
                    placeholder='99.888.777'
                    label='DNI'
                    name='documento'
                    value={dni}
                    setValue={() => { }}
                />
                <PagosInput
                    placeholder='540,60'
                    label='Monto'
                    name='monto'
                    value={importe}
                    setValue={() => { }}
                />
            </div>
            <div className={classes.buttons}>
                <button type="button" className={classes.submit} onClick={handleClick()}>Enviar</button>
                <button type="button" className={classes.volver} onClick={() => window.location.assign(`/cobros/paso1?tipo=${tipo}`)}>Volver</button>
            </div>
        </main>
    )
};