import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { CajaType, UserCredentialsType } from '../../../types/types';

export const merchantCajaGet = async (arg: UserCredentialsType) => {
    const body: AxiosPostBody = {
        "service": "MerchantCajaGet",
        "params": {
            "merchantId": arg.merchantId
        }
    }

    const result = await axiosPost<CajaType>('Y', body, arg);
    return result
}