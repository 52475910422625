import { AxiosPostBody } from '../../types/AxiosPost';
import { UserCredentialsType } from '../../types/types';
import axiosPost from './axiosPost';

export const usuarioMinimosGet = async (arg: UserCredentialsType) => {
    const body: AxiosPostBody = {
        "service": "UsuarioMinimosGet",
        "params": {}
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.result[0]
    } catch (err) {
        console.log(err)
    }
};