import axiosPost from "../axiosPost";
import { MerchantUsuarioType, UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Arg = UserCredentialsType & {
  merchantCUIT: string;
};

export const MerchantGet = async (arg: Arg) => {
  const body: AxiosPostBody = {
    service: "MerchantGet",
    params: {
      merchantCUIT: arg.merchantCUIT,
    },
  };

  try {
    const result = await axiosPost<MerchantUsuarioType>("Y", body, arg);
    return result.result;
  } catch (err) {
    console.log(err);
  }
};
