import React, { useEffect,useState } from 'react';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { useFormik } from 'formik';
import * as yup from "yup";
import LocalizationPage from '../../../components/alta-comercios/forms/localizationPage';
import {MerchantOnboardDo} from "../../../services/hooli-services/Merchants/MerchantOnboardDo" 
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, comercioData, provincias, userCredentials } from '../../../recoilState/GlobalState';
import ModalAltaComercio from '../../../components/alta-comercios/modal';


export function ContainerLocalizacion({nextStep, localidades}) {
  const [coords, setCoords] = useState({ 
    lat: -38.416097,
    lng: -63.616672
  });
  const [coordsComercial, setCoordsComercial] = useState({ 
      lat: -38.416097,
      lng: -63.616672
  });
  const recoilAfipData = useRecoilValue(afipData);
  const recoilProvincia = useRecoilValue(provincias);
  const [comercio,setComercio] = useRecoilState(comercioData)
  const credentials = useRecoilValue(userCredentials);
  const [isOpen, setIsOpen] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [textError, setTextError] = useState("");
  const [domicilio, setDomicilio] = useState("fiscal")
  

  const validationSchema = yup.object({
    calleFiscal: yup.string().required("Debes ingresar tu calle"),
    numeroFiscal: yup.string().required("Debes ingresar tu numero"),
    codFiscal: yup.string().required("Debes ingresar tu codigo postal"),
    provinciaFiscal: yup.string().required("Debes ingresar tu provincia"),
    localidadFiscal: yup.string().nullable(false).required("Debes ingresar tu localidad"),
    calleComercial: yup.string().required("Debes ingresar tu calle"),
    numeroComercial: yup.string().required("Debes ingresar tu numero"),
    codComercial: yup.string().required("Debes ingresar tu codigo postal"),
    provinciaComercial: yup.string().required("Debes ingresar tu provincia"),
    localidadComercial: yup.string().nullable(false).required("Debes ingresar tu localidad"),
})
  

  const getProvincias = ()=> {
  
    const findProvincia = recoilProvincia.find((prov)=> prov.provinciaId === recoilAfipData.domicilioFiscalProvinciaId);
    return findProvincia
}

const getLocalidad = ()=> { 
  const findLocalidad = localidades.find((loc)=> loc.localidadId === Number(recoilAfipData.domicilioFiscalLocalidadId));
  if(findLocalidad) { 
    return [{localidadNombre: findLocalidad.localidadNombre, localidadId: findLocalidad.localidadId, lat: findLocalidad.localidadGeoPINLatitud, lng: findLocalidad.localidadGeoPINLongitud}]
  }else {
    return localidades
  }
}
const handleOnboard = async(data) => {
  try{
    const response = await MerchantOnboardDo(credentials, data)
    if(response.status.errcode === 10000){
      setIsOpen(true)
  }
    if(response.status.code === 1){
      console.log(response)
      nextStep(5);
    } 
    if(response.status.code !== 1){
      console.log(response, data)
      setTitleError("Ups...");
      setTextError("Algo salio mal");
      setIsOpen(true);
    }
  } catch(e) {
    console.error(e);
    setTitleError("Ups...");
    setTextError("Algo salio mal");
    setIsOpen(true);
  }
}
console.log(getLocalidad(), localidades)
  const formik = useFormik({
    initialValues: { 
        calleFiscal: recoilAfipData.domicilioFiscalCalle,
        numeroFiscal: recoilAfipData.domicilioFiscalCasa,
        unidadFiscal: "",
        pisoFiscal: "",
        codFiscal: recoilAfipData.domicilioFiscalCPA,
        provinciaFiscal: getProvincias()?.provinciaNombre,
        localidadFiscal: getLocalidad()[0].localidadId,
        calleComercial: recoilAfipData.domicilioFiscalCalle,
        numeroComercial: recoilAfipData.domicilioFiscalCasa,
        unidadComercial: "",
        pisoComercial: "",
        codComercial: recoilAfipData.domicilioFiscalCPA,
        provinciaComercial: String(getProvincias()?.provinciaId),
        localidadComercial: getLocalidad()[0].localidadId
    },
    validationSchema: validationSchema,
    onSubmit: (values)=> { 
      console.log(values, "somos los values");
      const newComercio = {...comercio, 
        domicilioFiscalCalle: values.calleFiscal,
        domicilioFiscalCasa: values.numeroFiscal,
        domicilioFiscalPiso: values.pisoFiscal,
        domicilioFiscalUnidad: values.unidadFiscal,
        domicilioFiscalCPA: values.codFiscal,
        domicioFiscalLocalidadId: values.localidadFiscal,
        domicilioFiscalLatitud: coords.lat,
        domicilioFiscalLongitud: coords.lng, 
        domicilioComercialCalle: values.calleComercial,
        domicilioComercialCasa: values.numeroComercial, 
        domicilioComercialPiso: values.pisoComercial,
        domicilioComercialUnidad: values.unidadComercial,
        domicilioComercialCPA:values.codComercial,
        domicilioComercialLocalidadId: values.localidadComercial,
        domicilioComercialLatitud: coordsComercial.lat,
        domicilioComercialLongitud: coordsComercial.lng,
      }
      setComercio(newComercio)
      console.log(newComercio, "new comercio");
      handleOnboard(newComercio)
    },
    initialErrors:{
        calleFiscal: false,
        numeroFiscal: false,
        codFiscal: false,
        provinciaFiscal: false,
        localidadFiscal: false,
        calleComercial: false,
        numeroComercial: false,
        codComercial: false,
        provinciaComercial: false,
        localidadComercial: false,
    }
}); 
const handleClickModal = ()=> { 
  setIsOpen(false);
}

const handleContinue = () => {
  if(domicilio === "fiscal") {
    setDomicilio("comercial");
  } else {
    formik.handleSubmit()
  }
}

  useEffect(()=> { 
    formik.setFieldValue("localidadComercial", formik.values.localidadFiscal)
  },[formik.values.localidadFiscal]);

  return (
    <> 
          <LocalizationPage formik={formik} domicilio={domicilio} setDomicilio={setDomicilio} coords={coords} setCoords={setCoords} coordsComercial={coordsComercial} setCoordsComercial={setCoordsComercial} />
          <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={handleContinue} disabled={!formik.isValid}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>nextStep(3)}/>
        </Box>
        <ModalAltaComercio isOpen={isOpen} setIsOpen={setIsOpen} handleClick={handleClickModal} title={titleError} text={textError}/>
    </>
  )
}
