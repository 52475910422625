import { useState } from "react";
import { useFormik } from "formik";
import { Box, Typography } from "@mui/material";

import {
  MerchantNominaEmpleadoIns,
  MerchantGet,
} from "../../../../services/hooli-services";

import {
  FormikTextInput,
  FormikSelectInput,
} from "../../../../components/utils/inputs/FormikInputs";
import SubmitButton from "../../../../components/utils/button/SubmitButton";

import { removeAddedOption } from "../../utils";

const MerchantHooliForm = ({
  payrollsOptions,
  employeeType,
  handleCloseModal,
  retrieveEmployees,
  classes,
  reset,
  style,
}) => {
  const [merchants, setMerchants] = useState([]);

  const filteredOption = removeAddedOption(payrollsOptions);
  const merchantHooliFormik = useFormik({
    initialValues: {
      merchantCUIT: "",
      nominaId: filteredOption[0]?.merchantNominaId,
      merchantId: "",
    },
    onSubmit: async (values, { resetForm }) => {
      if (!!merchants.length) {
        console.log(values);
        await MerchantNominaEmpleadoIns({
          merchantNominaEmpleadoTipo: employeeType,
          merchantId: values.merchantId,
          merchantNominaId: Number(values.nominaId),
        });
        handleCloseModal(resetForm);
        retrieveEmployees();
      } else {
        retrieveMerchants();
      }
    },
  });

  const merchantHooliData = {
    merchantCUIT: { label: "Ingresa el CUIT" },
    merchantId: { label: "Elegí un merchant" },
    nominaId: { label: "Elegí una nómina" },
  };

  const retrieveMerchants = async () => {
    const response = await MerchantGet({
      merchantCUIT: merchantHooliFormik.values.merchantCUIT,
    });
    merchantHooliFormik.setFieldValue("merchantId", response[0]?.merchantId);
    setMerchants(response);
  };
  return (
    <form onSubmit={merchantHooliFormik.handleSubmit}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Agregar empleado de merchant Hooli
        </Typography>
        {!merchants.length && (
          <>
            <FormikTextInput
              name={"merchantCUIT"}
              data={merchantHooliData}
              formik={merchantHooliFormik}
              className={classes.inputLarge}
            />
            <div style={{ display: "flex", gap: "4rem", alignItems: "center" }}>
              <div
                onClick={() => handleCloseModal(reset)}
                style={{ cursor: "pointer" }}
              >
                Cerrar
              </div>
              <SubmitButton text="Aceptar" />
            </div>
          </>
        )}
        {!!merchants.length && (
          <>
            <FormikSelectInput
              data={merchantHooliData}
              name="merchantId"
              formik={merchantHooliFormik}
              options={merchants}
              optionKey="merchantId"
              optionName="merchantNombre"
              optionValue="merchantId"
              className={classes.inputLarge}
              fullWidth={true}
            />
            <FormikSelectInput
              data={merchantHooliData}
              name="nominaId"
              formik={merchantHooliFormik}
              options={filteredOption}
              optionKey="merchantNominaId"
              optionName="merchantNominaNombre"
              optionValue="merchantNominaId"
              className={classes.inputLarge}
              fullWidth={true}
            />
            <div style={{ display: "flex", gap: "4rem", alignItems: "center" }}>
              <div
                onClick={() => handleCloseModal(reset)}
                style={{ cursor: "pointer" }}
              >
                Cerrar
              </div>
              <SubmitButton text="Aceptar" />
            </div>
          </>
        )}
      </Box>
    </form>
  );
};

export default MerchantHooliForm;
