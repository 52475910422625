import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';
import axiosPost from '../axiosPost';

type Args = UserCredentialsType & {
    merchantId: number
};


export const MWDashboardPendientesGet = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "MWDashboardPendientesGet",
        "params": {
            "merchantId": arg.merchantId,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result.result
    } catch (err) {
        console.log(err)
    }
}