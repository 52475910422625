import React, { useState, useEffect } from "react";
import moment from "moment";
// styles
import "../../pages.css";
// utils
import {
    XGridDemo,
    TransitionModal,
    CircularIndeterminate
} from '../../../components';
import Filtros from "./Filtros";
// consts
import { cobros as titles } from "../../../consts/titulos-de-tablas";
import { infoModalCobros as infoModal } from "../../../consts/formInfo";
import { Routes } from "../../../consts/Routes-App";
//Services 
import { merchantCobroGet } from '../../../services/hooli-services';
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formData, routeInfo, rowSelected, userCredentials } from '../../../recoilState/GlobalState';
// Types
import { RowType, UserCredentialsType } from "../../../types/types";

const today = new Date().toISOString().substring(0, 10);

export default function Cobros() {
    const [operaciones, setOperaciones] = useState<RowType[]>([]);
    const [cargando, setCargando] = useState(true);
    const [procede, setProcede] = useState<'cancelar' | 'marcar' | null>(null);
    const [filtros, setFiltros] = useState({ medioDePago: 'todos', estado: 'todos', desde: moment(today).subtract(6, 'days').format('YYYY-MM-DD'), hasta: today });

    const setSelected = useSetRecoilState(rowSelected);
    const setRoute = useSetRecoilState(routeInfo);
    const setFormInfo = useSetRecoilState(formData);
    const credentials = useRecoilValue(userCredentials) as UserCredentialsType;

    async function retrieveOperaciones() {
        setCargando(true);
        const payload = {
            ...credentials, 
            ...filtros,
            medioDePago: filtros.medioDePago === 'todos' ? null : filtros.medioDePago,
            estado: filtros.estado === 'todos' ? null : filtros.estado
        };
        const result = await merchantCobroGet(payload);
        const operaciones = result.reduce((acc: RowType[], operacion) => {
            let medioDePago: string;
            let estado: string;
            switch (operacion.merchantCobroMedioPago) {
                case 'B': medioDePago = 'Transferencia bancaria';
                    break;
                case 'T': medioDePago = 'Tarjeta';
                    break;
                case 'H': medioDePago = 'Dinero en hooli';
                    break;
                case 'P': medioDePago = 'POS';
                    break;
                default: medioDePago = 'Dinero en hooli';
            };
            switch (operacion.merchantCobroEstado) {
                case 'X': estado = 'Rechazado';
                    break;
                case 'C': estado = 'Cobrado';
                    break;
                case 'R': estado = 'Cancelado';
                    break;
                case 'P': estado = 'Pendiente';
                    break;
                default: estado = 'Pendiente';
            };
            const item: RowType = {
                id: operacion.merchantCobroId,
                [titles[0]]: operacion.merchantCobroFH ? operacion.merchantCobroFH.substring(0, 10) : '',
                [titles[1]]: operacion.merchantCobroNombre,
                [titles[2]]: operacion.merchantCobroNroDeDocumento,
                [titles[3]]: operacion.merchantCobroConcepto,
                [titles[4]]: operacion.merchantCobroNumeroTicket,
                [titles[5]]: (operacion.merchantCobroEstado === 'P' || operacion.merchantCobroEstado === 'R') ? '' : medioDePago,
                [titles[6]]: estado,
                [titles[7]]: operacion.merchantCobroMonto,
                version: operacion.merchantCobroVersion,
                origen: operacion.merchantCobroOrigen,
                estado: operacion.merchantCobroEstado,
                medioDePago: operacion.merchantCobroMedioPago
            };
            return [...acc, item]
        }, []);
        setOperaciones(operaciones);
        setCargando(false);
    };

    const handleClick = (action: { action: 'cancelar' | 'marcar' }) => {
        setFormInfo(infoModal[action.action])
        setProcede(action.action);
    }

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveOperaciones();
    }, [credentials, filtros]);

    useEffect(() => {
        setRoute(Routes.pagos.cobros);
        setSelected({ status: false, row: {} });
    }, []);

    return (
        <main style={{ marginTop: '-2em' }}>
            <TransitionModal
                procede={procede}
                updateList={retrieveOperaciones}
            />
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                cargando={cargando}
                handleClick={handleClick}
                updateList={retrieveOperaciones}
            />
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={operaciones} titles={titles} />}
            </div>
        </main>
    )
};