import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import './index.css';
import { theme } from "./theme";
import { RecoilRoot } from 'recoil'
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
   
    <BrowserRouter> 
        <RecoilRoot>
            <ThemeProvider theme={theme}>  
                <App />
            </ThemeProvider>
        </RecoilRoot>
    </BrowserRouter>
 
);