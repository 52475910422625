import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import InsertIcon from "../../../assets/icons/insert-icon.png";
import styles from "./index.module.scss";
import IconFile from "../../../assets/icons/icon-file.png"
export function DropZone({file,setFile}) {
    const [errorFile,setErrorFile] = useState(false);
    

    const onDrop = async (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles[0].path === ".pdf") {
            return setErrorFile('El archivo debe tener un nombre')
        }
        if (rejectedFiles.length > 0) {
            if (rejectedFiles[0].errors[0].code === "file-too-large") {
                return setErrorFile('Solo se permiten archivos menores a 50 MB')
            }
            else {
                return setErrorFile('Solo se permiten archivos PDF.');
            }
        }
        setFile(acceptedFiles);
       
    }
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/pdf": [".pdf"]
        },
        maxSize:50 * 1024 * 1024,
        onDrop
    });


    const renderDropZone = ()=> (
        <Box width={"100%"} marginBottom={4}> 
            <div {...getRootProps({ className: 'dropzone' })} className={styles.dropZone} > 
                 <input {...getInputProps()} />
                 <img src={InsertIcon} alt="icon-upload"/>
                 <p> <span style={{color:"#9373B1", fontWeight:600,cursor:"pointer"}}>Subí la constancia </span>  <span style={{ color:"#8A8A8A", fontWeight:600 }}> o arrastralo aquí </span></p>
                 <p style={{color:"#8A8A8A", fontSize:"14px", marginTop:"8px"} }>Hasta 50 MB en .pdf</p>
            </div>
        </Box>
    )
    const renderFileSection = ()=> (
        <Box className={styles.containerFile}  marginBottom={4} > 
            <Box display={"flex"}  alignItems={"center"}> 
                <img  src={IconFile} alt='icon-file'/>
                <h1>{file[0]?.path}</h1>
            </Box>
            <Box>
                <div className={styles.btnDelete} onClick={()=> setFile(null)}>
                    Eliminar
                </div>
            </Box>
        </Box>
    )
  return (
    <Grid>
        {
            file && !errorFile? 
                renderFileSection()  
            :
            renderDropZone()
        }
       
    </Grid>
  )
}

