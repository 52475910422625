import axiosPost from '../axiosPost';
import { AxiosPostBody } from '../../../types/AxiosPost';
import { CajaType, UserCredentialsType } from '../../../types/types';

type Args = UserCredentialsType & {
    merchantCajaId: number
    usuarioLinkId: number
    tipoDeEnvio: "M" | "W"
    gwcardMonto: string
    gwcardFactura: string
    gwcardConcepto: string
}

export const GWCardTransaccionIns = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "GWCardTransaccionIns",
        "params": {
            "merchantCajaId": arg.merchantCajaId,
            "usuarioLinkId": arg.usuarioLinkId,
            "gwcardTipoEnvio": arg.tipoDeEnvio,
            "gwcardTransaccionPresente": "N",
            "gwcardMonto": arg.gwcardMonto,
            "gwcardTransaccionOrigenDatos": "M",
            "gwcardNumeroFactura": arg.gwcardFactura,
            "gwcardConcepto": arg.gwcardConcepto
        }
    }

    const result = await axiosPost<CajaType>('Y', body, arg);
    console.log('GWCardTransaccionIns', body, result)
    return result
}