import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import Buttons from "../../components/utils/buttonsContainer/Buttons";
import HeaderButton from "../../components/utils/button/HeaderButton";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { aCobrar as titles } from "../../consts/titulos-de-tablas";
import { Routes } from "../../consts/Routes-App";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
import reload from '../../assets/icons/actualizar.svg'
import lupa from '../../assets/icons/lupa.png'
//Services 
import { MWPorCobrarGet } from '../../services/hooli-services';
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { routeInfo, rowSelected, userCredentials } from '../../recoilState/GlobalState';

export default function ACobrar() {
    const [ventas, setVentas] = useState([]);
    const [cargando, setCargando] = useState(false);

    const [row, setRow] = useRecoilState(rowSelected);
    const setRoute = useSetRecoilState(routeInfo);
    const credentials = useRecoilValue(userCredentials);

    async function retrieveVentas() {
        setCargando(true);
        const result = await MWPorCobrarGet(credentials);
        const ventas = result.reduce((acc, item, index) => {
            const {
                merchantPorCobrarFechaAcreditacion,
                merchantPorCobrarFechaOperacion,
                merchantPorCobrarFechaAutorizacion,
                merchantPorCobrarFechaPresentacion,
                merchantPorCobrarMedioPago,
                merchantPorCobrarNroDeDocumento,
                merchantPorCobrarMontoNeto,
                merchantPorCobrarIBRet,
                merchantPorCobrarIGRet,
                merchantPorCobrarDBCR,
                merchantPorCobrarIVA,
                merchantPorCobrarIVARet,
                merchantPorCobrarFee,
                merchantPorCobrarFeeCuotas,
                merchantPorCobrarDeducciones,
                merchantPorCobrarImpuestos,
                merchantPorCobrarMonto,
                merchantPorCobrarConcepto,
                merchantPorCobrarIVACuotas,
                merchantPorCobrarCuotas,
                merchantPorCobrarLabelDBCR,
                merchantPorCobrarLabelFee,
                merchantPorCobrarLabelFeeCuotas,
                merchantPorCobrarLabelIBRet,
                merchantPorCobrarLabelIGRet,
                merchantPorCobrarLabelIVA,
                merchantPorCobrarLabelIVACuotas,
                merchantPorCobrarLabelIVARet,
                merchantPorCobrarlabelFeeTransaccion,
                merchantPorCobrarLabelCanjeHC,
                merchantPorCobrarCanjeHC,
                merchantPorCobrarFeeTransaccion
            } = item

            const venta = {
                id: index,
                merchantPorCobrarLabelCanjeHC,
                canjeHC: merchantPorCobrarCanjeHC,
                merchantPorCobrarLabelDBCR,
                dbcr: merchantPorCobrarDBCR,
                merchantPorCobrarLabelFee,
                fee: merchantPorCobrarFee,
                merchantPorCobrarLabelFeeCuotas,
                feeCuotas: merchantPorCobrarFeeCuotas,
                merchantPorCobrarLabelIBRet,
                iibb: merchantPorCobrarIBRet,
                merchantPorCobrarLabelIGRet,
                ganancias: merchantPorCobrarIGRet,
                merchantPorCobrarLabelIVA,
                IVA: merchantPorCobrarIVA,
                merchantPorCobrarLabelIVACuotas,
                IVACuotas: merchantPorCobrarIVACuotas,
                merchantPorCobrarLabelIVARet,
                IVARet: merchantPorCobrarIVARet,
                merchantPorCobrarlabelFeeTransaccion,
                feeTransaccion: merchantPorCobrarFeeTransaccion,
                montoBruto: merchantPorCobrarMonto,
                montoNeto: merchantPorCobrarMontoNeto,
                [titles[0]]: merchantPorCobrarFechaOperacion,
                [titles[1]]: merchantPorCobrarFechaAutorizacion,
                [titles[2]]: merchantPorCobrarFechaPresentacion,
                [titles[3]]: merchantPorCobrarFechaAcreditacion,
                [titles[4]]: merchantPorCobrarNroDeDocumento,
                [titles[5]]: merchantPorCobrarMedioPago === 'H' ? 'Hooli Money' : merchantPorCobrarMedioPago === 'C' ? 'TC - ' + merchantPorCobrarConcepto : merchantPorCobrarMedioPago === 'D' ? 'TD - ' + merchantPorCobrarConcepto : merchantPorCobrarMedioPago === 'B' ? 'BNLP' : 'Transferencia',
                [titles[6]]: merchantPorCobrarCuotas,
                [titles[7]]: merchantPorCobrarMonto,
                [titles[8]]: merchantPorCobrarDeducciones,
                [titles[9]]: merchantPorCobrarImpuestos,
                [titles[10]]: merchantPorCobrarMontoNeto,
            };
            return [...acc, venta]
        }, []);
        setVentas(ventas);
        setCargando(false);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveVentas();
    }, [credentials]);

    useEffect(() => {
        setRoute(Routes[window.location.pathname.split('/')[1]])
        setRow({ status: false, row: {} });
    }, []);

    return (
        <main>
            <TransitionModal />
            <Buttons>
                {row.status ?
                    <HeaderButton
                        text={"Ver detalle"}
                        icon={lupa}
                        data={{ action: 'detalle' }}
                    />
                    :
                    <HeaderButton
                        text={"Actualizar pagina"}
                        icon={reload}
                        updateList={retrieveVentas}
                    />
                }
            </Buttons>
            <div className="row-grid" id="grid-container">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={ventas} titles={titles} />}
            </div>
        </main>
    )
};