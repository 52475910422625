import { fallbackURL } from "../../consts/FallbackURL";

export const checkAuth = () => {
     if (sessionStorage.getItem('hooliToken') && sessionStorage.getItem('sessionId')) {
         return {
             hooliToken: sessionStorage.getItem('hooliToken'),
             sessionId: sessionStorage.getItem('sessionId'),
             merchantId: sessionStorage.getItem('merchantId'),
             merchantRZ: sessionStorage.getItem('merchantRz'),
             merchantNombre: sessionStorage.getItem('merchantNombre'),
             merchantCUIT: sessionStorage.getItem('merchantCUIT'),
             rolCod: sessionStorage.getItem('rolCod')
         }
     };

     return { }
    
};