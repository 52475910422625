import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  FormikSelectInput,
  FormikTextInput,
} from "../../../utils/inputs/FormikInputs";
import { useStyles } from "./styles";
import { tipoDeSociedadGet } from "../../../../services/hooli-services/Comercios/tipoDeSociedadGet";
import { cargosGet } from "../../../../services/hooli-services/Comercios/cargosGet";
import { rubroMerchantGet } from "../../../../services/hooli-services/Comercios/rubroMerchantGet";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  afipData,
  loading,
  userCredentials,
} from "../../../../recoilState/GlobalState";
function Identificacion({ formik, setIsOpen }) {
  const [credentials, setCredentials] = useRecoilState(userCredentials);
  const [load, setLoad] = useRecoilState(loading);
  const recoilAfipData = useRecoilValue(afipData);
  const [tipoDeSociedad, setTipoDeSociedad] = useState([]);
  const [rubros, setRubros] = useState([]);
  const [cargos, setCargos] = useState([]);
  const styles = useStyles();

  const dataFormik = {
    razonSocial: { label: "Razón Social*", placeholder: "" },
    tipoDePersona: { label: "Persona Física/Júridica", placeholder: "Física" },
    cuit: { label: "CUIT", placeholder: "30-22222222-8" },
    nombre: { label: "Nombre de tu comercio*", placeholder: "Grido" },
    tipoDeSociedad: {
      label: "Tipo de Sociedad",
      placeholder: "Sociedad Anónima",
    },
    rubro: { label: "Rubro de tu comercio", placeholder: "Heladeria" },
    cargo: { label: "Cargo", placeholder: "Representante legal" },
  };

  const selectGet = async () => {
    let sociedad = await tipoDeSociedadGet(credentials);
    let cargo = await cargosGet(credentials);
    let rubro = await rubroMerchantGet(credentials);
    if (sociedad.status.code === 1) {
      const filterCargo = cargo.result.filter(
        (cargo) => cargo.cargoPFJ === recoilAfipData.personaFJ
      );
      setTipoDeSociedad(sociedad.result);
      setCargos(filterCargo);
      setRubros(rubro.result);
      setLoad(false);
      formik.setFieldValue("cargo", filterCargo[0].cargoId);
      formik.setFieldValue(
        "tipoDeSociedad",
        sociedad.result[0].tipoSocietarioId
      );
      formik.setFieldValue("rubro", rubro.result[0].rubroMerchantId);
    }
  };

  useEffect(() => {
    selectGet();
  }, []);

  return (
    <Grid width={"100%"} padding={"20px 0 50px 0"}>
      <Box width={"100%"} className={styles.inputRazonSocial}>
        <FormikTextInput
          data={dataFormik}
          name={"razonSocial"}
          formik={formik}
          disabled={true}
        />
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        gap={4}
        className={styles.containerInput}
      >
        <FormikTextInput
          data={dataFormik}
          name={"tipoDePersona"}
          formik={formik}
          disabled={true}
        />
        <FormikSelectInput
          data={dataFormik}
          name={"tipoDeSociedad"}
          formik={formik}
          options={tipoDeSociedad}
          optionName="tipoSocietarioNombre"
          optionValue="tipoSocietarioId"
          optionKey="tipoSocietarioId"
        />
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        gap={4}
        className={styles.containerInput}
      >
        <FormikTextInput
          data={dataFormik}
          name={"cuit"}
          formik={formik}
          disabled={true}
        />
        <FormikSelectInput
          data={dataFormik}
          name={"rubro"}
          formik={formik}
          options={rubros}
          optionName="rubroMerchantNombre"
          optionValue="rubroMerchantId"
          optionKey="rubroMerchantId"
        />
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        gap={4}
        className={styles.containerInput}
      >
        <FormikTextInput data={dataFormik} name={"nombre"} formik={formik} />
        <FormikSelectInput
          data={dataFormik}
          name={"cargo"}
          formik={formik}
          options={cargos}
          optionName="cargoNombre"
          optionValue="cargoId"
          optionKey="cargoId"
        />
      </Box>
    </Grid>
  );
}

export { Identificacion };
