import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Args = UserCredentialsType & {
  merchantNominaEmpleadoTipo: string;
  merchantEmpleadoCUIT: string;
  merchantEmpleadoDenominacion: string;
  merchantEmpleadoCuenta: string;
  usuarioId: number;
  merchantId: number;
  merchantNominaId: number;
};

export const MerchantNominaEmpleadoIns = async (args: Args) => {
  const body: AxiosPostBody = {
    service: "MerchantNominaEmpleadoIns",
    params: {
      merchantNominaEmpleadoTipo: args.merchantNominaEmpleadoTipo,
      merchantEmpleadoCUIT: args.merchantEmpleadoCUIT,
      merchantEmpleadoDenominacion: args.merchantEmpleadoDenominacion,
      merchantEmpleadoCuenta: args.merchantEmpleadoCuenta,
      usuarioId: args.usuarioId,
      merchantId: args.merchantId,
      merchantNominaId: args.merchantNominaId,
    },
  };

  try {
    const response = await axiosPost("Y", body, args);
    return response.result;
  } catch (e) {
    console.log(e);
  }
};
