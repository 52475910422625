import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
// functions
import { generateColumns } from "../../../services/Local-services";
// recoil
import { useRecoilValue, useRecoilState } from "recoil";
import { routeInfo, rowSelected } from "../../../recoilState/GlobalState";

LicenseInfo.setLicenseKey(
  "eaa04298c880ee5a01c3c1f8ecfc8314Tz04ODc1OSxFPTE3NDUwOTA4NjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export default function XGridDemo(props) {
  const {
    rows,
    titles,
    secondarySelected,
    setSecondarySelected,
    formik,
    data,
    type,
    withoutRowSelection,
  } = props;
  const [selected, setSelected] = useRecoilState(rowSelected);
  const { route } = useRecoilValue(routeInfo);
  const { row } = secondarySelected ? secondarySelected : selected;
  const setter = setSecondarySelected ? setSecondarySelected : setSelected;
  const columns = generateColumns(titles, route, formik, data, type);

  return (
    <>
      <DataGridPro
        columns={columns}
        rows={rows || []}
        onRowClick={
          withoutRowSelection
            ? () => {}
            : (data) => {
                if (data.row.id === row?.id) {
                  setter({ status: false, row: {} });
                  return;
                }
                setter({ status: true, row: data.row });
              }
        }
        selectionModel={row?.id || []}
        autoPageSize
        pagination
        hideFooterSelectedRowCount
        disableMultipleSelection
        disableColumnMenu
      />
    </>
  );
}
