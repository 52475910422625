import React from 'react';
// styles
import classes from './Buttons.module.css';

export default function Buttons({ children }) {
    return (
        <div className={classes.buttonsContainer}>
            <div className={classes.icons}>
                {children}
            </div>
        </div>
    )
};