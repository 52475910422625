import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Args = UserCredentialsType & {
  merchantNominaNombre: string;
};

export const MerchantNominaIns = async (args: Args) => {
  const body: AxiosPostBody = {
    service: "MerchantNominaIns",
    params: {
      merchantId: args.merchantId,
      merchantNominaNombre: args.merchantNominaNombre,
    },
  };
  try {
    const result = await axiosPost("Y", body, args);
    console.log(result);
  } catch (err) {
    console.log(err);
  }
};
