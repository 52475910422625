import { AxiosPostBody } from '../../../types/AxiosPost';
import { UserCredentialsType } from '../../../types/types';
import axiosPost from '../axiosPost';

export const PaisGet = async (arg: UserCredentialsType) => {
    const body: AxiosPostBody = {
        "service": "PaisGet",
        "params": { }
    };

    try {
        const result = await axiosPost('Y', body, arg)
       
        return result
    } catch (err) {
        console.log(err)
    }
};