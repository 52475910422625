import React from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../services/Local-services";
// Assets
import Moneda from "../../../assets/icons/moneda.svg";
import HooliCoins from "../../../assets/icons/hc.svg";
// Skeleton
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Styles
import classes from "./Saldos.module.scss";

export default function Saldos({
  cargando,
  totales: {
    saldoTotalARS = 0,
    saldoTotalHC,
    saldoIngresosARS,
    saldoIngresosHC,
    saldoEgresosARS,
    saldoEgresosHC,
  },
}) {
  return (
    <div className={classes.saldos}>
      <div className={classes.titleContainer}>
        <img src={Moneda} alt="Moneda" />
        <h4 className={classes.title}>Saldos</h4>
      </div>
      <div className={classes.row}>
        <p className={classes.rowTitle}>ARS</p>
        <div className={`${classes.card} ${classes.total}`}>
          <p>Total ARS</p>
          <div className={classes.divider} />
          <h4>
            {cargando ? (
              <Skeleton count={1} enableAnimation={true} direction="ltr" />
            ) : (
              "$" + formatNumber(saldoTotalARS)
            )}
          </h4>
        </div>
        <div className={classes.card}>
          <p>Ingresos</p>
          <div className={classes.divider} />
          <h4>
            {cargando ? (
              <Skeleton count={1} enableAnimation={true} direction="ltr" />
            ) : (
              "$" + formatNumber(saldoIngresosARS)
            )}
          </h4>
        </div>
        <div className={classes.card}>
          <p>Egresos</p>
          <div className={classes.divider} />
          <h4>
            {cargando ? (
              <Skeleton count={1} enableAnimation={true} direction="ltr" />
            ) : (
              "$" + formatNumber(saldoEgresosARS)
            )}
          </h4>
        </div>
      </div>
      <div className={classes.row}>
        <p className={classes.rowTitle}>
          <img src={HooliCoins} alt="HC" />
        </p>
        <div className={`${classes.card} ${classes.total}`}>
          <p>Total Hooli Coins</p>
          <div className={classes.divider} />
          <h4>
            {cargando ? (
              <Skeleton count={1} enableAnimation={true} direction="ltr" />
            ) : (
              "$" + formatNumber(saldoTotalHC)
            )}
          </h4>
        </div>
        <div className={classes.card}>
          <p>Ingresos</p>
          <div className={classes.divider} />
          <h4>
            {cargando ? (
              <Skeleton count={1} enableAnimation={true} direction="ltr" />
            ) : (
              "$" + formatNumber(saldoIngresosHC)
            )}
          </h4>
        </div>
        <div className={classes.card}>
          <p>Egresos</p>
          <div className={classes.divider} />
          <h4>
            {cargando ? (
              <Skeleton count={1} enableAnimation={true} direction="ltr" />
            ) : (
              "$" + formatNumber(saldoEgresosHC)
            )}
          </h4>
        </div>
      </div>
      <button>
        <Link to="/saldos">Ver detalle</Link>
      </button>
    </div>
  );
}
