import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(()=>(
    {
        containerInput: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
            width: "50%", 
            '& .MuiFormControl-root': {
                '& label':{
                    color: "#9373B1",
                    left: "50%",
                    transform: "translate(-50%)",
                    fontSize: "12px"
                   
                },
                '& input::placeholder':{
                    textAlign:"center"
                },
                '& input': {
                    textAlign:"center",
                    width: "300px"
                },
                '& input:focus': {
                    '&, &::after' :{
                        borderBottom:"2px solid #9373B1 !important", 
                        zIndex: "2"
                    },
                }
                
            },

            
        }
    }
))