import { Grid } from '@mui/material'
import React from 'react'
import ComercioNoDisponible from "../../../assets/icons/comercio-nodisponible.png"
import { Layaout } from '../../../components/layaout'
import ButtonGradient from '../../../components/utils/button/ButtonGradient'
import styles from "./index.module.scss";
import { useNavigate } from 'react-router-dom'
function ContainerNoDisponibles() {
    const navigate = useNavigate();
  return (
    <Layaout isNeedStepper={false}> 
    <Grid container display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
            <Grid item width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} mt={"50px"}> 
                <img src={ComercioNoDisponible} alt="no disponible" width={"150px"}/>
            </Grid>
            <Grid item className={styles.containerTitle} mt={"50px"}width={"100%"} display={"flex"} flexDirection={"column"}justifyContent={"center"} alignItems={"center"}>
              
                <h1> No encontramos comercios disponibles.</h1>
                <p> Para crear nuevos comercios ingresa a “Crear nuevo comercio” y segui los pasos correspondientes. </p> 
             
               
            </Grid>
            <Grid item mt={"50px"} mb={"30px"}> 
                <ButtonGradient text={"Crear nuevo comercio"} action={()=> navigate("/ingreso-cuit")}/>
            </Grid>
    </Grid>
    </Layaout>
  )
}

export { 
    ContainerNoDisponibles
}