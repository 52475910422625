import { Button } from "@mui/material";
import { SubmitButtonStyles } from "./SubmitButton.styles";

export default function SubmitButton({ prevent=false, click = () => {}, type = "submit", text, className = "" }) {
  const classes = SubmitButtonStyles();
  return (
    <Button
      type={type ? type : "submit"}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (!prevent) return;
          click();
        }
      }}
      onClick={click}
      className={`${classes.root} ${className}`}
      sx={{ color: "white", borderRadius: "10px", fontWeight: 300 }}
    >
      {text}
    </Button>
  );
}
