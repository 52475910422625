import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userCredentials } from '../../../recoilState/GlobalState';
import { UserCredentialsType } from '../../../types/types';
import classes from './Modal.module.scss';

type Props = {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    text: string
};

export default function Modal({ open, setOpen, text }: Props) {
    const credentials = useRecoilValue(userCredentials) as UserCredentialsType;
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(false);
        credentials.rolCod === ('MD' || 'MA') ? navigate('/dashboard') : navigate('/cobros')
    };

    if (!open) return <></>;

    return (
        <div className={classes.container}>
            <div className={classes.modal}>
                <img src="" alt="" />
                <h4>{text}</h4>
                <button onClick={handleClick}>Finalizar</button>
            </div>
        </div>
    )
};