import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Args = UserCredentialsType & {
  merchantNominaId: number;
};

export const MerchantLiquidacionGet = async (args: Args) => {
  const body: AxiosPostBody = {
    service: "MerchantLiquidacionGet",
    params: {
      merchantNominaId: args.merchantNominaId,
      merchantId: args.merchantId,
    },
  };

  try {
    const result = await axiosPost("Y", body, args);

    return result.result;
  } catch (err) {
    console.log(err);
  }
};
