import React from 'react';
import styles from './ModalDetalle.module.css';
import { Divider, Button } from '@mui/material';
// Recoil
import { formIsOpen, rowSelected } from '../../recoilState/GlobalState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formatNumber } from '../../services/Local-services';

export default function ModalDetalle() {
    const { row } = useRecoilValue(rowSelected);
    const setOpen = useSetRecoilState(formIsOpen);

    return (
        <>
            <h2 className={styles.title}>Detalle</h2>
            <Divider className={styles.divider} />
            <div className={styles.detalle}>
                <div className={styles.nombre}>Monto Bruto</div>
                <div className={styles.info}>${formatNumber(row.montoBruto)}</div>
            </div>
            {row.feeCuotas > 0 &&
                <div className={styles.detalle}>
                    <div className={styles.nombre}>{row.merchantPorCobrarLabelFeeCuotas}</div>
                    <div className={styles.info}>-${formatNumber(row.feeCuotas)}</div>
                </div>
            }
            {row.IVACuotas > 0 &&
                <div className={styles.detalle}>
                    <div className={styles.nombre}>{row.merchantPorCobrarLabelIVACuotas}</div>
                    <div className={styles.info}>{row.IVACuotas > 0 ? `-$${formatNumber(row.IVACuotas)}` : "$" + formatNumber(row.IVACuotas)}</div>
                </div>
            }
            <div className={styles.detalle}>
                <div className={styles.nombre}>{row.merchantPorCobrarLabelFee}</div>
                <div className={styles.info}>{row.fee > 0 ? `-$${formatNumber(row.fee)}` : "$" + formatNumber(row.fee)}</div>
            </div>
            <div className={styles.detalle}>
                <div className={styles.nombre}>{row.merchantPorCobrarlabelFeeTransaccion}</div>
                <div className={styles.info}>{row.feeTransaccion > 0 ? `-$${formatNumber(row.feeTransaccion)}` : "$" + formatNumber(row.feeTransaccion)}</div>
            </div>
            <div className={styles.detalle}>
                <div className={styles.nombre}>{row.merchantPorCobrarLabelIVA}</div>
                <div className={styles.info}>{row.IVA > 0 ? `-$${formatNumber(row.IVA)}` : "$" + formatNumber(row.IVA)}</div>
            </div>
            <div className={styles.detalle}>
                <div className={styles.nombre}>{row.merchantPorCobrarLabelIVARet}</div>
                <div className={styles.info}>{row.IVARet > 0 ? `-$${formatNumber(row.IVARet)}` : "$" + formatNumber(row.IVARet)}</div>
            </div>
            <div className={styles.detalle}>
                <div className={styles.nombre}>{row.merchantPorCobrarLabelIGRet}</div>
                <div className={styles.info}>{row.ganancias > 0 ? `-$${formatNumber(row.ganancias)}` : "$" + formatNumber(row.ganancias)}</div>
            </div>
            <div className={styles.detalle}>
                <div className={styles.nombre}>{row.merchantPorCobrarLabelIBRet}</div>
                <div className={styles.info}>{row.iibb > 0 ? `-$${formatNumber(row.iibb)}` : "$" + formatNumber(row.iibb)}</div>
            </div>
            <div className={styles.detalle}>
                <div className={styles.nombre}>{row.merchantPorCobrarLabelDBCR}</div>
                <div className={styles.info}>{row.dbcr > 0 ? `-$${formatNumber(row.dbcr)}` : "$" + formatNumber(row.dbcr)}</div>
            </div>
            <div className={styles.detalle}>
                <div className={styles.nombre}>{row.merchantPorCobrarLabelCanjeHC}</div>
                <div className={styles.info}>{row.canjeHC > 0 ? `-$${formatNumber(row.canjeHC)}` : "$" + formatNumber(row.canjeHC)}</div>
            </div>
            <div className={styles.detalle}>
                <Divider className={styles.divider} />
            </div>
            <div className={styles.detalle}>
                <div className={styles.nombre}>Monto Neto</div>
                <div className={styles.info}>${formatNumber(row.montoNeto)}</div>
            </div>
            <Button
                style={{
                    color: "#ffffff",
                    width: "16rem",
                    height: "2rem",
                    background: "linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)",
                    borderRadius: 21,
                    opacity: 1,
                    textTransform: "none",
                    fontWeight: 300,
                    fontFamily: '"Roboto", sans-serif',
                    marginTop: '1em',
                }}
                onClick={() => setOpen(false)}
            >Cerrar</Button>
        </>
    )
}
