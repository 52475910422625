import axiosPost from '../axiosPost';
import { UserCredentialsType } from '../../../types/types';
import { AxiosPostBody } from '../../../types/AxiosPost';

type Args = UserCredentialsType & {
    "merchantId": number
    "monedaCod": string
}

export const MWSaldoActualGet = async (arg: Args) => {
    const body: AxiosPostBody = {
        "service": "MWSaldoActualGet",
        "params": {
            "merchantId": arg.merchantId,
            "monedaCod": arg.monedaCod
        }
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result.result[0]
    } catch (err) {
        console.log(err)
    }
}