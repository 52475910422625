import React from "react";
import { useRecoilValue } from "recoil";
import { userCredentials } from "../../recoilState/GlobalState";
import { UserCredentialsType } from "../../types/types";

type Props = {
  children: React.ReactNode;
};

export default function ProtectedRoute({ children }: Props): React.ReactNode {
  const credentials = useRecoilValue(userCredentials) as UserCredentialsType;
  if (credentials.rolCod !== "MD" && credentials.rolCod !== "MA") return <></>;

  return children;
}
