import axiosPost from "../axiosPost";
import { UserCredentialsType } from "../../../types/types";
import { AxiosPostBody } from "../../../types/AxiosPost";

type Args = UserCredentialsType & {
  merchantNominaId: number;
};

export const MerchantNominaEmpleadoGet = async (args: Args) => {
  const body: AxiosPostBody = {
    service: "MerchantNominaEmpleadoGet",
    params: {
      merchantNominaId: args.merchantNominaId,
      merchantId: args.merchantId,
    },
  };

  try {
    const response = await axiosPost("Y", body, args);
    return response.result;
  } catch (e) {
    console.log(e);
  }
};
