import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import classes from './Solicitudes.module.scss';
// Skeleton
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// Assets
import ArrowDown from '../../../assets/icons/arrow-down-red.svg';
import Cantidad from '../../../assets/icons/cantidad.svg';
import { formatNumber } from '../../../services/Local-services';

export default function Solicitudes({ pendientes, cargando }) {
    return (
        <div className={classes.solicitudes}>
            <div className={classes.titleContainer}>
                <img className={classes.icon} src={ArrowDown} alt="Solicitudes" />
                <h4 className={classes.title}>Ventas pendientes de pago</h4>
            </div>
            <div className={classes.row}>
                <div className={classes.dato}>
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : '$' + formatNumber(pendientes.monto)}</h4>
                </div>
                <div className={classes.dato}>
                    <img src={Cantidad} />
                    <h4>{cargando ? <Skeleton count={1} enableAnimation={true} direction='ltr' /> : pendientes.operaciones}</h4>
                </div>
            </div>
            <button><Link to="/pendientes">Ver detalle</Link></button>
        </div>
    )
};