import React from 'react';
import {CircularProgress }from '@mui/material';
import { SpinnerStyles } from './Spinner.styles';

export default function CircularIndeterminate() {
    const styles = SpinnerStyles();
    return (        
        <div className={styles.container}>
            <CircularProgress className={styles.root} />
        </div>
    );
};