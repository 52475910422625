import React from 'react';
import { FormContacto } from '../../../components/alta-comercios/forms/contacto';
import { Layaout } from '../../../components/layaout';
import { Box } from '@mui/material';
import ButtonGradient from '../../../components/utils/button/ButtonGradient';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useRecoilState, useRecoilValue } from 'recoil';
import { afipData, comercioData } from '../../../recoilState/GlobalState';

function ContainerContacto({nextStep}) {

  const validationSchema = yup.object({
    mail: yup.string().required("Debes ingresar tu mail").matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, "Debe ingresar un formato correcto"),
    celular: yup.string().required("Debes ingresar tu celular")
}); 
 const recoilAfipData = useRecoilValue(afipData);
 const [comercio,setDataComercios] = useRecoilState(comercioData);

  const formik = useFormik({
    initialValues: { 
        razonSocial: recoilAfipData.personaFJ === "J" ? recoilAfipData.merchantRZ : `${recoilAfipData.usuarioApellidos}, ${recoilAfipData.usuarioNombres}` ,
        mail:"",
        telefono:"",
        celular:"",
        sitioWeb: "",
        instagram:"",
        facebook:""
    },
    validationSchema: validationSchema,
    onSubmit: (values)=> { 
    
      setDataComercios({...comercio, 
        merchantMail: values.mail,
        merchantCelular: values.celular,
        merchantTelefono: values.telefono,
        merchantInstagram: values.instagram,
        merchantWebSite: values.sitioWeb,
        merchantFacebook: values.facebook 
      })
      nextStep(2)
    },
    initialErrors:{
      mail:false,
      celular: false
    }
}); 
  return (
    <>   
          <FormContacto formik={formik}/>
          <Box> 
            <ButtonGradient size={"medium"} text={"Continuar"} action={formik.handleSubmit} disabled={!formik.isValid}/>
          </Box>
          <Box marginTop={"15px"} marginBottom={"15px"}> 
          <ButtonGradient size={"medium"} text={"Volver"} color="transparent" action={()=>nextStep(0)}/>
        </Box>
    </>
  
  )
}

export { ContainerContacto }