import React from 'react';
import moment from 'moment';
import { Page, Document, StyleSheet, Text, View, Image, Font } from '@react-pdf/renderer';
import { formatNumber } from '../../services/Local-services';
import LogoHooli from '../../assets/icons/LogoBlanco.png';
import IconoResumen from '../../assets/icons/IconoResumen.png';

export default function PDF({ rows, titles, filtros, credentials }) {
    return (
        <Document title="Document">
            <Page size="A4" orientation='portrait' style={styles.root} wrap>
                <View style={styles.header} fixed>
                    <Image style={styles.icono} src={IconoResumen} />
                    <View>
                        <Text style={styles.title}><Text style={styles.bold}>{credentials.merchantRZ}</Text> ({credentials.merchantCUIT})</Text>
                        <Text style={styles.cuit}>Resumen de cuenta desde
                            <Text style={styles.bold}> {moment(filtros.desde).format('DD/MM/YYYY')}</Text> hasta <Text style={styles.bold}>{moment(filtros.hasta).format('DD/MM/YYYY')}</Text> | Moneda: <Text style={styles.bold}>{filtros.moneda}</Text>
                        </Text>
                    </View>
                    <Image style={styles.image} src={LogoHooli} />
                </View>
                <View style={styles.thead} fixed>
                    {titles.map((t, index) => {
                        if (index === 0) return <Text key={index} style={styles.fecha}>{t}</Text>
                        if (index === 1) return <Text key={index} style={styles.tipo}>{t}</Text>
                        if (index === 2) return <Text key={index} style={styles.monto}>{t}</Text>
                        if (index === 3) return <Text key={index} style={styles.monto}>{t}</Text>
                        if (index === 5) return <Text key={index} style={styles.monto}>{t}</Text>
                        return <Text key={index} style={styles.th}>{t}</Text>
                    })}
                </View>
                {rows.map((row, index) => (
                    <View style={styles.row} key={index}>
                        {Object.values(row).map((value, index) => {
                            if (index === 0) return null
                            if (index === 1) return <Text key={index} style={styles.fecha}>{moment(value).format('DD/MM/YYYY')}</Text>
                            if (index === 2) return <Text key={index} style={styles.tipo}>{value}</Text>
                            if (index === 3) return <Text key={index} style={styles.monto}>{formatNumber(value) === '0' || formatNumber(value) === undefined ? '' : '$' + formatNumber(value)}</Text>
                            if (index === 4) return <Text key={index} style={styles.monto}>{formatNumber(value) === '0' || formatNumber(value) === undefined ? '' : '$' + formatNumber(value)}</Text>
                            if (index === 5) return <Text key={index} style={styles.text}>{value}</Text>
                            if (index === 6) return <Text key={index} style={styles.monto}>${formatNumber(value)}</Text>
                        })}
                    </View>
                ))}
                <Text style={styles.page} render={({ pageNumber, totalPages }) => (
                    `Pagina ${pageNumber} de ${totalPages}`
                )} fixed />
                <View style={styles.footer} fixed>
                    <Text style={styles.footerTop}>
                        Documento no válido para el cómputo del importe de las retenciones y/o percepciones como pago a cuenta de los impuestos detallados precedentemente.
                        <Text style={styles.bold}>RG 796</Text>
                    </Text>
                    <Text style={styles.footerBottom}>Airata S.A. CUIT 30-71454136-2 &nbsp;|&nbsp; ING. BRUTOS: CM901-707964-3 &nbsp;|&nbsp; General Lucio Mansilla 2686 Piso 2 Of 15, CP 1425, CABA</Text>
                </View>
            </Page>
        </Document>
    )
}

Font.register({
    family: 'Roboto-300',
    fonts: [
        {
            src: 'https://cdn.jsdelivr.net/npm/roboto-fontface-woff@0.8.0/fonts/roboto/Roboto-Light.woff', fontWeight: 300,
            // src: 'https://cdn.jsdelivr.net/npm/roboto-fontface-woff@0.8.0/fonts/roboto/Roboto-Regular.woff', fontWeight: 400,
            // src: 'https://cdn.jsdelivr.net/npm/roboto-fontface-woff@0.8.0/fonts/roboto/Roboto-Bold.woff', fontWeight: 700,
        }
    ]
});
Font.register({
    family: 'Roboto-400',
    fonts: [
        {
            src: 'https://cdn.jsdelivr.net/npm/roboto-fontface-woff@0.8.0/fonts/roboto/Roboto-Regular.woff', fontWeight: 400
        }
    ]
});
Font.register({
    family: 'Roboto-700',
    fonts: [
        {
            src: 'https://cdn.jsdelivr.net/npm/roboto-fontface-woff@0.8.0/fonts/roboto/Roboto-Bold.woff', fontWeight: 700,
        }
    ]
});

const styles = StyleSheet.create({
    root: {
        fontFamily: 'Roboto-400'
    },
    bold: {
        fontFamily: 'Roboto-700',
    },
    header: {
        padding: '16px 24px 16px 60px',
        backgroundColor: '#8972b4',
    },
    title: {
        display: 'inline',
        marginRight: 'auto',
        color: '#FFF',
        width: '100%',
        textAlign: 'left',
        fontFamily: 'Roboto-300',
        fontSize: '16px',
    },
    cuit: {
        fontSize: '10px',
        marginTop: '8px',
        color: '#FFF',
        fontFamily: 'Roboto-300',
    },
    icono: {
        position: 'absolute',
        top: 4,
        left: 0,
        height: '60px',
        width: '60px',
    },
    image: {
        position: 'absolute',
        top: 12,
        right: 48,
        height: '40px',
        width: '80px',
    },
    thead: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '8px',
        margin: '16px 8px 8px',
        textAlign: 'left',
        fontSize: '10px',
        color: '#5567AE',
        fontFamily: 'Roboto-700',
        borderBottom: '2px solid #5567AE',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 8px 8px',
        textAlign: 'left',
        fontSize: '8px',
        color: '#666',
        fontFamily: 'Roboto-400',
    },
    fecha: {
        width: '60px',
        padding: '0 8px',
    },
    tipo: {
        width: '180px',
        padding: '0 8px'
    },
    monto: {
        width: '100px',
        padding: '0 8px',
        textAlign: 'right'
    },
    th: {
        width: '130px',
        padding: '0 8px',
    },
    text: {
        width: '130px',
        padding: '0 8px'
    },
    page: {
        marginTop: 'auto',
        marginRight: '8px',
        marginBottom: '4px',
        marginLeft: 'auto',
        fontSize: '8px',
        color: '#5567AE'
    },
    footer: {
        backgroundColor: '#8972b4',
        padding: '6px',
    },
    footerTop: {
        fontSize: '8px',
        textAlign: 'center',
        color: '#FFF',
        marginBottom: '4px'
    },
    footerBottom: {
        fontSize: '6px',
        textAlign: 'center',
        color: '#FFF',
        fontFamily: 'Roboto-300',
    }
});